import { Grid } from "@mui/material";
import moment from "moment";
import React from "react";

export default function MesaCard({ dataInicio, dataFim, nome, siglaPartido, siglaUf, titulo }) {
  return (
    <Grid my={2} container columnSpacing={5} rowSpacing={3}>
      <Grid item width={175}>
        <b style={{ color: "var(--bayer-md-green)" }}>
          {moment(dataInicio).format("MM/YYYY")} -{" "}
          {dataFim ? moment(dataFim).format("MM/YYYY") : "Atual   "}
        </b>
      </Grid>
      <Grid item>
        <b>{nome}</b> -{" "}
        <small>
          {siglaPartido}/{siglaUf}
        </small>
        <div>{titulo}</div>
      </Grid>
    </Grid>
  );
}
