import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { AccessTime, Square, StarBorder, StarRounded } from "@mui/icons-material";
import moment from "moment";
import JotaLogo from "../../image/Logo_Jota.png";
import PatriLogo from "../../image/Logo_Patri.png";
import JotaLogoUpdated from "../../image/Logo_Jota_2-1309x450.png";
import PatriLogoUpdated from "../../image/Logo_Patri_2-8936x3000.png";
import JotaMobileLogo from "../../image/Fav_Jota_Updated.png";
import PatriMobileLogo from "../../image/Fav_Patri.png";
import JotaLogoNew from "../../image/Home_Jota.png";
import PatriLogoNew from "../../image/Home_Patri.png";
import { images } from "./images";
import axios from "axios";
import { AppContext } from "../../App";
import FavouriteStar from "../../ReuseableComponents/FavouriteStar";
import favpatrilogo from "../../image/patri_2.png";
import favjotalogo from "../../image/jota_2.png";

export default function HomePostCard({ item, onClick }) {
  const {
    currIdToken,
    expiresOn,
    renewSilentToken,
    currUserId,
    favData,
    setFavData,
    isMobile,
  } = React.useContext(AppContext);

  useEffect(() => {
    if (!currIdToken) return;
  }, [currIdToken]);

  const time = (text) => {
    let timeAgo = text.split("~");
    return timeAgo[0];
  };

  const addFavourites = async (e) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    e.stopPropagation();
    const params = {
      user_id: currUserId, //loggedInUser ID
      type: "fav_news",
      favourite_ids: [item.news_id.toString()],
    };
    await axios
      .post(`${process.env.REACT_APP_API_KEY}/favourites/add-favourite`, params, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        setFavData((f) => f.concat({ id: item.news_id }));
      })
      .catch(renewSilentToken);
  };

  const removeFavourites = async (e) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    e.stopPropagation();
    const params = {
      user_id: currUserId, //loggedInUser ID
      type: "fav_news",
      favourite_ids: [item.news_id.toString()],
    };
    await axios
      .post(`${process.env.REACT_APP_API_KEY}/favourites/remove-favourite`, params, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        setFavData((f) => {
          return f.filter(({ id }) => id !== item.news_id);
        });
      })
      .catch(renewSilentToken);
  };
  const handleOnClickCard = () => {
    onClick && onClick();
  };

  return (
    <Card
      className="card"
      style={{
        backgroundImage:
          item.datasource_name === "JOTA" && !isMobile
            ? `url('${JotaLogoUpdated}')`
            : item.datasource_name === "JOTA" && isMobile
            ? ""
            : item.datasource_name === "PATRI" && !isMobile
            ? `url('${PatriLogoUpdated}')`
            : "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        overflowY: "hidden",
        backgroundPosition: "center",
        // maxHeight: "150px"
      }}
    >
      <div className="shadow">
        {item.datasource_name === "JOTA" && isMobile ? (
          <span style={{ cursor: "pointer" }}>
            <img style={{ height: "60px", width: "60px" }} src={favjotalogo} />
          </span>
        ) : (
          <></>
        )}
        {item.datasource_name === "PATRI" && isMobile ? (
          <span style={{ cursor: "pointer" }}>
            <img style={{ height: "60px", width: "60px" }} src={favpatrilogo} />
          </span>
        ) : (
          <></>
        )}
        <span style={{ cursor: "pointer" }} className="p-2 ">
          <FavouriteStar
            addFav={addFavourites}
            removeFav={removeFavourites}
            selectedId={item.news_id}
          />
        </span>
        <div onClick={handleOnClickCard}>
          <Typography style={{ cursor: "pointer" }} className="content">
            {item.news_title}
          </Typography>
          <div className="home-posts-bottom">
            <div>
              <Typography
                className="home-posts-author"
                style={{ fontSize: "12px", marginRight: "15px" }}
              >
                <Square
                  style={{
                    color: "rgb(17 203 83)",
                    fontSize: "18px",
                    marginRight: "5px",
                  }}
                />
                {item.datasource_name}
              </Typography>
            </div>
            <div>
              <Typography className="home-posts-clock" style={{ fontSize: "12px" }}>
                <AccessTime
                  style={{
                    fontSize: "18px",
                  }}
                />{" "}
                {moment(item.news_date).format("DD/MM/YYYY")}
                <div className="access-time" style={{ marginLeft: "23px" }}>
                  {time(item.time_ago)} atrás
                </div>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
