import { Construction } from "@mui/icons-material";
import { setDate } from "date-fns";
import { typeOf } from "react-is";

export const getCalendarArray = (year, month) => {
  const calendarArray = [];

  //This month
  const firstDayWeekDay = new Date(year, month - 1, 1).getDay();
  const lastDayWeekDay = new Date(year, month, 0).getDay();
  const lastDay = new Date(year, month, 0).getDate();

  //Last month
  const lastDate = new Date(year, month - 1, 0);
  const lastMonth = new Date(lastDate).getMonth() + 1;
  const lastYear = new Date(lastDate).getFullYear();
  const lastMonthLastDay = new Date(year, month - 1, 0).getDate();

  //Next Month
  const nextDate = new Date(year, month, 1).setMonth(month + 1);
  const nextMonth = new Date(nextDate).getMonth();
  const nextYear = new Date(nextDate).getFullYear();

  let day = 1;
  const length = lastDay + firstDayWeekDay + (6 - lastDayWeekDay);

  for (let i = 0; i < length; i++) {
    if (i < firstDayWeekDay) {
      const dayMonthBefore = lastMonthLastDay - (firstDayWeekDay - i) + 1;
      calendarArray.push({
        date: new Date(lastYear, lastMonth - 1, dayMonthBefore),
        enabled: false,
      });
    } else if (day > lastDay) {
      const dayMonthAfter = i - lastDay - firstDayWeekDay + 1;
      calendarArray.push({
        date: new Date(nextYear, nextMonth - 1, dayMonthAfter),
        enabled: false,
      });
    } else {
      calendarArray.push({ date: new Date(year, month - 1, day), enabled: true });
      day++;
    }
  }
  return calendarArray;
};

export const getMonthYearString = (year, month) =>
  new Date(year, month - 1, 1).toLocaleString("pt-BR", {
    month: "long",
    year: "numeric",
  });

export const getFullDateString = (date) =>
  date.toLocaleString("pt-BR", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });

export const padMonth = (month) => month.toString().padStart(2, "0");

export const getDateIndexes = (date) => [
  `${date?.getFullYear()}${padMonth(date?.getMonth() + 1)}`,
  date?.getDate(),
];

export const sortByTime = (array = null) =>
  array?.sort((a, b) => (a.time < b.time ? -1 : 1)) || [];

export const formatISODateStringToJSDateObj = (datetime) => {
  if (typeof datetime == "string") {
    const months = {
      1: "Jan",
      2: "Feb",
      3: "Mar",
      4: "Apr",
      5: "May",
      6: "Jun",
      7: "Jul",
      8: "Aug",
      9: "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const [date, time] = datetime.split("T");
    const [y, m, d] = date.match(/\d+/g);
    const [t, tz] = time.split(/(?=[+-])/);
    var timeArr = t.split(":");
    var result = new Date(y, m, d, timeArr[0], timeArr[1], timeArr[2].split(".")[0], 0);
    return result;
  } else {
    return datetime;
  }
};

export const getRemindersByDate = (reminders, date) => {
  date = formatISODateStringToJSDateObj(date);
  const [dateIndex, day] = getDateIndexes(date);
  return sortByTime(reminders[dateIndex]?.[day]) || [];
};

export const getRemindersByDateAndId = (reminders, date, id) => {
  var result = reminders?.find((reminder) => reminder.calendar_user_event_id == id);
  return result;
};

export const areDatesEqual = (date1, date2) =>
  date1.setHours(0, 0, 0, 0) === date2.setHours(0, 0, 0, 0);

export const insertToCalendarDb = (calendarDb, date, calendarItem) => {
  const [dateIndex, day] = getDateIndexes(date);
  const reminders = calendarDb[dateIndex] || {};
  reminders[day] = reminders[day] || [];
  reminders[day].push(calendarItem);
  return { ...calendarDb, [dateIndex]: reminders };
};

export const updateToCalendarDb = (calendarDb, date, calendarItem, id) => {
  const [dateIndex, day] = getDateIndexes(date);
  const reminders = calendarDb[dateIndex] || {};
  reminders[day] = reminders[day] || [];
  reminders[day] = reminders[day].map((reminder) =>
    reminder.id === id ? calendarItem : reminder
  );
  return { ...calendarDb, [dateIndex]: reminders };
};

export const deleteFromCalendarDb = (calendarDb, date, id) => {
  const [dateIndex, day] = getDateIndexes(date);
  const reminders = calendarDb[dateIndex] || {};
  reminders[day] = reminders[day] || [];
  reminders[day] = reminders[day].filter((reminder) => reminder.id !== id);
  return { ...calendarDb, [dateIndex]: reminders };
};

export const moveItemInCalendarDb = (calendarDb, prevDate, date, calendarItem, id) => {
  const newCalendarDb = deleteFromCalendarDb(calendarDb, prevDate, id);
  return insertToCalendarDb(newCalendarDb, date, calendarItem);
};

export const getDefaultReminder = (date) => ({
  date,
  time: "00:00",
  title: "",
  description: "",
  city: "",
});
