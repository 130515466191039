import React, { useContext } from "react";
import { usePageHeader } from "../MasterPage/MasterPage";
import Board from "./Board";
import "./CSS/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { Grid, Typography } from "@mui/material";
import { AppContext } from "../App";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { CalendarContext } from "./Board/context";

export default function Calendar() {
  const { isMobile, currIdToken, expiresOn, renewSilentToken } =
    React.useContext(AppContext);
  const { calendarDb, activeDate, setActiveDate, openNewReminderForm, closeModal } =
    useContext(CalendarContext);
  const mobileClass = isMobile ? "mobile" : "";
  const buttonMobileClass = isMobile ? "buttonMobile" : "";
  usePageHeader("Calendário");
  const sizeStyle = isMobile
    ? { width: "90vw", height: "80vh" }
    : { width: 1000, height: 500 };

  return (
    <div>
      <Typography className="calendar-heading">
        Visualize os próximos 10 dias de eventos programados na Câmara para discussão!
      </Typography>

      <Board />

      <p className="mt-2" style={{ textAlign: "center", fontSize: "11px" }}>
        Os dados contidos nesta página são puxados conforme registro e ordem das
        informações disponibilizadas no site institucional da Câmara (
        <a
          href="https://www.camara.leg.br/"
          style={{ cursor: "pointer", fontSize: "11px" }}
          target="_blank"
        >
          https://www.camara.leg.br/
        </a>
        )
      </p>
    </div>
  );
}
