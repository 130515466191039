import React from "react";
import { Modal, Box } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  pr: 2,
  borderRadius: "10px",
  display: "flex",
};

export default function HomePostModal({ children, isMobile, ...props }) {
  const sizeStyle = isMobile
    ? { width: "90vw", height: "80vh" }
    : { width: 1000, height: 500 };
  return (
    <Modal {...props}>
      <Box sx={{ ...style, ...sizeStyle }}>
        <div
          style={{
            width: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
            paddingRight: "16px",
          }}
        >
          {children}
        </div>
      </Box>
    </Modal>
  );
}
