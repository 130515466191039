import { Box, Grid, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import StakeholdersProfileDados from "./Dados/StakeholderProfileDados";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { textAlign } from "@mui/system";
import { Tag } from "@mui/icons-material";
import * as moment from "moment";
import { Card, Divider } from "@mui/material";
import axios from "axios";
import { AppContext } from "../../../App";
import { AccessTime, Square, StarBorder, StarRounded } from "@mui/icons-material";
import { Spinner } from "react-bootstrap";
import PessoasMessage from "../FooterMessages/Js/PessoasMessage";
import StakeholderPosition from "./StakeholderPosition";
import StakeholderTweet from "./StakeholderTweet";

export default function StakeholderProfilePosicionamentos({ posicionamentos, nome }) {
  const { isMobile, currIdToken, expiresOn, renewSilentToken } =
    React.useContext(AppContext);
  return (
    <>
      <Box mt={2} style={{ marginBottom: "40px" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <StakeholderPosition posicionamentos={posicionamentos} nome={nome} />
          </Grid>
          <Grid item xs={6}>
            <StakeholderTweet posicionamentos={posicionamentos} nome={nome} />
          </Grid>
        </Grid>
      </Box>
      <PessoasMessage />
    </>
  );
}
