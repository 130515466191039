import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Avatar, Card, Divider } from "@mui/material";
import { AccessTime, Close, Square, StarBorder, StarRounded } from "@mui/icons-material";
import "../CSS/HomeButtonDetails.css";
import HomePostDetails from "./HomePostDetails";
import { AppContext } from "../../App";
import * as moment from "moment";
import JotaLogo from "../../image/Logo_Jota.png";
import PatriLogo from "../../image/Logo_Patri.png";
import { HomeContext } from "./Home";
import axios from "axios";
import FavouriteStar from "../../ReuseableComponents/FavouriteStar";
import HomePostDetailsBack from "./HomePostDetailsBack";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1100,
  height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  borderRadius: "10px",
};

export const HomeButtonDetailsContext = React.createContext({});

export default function HomeButtonDetails({ item, close, showFirstModal }) {
  const { isMobile } = React.useContext(AppContext);
  const sizeStyle = isMobile
    ? { width: "90vw", height: "80vh" }
    : { width: 1000, height: 500 };

  const time = (text) => {
    let timeAgo = text.split("~");
    return timeAgo[0];
  };
  const { openModal, setModalContent } = React.useContext(HomeContext);

  useEffect(() => {
    if (showFirstModal) {
      setModalContent(<HomeButtonDetails item={item} close={close} />);
    }
  }, [showFirstModal]);

  const { currIdToken, currUserId, expiresOn, renewSilentToken, favData, setFavData } =
    React.useContext(AppContext);

  useEffect(() => {
    if (!currIdToken) return;
  }, [currIdToken]);

  const addFavourites = async (e, newsId) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    e.stopPropagation();
    const params = {
      user_id: currUserId, //loggedInUser ID
      type: "fav_news",
      favourite_ids: [newsId.toString()],
    };

    await axios
      .post(`${process.env.REACT_APP_API_KEY}/favourites/add-favourite`, params, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        setFavData((f) => {
          return f.concat({ id: newsId });
        });
      })
      .catch(renewSilentToken);
  };

  const removeFavourites = async (e, newsId) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    e.stopPropagation();
    const params = {
      user_id: currUserId, //loggedInUser ID
      type: "fav_news",
      favourite_ids: [newsId.toString()],
    };

    await axios
      .post(`${process.env.REACT_APP_API_KEY}/favourites/remove-favourite`, params, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        setFavData((f) => {
          return f.filter(({ id }) => id !== newsId);
        });
      })
      .catch(renewSilentToken);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end", height: "20px" }}>
        <Close
          style={{
            fontSize: "17px",
            cursor: "pointer",
            position: "fixed",
            marginRight: "-27px",
            marginTop: "-20px",
          }}
          onClick={close}
        />
      </div>
      <Typography
        style={{
          fontWeight: "bold",
          marginBottom: "10px",
        }}
      >
        {item.text}
      </Typography>
      {item.data.length > 0
        ? item.data &&
          item.data.map((text, index) => {
            return (
              <div key={`homebuttondetails-${index}`}>
                <div
                  style={{ marginBottom: "10px", cursor: "pointer" }}
                  onClick={() => {
                    setModalContent(
                      <HomePostDetailsBack
                        isMobile={isMobile}
                        item={text}
                        close={close}
                        checkModal={showFirstModal}
                      />
                    );
                    openModal();
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "10px",
                    }}
                  >
                    <div
                    className="keywords-popup-image"
                      style={{
                        height: "65px",
                        width: "8%",
                      }}
                    >
                      <Card
                        style={{
                          backgroundImage:
                            text.datasource_name === "JOTA"
                              ? `url('${JotaLogo}')`
                              : `url('${PatriLogo}')`,
                          width: "100%",
                          height: "100%",
                          backgroundPosition: "center center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          overflowY: "hidden",
                          cursor: "pointer",
                        }}
                      ></Card>
                    </div>
                    <div style={{ marginLeft: "10px", width: "80%" }}>
                      <Typography
                        className="modal-title"
                        variant="body2"
                        style={{ fontWeight: "bold" }}
                      >
                        {text.news_title}{" "}
                      </Typography>
                      <div className="modal-author-time">
                        <div className="author">
                          <Square
                            style={{ padding: "4px" }}
                            className="home-btn-details-square"
                          />{" "}
                          {text.datasource_name}{" "}
                        </div>
                        <div className="time">
                          <AccessTime
                            style={{ padding: "4px" }}
                            className="home-btn-details-clock"
                          />{" "}
                          {moment(text.news_date).format("DD/MM/YYYY")}
                          <div className="access-time" style={{ marginLeft: "83px" }}>
                            {time(text.time_ago)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="favorites" style={{ width: "10%" }}>
                      <FavouriteStar
                        addFav={addFavourites}
                        removeFav={removeFavourites}
                        selectedId={text.news_id}
                      />
                    </div>
                  </div>
                </div>
                <Divider className="border" />
              </div>
            );
          })
        : "Nenhum dado correspondente à busca realizada está disponível"}{" "}
    </div>
  );
}
