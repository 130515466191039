import React, { useState, useEffect } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";
import { Box, Tabs } from "@mui/material";
import { usePageHeader } from "../../MasterPage/MasterPage";
import StakeholderProfileCard from "./StakeholderProfileCard";
import TabPanel from "./StakeholderProfileTabPanel";
import ErrorBoundary from "../../ReuseableComponents/ErrorBoundary";
import Tab from "./StakeholderProfileTab";
import { useFetchBackend } from "../../hooks/useFetchBackend";
import MinisterInformation from "./Minister/MinisterInformation/MinisterInformation";
import MinisterPoliticalConnection from "./Minister/MinisterInformation/MinisterPoliticalConnection";
import MinisterNoticias from "./Minister/MinisterInformation/MinisterNoticias";
import MinisterProfileCard from "./MinisterProfileCard";
import { AppContext } from "../../App";
import axios from "axios";

const INFORMACOES_ENDPOINTS = {
  frentes: "frentes",
  ocupacoes: "ocupacoes",
  orgaos: "orgaos",
  profissoes: "profissoes",
};

export default function StakeHolderMinistroProfile({ params }) {
  usePageHeader("Stakeholders - Pessoas (Ministro)");
  const [tabIndex, setTabIndex] = React.useState(0);
  const paramID = React.useRef(params.id);
  const profile = paramID.current.split("-");
  const StakeholderId = profile[1];
  const handleChangeTab = (event, newValue) => setTabIndex(newValue);
  const { data: stakeholder } = useFetchBackend("minister");
  const [noticias, setNoticias] = useState(null);
  const [ministerNews, setMinisterNews] = useState([]);
  const {
    isMobile,
    currIdToken,
    expiresOn,
    renewSilentToken,
    institutionNoticias,
    institutionData,
  } = React.useContext(AppContext);

  const handleMinisterInformation = async (StakeholderId) => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/minister/get-minister/` + StakeholderId, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        getNews(response.data[0].stakeholder_name);
      });
  };

  const getNews = async (name) => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/news/get-google-news/` + name, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        if (response.data !== "") {
          setMinisterNews(response.data);
        }
      });
  };

  React.useEffect(() => {
    const DeputadoId = StakeholderId.current;
    handleMinisterInformation(StakeholderId);
  }, []);
  const mobileClass = isMobile ? "mobile" : "";

  return (
    <> 
      <Box>
        <Link
          to="/stakeholders-pessoas" className={`back ${mobileClass}`}
          style={{ textDecoration: "none", display: "block", marginBottom: "10px" }}
        >
          <KeyboardBackspaceIcon /> Voltar
        </Link>
        <MinisterProfileCard ministro stakeholder={stakeholder} />
      </Box>
      <Tabs
        value={tabIndex}
        onChange={handleChangeTab}
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="Informações" index={0} />
        <Tab label="Notícias" index={1} />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <ErrorBoundary>
          <MinisterInformation ministro stakeholder={stakeholder} />
        </ErrorBoundary>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <MinisterNoticias ministro ministerNews={ministerNews} />
      </TabPanel>
    </>
  );
}
