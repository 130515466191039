import { Box, Typography } from "@mui/material";
import React from "react";
import { AccessTime, Close, Square, StarBorder, StarRounded } from "@mui/icons-material";
import { AppContext } from "../../../../App";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  borderRadius: "10px",
};

export default function InstitutionProfileNewsDetails({ item, close }) {
  const {
    currIdToken,
    currUserId,
    expiresOn,
    renewSilentToken,
    favData,
    setFavData,
    isMobile,
  } = React.useContext(AppContext);

  const sizeStyle = isMobile
    ? { width: "70vw", height: "80vh" }
    : { width: 1000, height: 500 };

  const formattedText = (text) => {
    let paragraph = text.split("\n\n");
    return paragraph;
  };
  const formattedDesc = (text) => {
    let paragraph = text.split("\n");
    return paragraph;
  };
  return (
    <div>
      <Box className="modal-box" sx={style}>
        <div style={{ display: "flex", justifyContent: "flex-end", height: "20px" }}>
          <Close
            style={{
              fontSize: "17px",
              cursor: "pointer",
              position: "fixed",
              marginBottom: "20px",
            }}
            onClick={close}
          />
        </div>
        <div className="modal-top-bar">
          <div className="home-post-details-top">
            <div className="home-post-details-author">
              <Square className="home-post-details-square" />
              {item.Title}
            </div>
            <div className="home-post-details-time">
              <AccessTime className="home-post-details-clock" />
              {item.Date}
            </div>
          </div>
        </div>
        <div className="modal-image">
        </div>
        <Typography className="modal-title" variant="h5" style={{ fontWeight: "bold" }}>
          {item.Title}
        </Typography>
        {<br />}
        <Typography
          className="modal-description"
          variant="subtitile2"
          style={{ alignContent: "center" }}
        >
          {formattedText(item.Article).map((data, index) => {
            return <div dangerouslySetInnerHTML={{ __html: data }} />;
          })}
        </Typography>
        {<br />}
        <Typography className="modal-title" variant="h5" style={{ fontWeight: "bold" }}>
          Resumo
        </Typography>
        {<br />}
        <Typography
          className="modal-description"
          variant="subtitile2"
          style={{ alignContent: "center" }}
        >
          {formattedDesc(item.Summary).map((data, index) => {
            return <div dangerouslySetInnerHTML={{ __html: data }} />;
          })}
        </Typography>
      </Box>
    </div>
  );
}
