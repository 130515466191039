import React, { useState, useEffect } from "react";
import { usePageHeader } from "../../MasterPage/MasterPage";
import {
  Grid,
  Typography,
  InputAdornment,
  CircularProgress,
  Paper,
  Button,
  InputBase,
} from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { AppContext } from "../../App";
import { useFetchCamara } from "../../hooks/useFetchCamara";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Carousel from "react-elastic-carousel";
import "../CSS/style.css";
import axios from "axios";
import SocialMediaFacebook from "./SocialMediaFacebook";
import SocialMediaTwitter from "./SocialMediaTwitter";
import SocialMediaInstagram from "./SocialMediaInstagram";
import SocialMediaLikedin from "./SocialMediaLikedin";
import SocialMediaYoutube from "./SocialMediaYoutube";
import { SearchRounded } from "@mui/icons-material";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Atualmente nenhum dado encontrado.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const FETCH_DELAY_MS = 200;

const InputProps = (loading) => ({
  startAdornment: (
    <InputAdornment position="start">
      {loading ? (
        <CircularProgress
          style={{ padding: 0, height: "16px", width: "16px", margin: 0 }}
        />
      ) : (
        <FaSearch />
      )}
    </InputAdornment>
  ),
});

const gridMobileProps = (isMobile) => ({
  columns: isMobile ? 6 : 12,
  sx: {
    overflowY: "auto",
    maxHeight: isMobile ? "600px" : "450px",
    mt: isMobile ? 2 : 1,
    pr: 4,
    pb: 2,
    pl: 2,
  },
});

const itemsPorPagina = 10;

const initialParams = {
  itens: itemsPorPagina,
  pagina: 1,
  ordem: "ASC",
  ordenarPor: "nome",
};
const cardWidth = 275;

const Item = styled(Paper)(({ theme }) => ({
  minWidth: cardWidth,
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 450, itemsToShow: 2 },
  { width: 660, itemsToShow: 3 },
  { width: 950, itemsToShow: 3 },
  { width: 1050, itemsToShow: 4 },
  { width: 1200, itemsToShow: 4 },
];

export default function SocailMedia() {
  usePageHeader("Mídias Sociais");
  const [input, setInput] = React.useState("");
  const [inputID, setInputID] = React.useState("");
  const [params, setParams] = React.useState(initialParams);
  const { data, loading } = useFetchCamara("deputados", params);
  const [clicked, setClicked] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [twitterID, setTwitterID] = useState("");
  const [disable, setDisable] = React.useState(true);
  const [expanded, setExpanded] = React.useState(false);
  const { isMobile, currIdToken, expiresOn, renewSilentToken, currUserId } =
    React.useContext(AppContext);
  const mobileClass = isMobile ? "mobile" : "";
  const buttonMobileClass = isMobile ? "buttonMobile" : "";
  const [tweets, setTweets] = useState([]);

  const handleDisable = (keyword) => {
    keyword.length === 1 ? setDisable(true) : setDisable(false);
  };

  useEffect(() => {
    if (!currIdToken) return;
    getTweet();
  }, [currIdToken]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const getTweet = async (props, IDprops) => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    if (IDprops.length <= 0) {
      await fetch(`${process.env.REACT_APP_API_KEY}/twitter-get-all-tweets/` + props, {
        method: "GET",
        headers: { Authorization: "Bearer " + currIdToken },
      })
        .then((response) => {
          return response.json();
        })
        .then(setTweets)
        .catch(renewSilentToken);
    } else {
      await fetch(
        `${process.env.REACT_APP_API_KEY}/twitter-get-user-tweets/` +
          IDprops +
          "/" +
          props,
        {
          method: "GET",
          headers: { Authorization: "Bearer " + currIdToken },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then(setTweets)
        .catch(renewSilentToken);
    }
  };

  //input logic
  const delay = React.useRef(null);
  const handleInput = (keyword, twitterID) => {
    setInput(keyword);
    setInputID(twitterID);
    getTweet(keyword, twitterID);
  };

  const useStyles = makeStyles({
    flexGrow: {
      flex: "1",
    },
    button: {
      backgroundColor: "var(--bayer-light-blue)",
      color: "#fff",
      marginRight: ".5rem",
      "&:hover": {
        backgroundColor: "var(--bayer-green)",
        color: "#fff",
      },
    },
  });

  const classes = useStyles();

  const scrollRef = React.useRef();

  //Scroll right for the column
  const handleButtonClick = () => {
    scrollRef.current.scroll({
      left: scrollRef.current.scrollLeft + 500,
      behavior: "smooth",
    });
  };

  //Scroll left for the column
  const handleButtonClick2 = () => {
    scrollRef.current.scroll({
      left: scrollRef.current.scrollLeft - 500,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div style={{ width: "100%", paddingRight: !isMobile ? "50px" : "" }}>
        <h5
          className="social-media-heading"
          style={{ color: "var(--bayer-md-blue)", fontWeight: "bold" }}
        >
          Acompanhe os últimos 15 dias nas mídias sociais
        </h5>
        <Typography>
          Acompanhe as últimas postagens nas mídias sociais, utilize a barra de pesquisa
          para busca de palavras-chaves ou pessoas nas redes sociais
        </Typography>
        <form
          className=" gap-2 mt-3 mb-2"
          onSubmit={(e) => {
            e.preventDefault();
            handleInput(keyword, twitterID);
          }}
        >
          <TextField
            label="Pesquisar"
            variant="outlined"
            size="small"
            style={{ width: "45%" }}
            value={keyword}
            inputProps={InputProps(loading)}
            onChange={(e) => {
              setKeyword(e.target.value);
              handleDisable(keyword);
            }}
          />

          {!disable ? (
            <TextField
              label="Pesquisar ID do Twitter"
              disabled={true}
              variant="outlined"
              size="small"
              style={{ width: "45%", marginLeft: "10%" }}
              value={twitterID}
              inputProps={InputProps(loading)}
              onChange={(e) => {
                setTwitterID(e.target.value);
              }}
            />
          ) : (
            <TextField
              label="Pesquisar ID do Twitter"
              disabled={true}
              variant="outlined"
              size="small"
              style={{ width: "45%", marginLeft: "10%" }}
              value={twitterID}
              inputProps={InputProps(loading)}
              onChange={(e) => {
                setTwitterID(e.target.value);
              }}
            />
          )}
          <Button
            variant="contained"
            className={`${classes.button} ${
              buttonMobileClass.length > 0 ? "my-1" : ""
            } mt-2`}
            style={{width: isMobile ? "100%" : ""}}
            type="submit"
          >
            Enviar
          </Button>
        </form>
        {!isMobile ? (
          <Grid spacing={10} className={`socialMedia-tabs ${mobileClass}`}>
            <Item className={`socialTabs ${mobileClass}`}>
              <ErrorBoundary>
                {/* YouTube Card */}
                <SocialMediaYoutube props={input} />
              </ErrorBoundary>
            </Item>

            <Item className={`socialTabs twitterTab ${mobileClass}`}>
              <ErrorBoundary>
                {/* Twitter Card */}
                <SocialMediaTwitter tweets={tweets} />
              </ErrorBoundary>
            </Item>
          </Grid>
        ) : (
          <Grid>
            <Grid item style={{ width: "100%" }}>
              <ErrorBoundary>
                <SocialMediaYoutube props={input} />
              </ErrorBoundary>
            </Grid>
            <Grid item className="my-4">
              <ErrorBoundary>
                <SocialMediaTwitter tweets={tweets} />
              </ErrorBoundary>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
}
