import { useEffect, useState } from 'react';
import { getCalendarArray } from '../utils_calendar';

export default function useCalendar(defaultYear, defaultMonth) {
  const [month, setMonth] = useState(defaultMonth ? defaultMonth : new Date().getMonth() + 1);
  const [year, setYear] = useState(defaultYear ? defaultYear : new Date().getFullYear());
	const [calendar, setCalendar] = useState([]);

  //To change the calendar's month/year
  useEffect(() => {
    setCalendar(getCalendarArray(year, month));
  }, [month, year]);

  const PrevMonth = () => <button className='calendar-caret' onClick={() => setMonth(month - 1)}>{'<'}</button>;
  const NextMonth = () => <button className='calendar-caret' onClick={() => setMonth(month + 1)}>{'>'}</button>;

  const setDate = (year, month) => {
    setMonth(month);
    setYear(year);
  };

  return {
		calendar,
    month: new Date(year, month - 1, 1).getMonth() + 1,
    year: new Date(year, month - 1, 1).getFullYear(),
		date: new Date(year, month - 1),
    Button: {
      PrevMonth,
      NextMonth
    },
		setDate
  };
}
