import { Box } from "@mui/material";
import axios from "axios";
import { Grid, Paper, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { AppContext } from "../../App";
import EmptyProfile from "../../image/EmptyProfile.png";
import "../card.css";
import ReactDOMServer from "react-dom/server";
import jsPDF from "jspdf";
import MinisterPdf from "./Minister/MinisterPdf";
const doc = new jsPDF("p", "px", [1528, 1080]);

function MinisterProfileCard(props) {
  const { id } = props.match.params;
  const profile = id.split("-");
  const profileID = profile[1];
  const { isMobile, currIdToken, expiresOn, renewSilentToken, institutionData } =
    React.useContext(AppContext);
  const mobileClass = isMobile ? "mobile" : "";
  const [imageErrorFlag, setImageErrorFlag] = React.useState(false);
  const handleImageError = () => setImageErrorFlag(true);

  /// Genrate pdf///////////
  const [loadingPdf, setLoadingPdf] = React.useState(false);
  const handleDownload = () => {
    // const content = PdfConsumer.current;
    // const content = PdfConsumer;
    setLoadingPdf(true);
    // const content = "have fun";
    const doc = new jsPDF("p", "px", [1528, 1080]);
    doc.html(
      ReactDOMServer.renderToString(
        <MinisterPdf
          ministerId={id}
          ministerInfo={ministerInfo}
          ministerNoticias={ministerNews}
          //  ministerTitle={title}
        />
      ),
      {autoPaging:"text",
        margin: [40, 20, 40, 10],
        callback: function (doc) {
          doc.save("MinisterProfile.pdf");
          setLoadingPdf(false);
        },
        // width: 200,
        // windowWidth: 200
      }
    );
  };
  ////////////////////////handling Information
  useEffect(() => {
    if (!currIdToken) return;
    // setLoading(true);
    handleMinisterInformation();
    handleTitle();
  }, [currIdToken]);

  const [ministerInfo, setministerInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");

  const handleMinisterInformation = async () => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/minister/get-minister/${profileID}`, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        if (response.data != "") {
          setministerInfo(response.data);
          setLoading(true);
        }
      })

      .catch(renewSilentToken);
  };
  const handleTitle = () => {
    return (
      <div>
        {ministerInfo?.map((items) => {
          setTitle(items.stakeholder_name);
        })}
      </div>
    );
  };
  const noticias = title;

  const [ministerNews, setMinisterNews] = useState([]);
  const getNews = async (e) => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/news/get-google-news/` + noticias, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        if (response.data !== "") {
          setMinisterNews(response.data);
          setLoading(true);
        }
      })
      .catch(renewSilentToken);
  };
  //   if (!ministerInfo) return <Box height="150px"></Box>;

  //   if (ministerInfo)
  return (
    <>
      {ministerInfo.length > 0 ? (
        ministerInfo?.map((item, index) => {
          return (
            <Box
              className={`card-grid profile ${mobileClass}`.trim()}
              style={{ minHeight: "90px" }}
            >
              <div className="card-img">
                {!imageErrorFlag && (
                  <img src={item.stakeholder_img} alt="img" onError={handleImageError} />
                )}
                {imageErrorFlag && <img src={EmptyProfile} alt="img" />}
              </div>
              <div>
                <h5 style={{ fontWeight: "bold" }} title={item.stakeholder_name}>
                  {item.stakeholder_name}
                </h5>
                <small>{item.stakeholder_designation}</small>
              </div>
              <div className={`card-interaction ${mobileClass}`.trim()}>
                <div className="card-link-button" style={{ margin: "0 0 auto 0" }}>
                  <Button
                    variant="contained"
                    // ref={PdfConsumer}
                    onClick={handleDownload}
                    // disabled={pageNotLoaded}
                    // {...(pageNotLoaded && { title: "Aguarde o carregamento da página" })}
                  >
                    {loadingPdf ? "Loading..." : "Gerar PDF"}
                  </Button>
                </div>
              </div>
            </Box>
          );
        })
      ) : (
        <Box height="150px"></Box>
      )}
    </>
  );
}

export default withRouter(MinisterProfileCard);
