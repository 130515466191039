import React, { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import { ListItem, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

export default function MapaModal({
  data,
  stakeholderNameX,
  stakeholderNameY,
  topicName,
  close,
}) {
  return (
    <div>
      <div style={{ width: "100%", display: "flex", height: "20px" }}>
        <p
          onClick={close}
          style={{
            width: "80%",
            cursor: "pointer",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <KeyboardBackspaceIcon /> Voltar
        </p>
        <p style={{ width: "20%", display: "flex", justifyContent: "flex-end" }}>
          <Close
            style={{
              fontSize: "17px",
              cursor: "pointer",
              position: "fixed",
              marginRight: "-27px",
              marginTop: "-20px",
            }}
            onClick={close}
          />
        </p>
      </div>
      <div className="modal-top-bar">
        <h3
          style={{
            textAlign: "center",
            textTransform: "capitalize",
            color: "var(--bayer-md-blue)",
            marginTop: "10px",
          }}
        >
          {topicName} {stakeholderNameX} e {stakeholderNameY} possuem similaridade
        </h3>
      </div>
      {data.map((item) => {
        return (
          <div className="modal-desc" style={{ display: "flex", width: "100%" }}>
            <ListItem style={{ display: "list-item" }}>
              {item.frentes_titulo ? item.frentes_titulo : ""}
              {item.orgao_nomeorgao ? item.orgao_nomeorgao : ""}
              {item.legistative_ementa ? item.legistative_ementa : ""}
            </ListItem>
          </div>
        );
      })}
    </div>
  );
}
