import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "../CSS/AgendaPosts.css";
import { AccessTime, Close, Square, StarBorder, StarRounded } from "@mui/icons-material";
import * as moment from "moment";
import axios from "axios";
import { AppContext } from "../../App";
import FavouriteStar from "../../ReuseableComponents/FavouriteStar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  borderRadius: "10px",
};

export default function AgendaPostDetails({ item, close }) {
  const displayText = (text) => {
    text = text.replace(/\\/g, "");
    text = text.replace(/\s*style=(["'])(.*?)\1/gm, 'style="text-align:justify;"');
    text = text.replace(/<strong>/g, "");
    text = text.replace(/<\/strong>/g, "</>");
    var remove = [
      "border",
      "cellspacing",
      "cellpadding",
      "height",
      "width",
      "valign",
      "align",
      "Helvetica",
      "sans-serif",
      "strong",
      "<strong>",
      "</strong>",
    ];
    const removeAttr = (text, attr) => {
      return text.replace(
        new RegExp("(<[^>]+)\\s" + attr + "\\s*=['\"].*?['\"]", "gi"),
        "$1"
      );
    };

    remove.forEach(function (attr) {
      text = removeAttr(text, attr);
    });
    return text;
  };

  const displayTime = (text) => {
    let timeAgo = text.split("T");
    console.log(timeAgo)
    return timeAgo[1];
  };

  const {
    currIdToken,
    currUserId,
    expiresOn,
    renewSilentToken,
    favData,
    setFavData,
    getFavListAPI,
  } = React.useContext(AppContext);

  useEffect(() => {
    if (!currIdToken) return;
  }, [currIdToken]);

  const addFavourites = async (e) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    e.stopPropagation();
    const params = {
      user_id: currUserId, //loggedInUser ID
      type: "fav_agenda",
      favourite_ids: [item.agenda_id.toString()],
    };

    await axios
      .post(`${process.env.REACT_APP_API_KEY}/favourites/add-favourite`, params, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        setFavData((f) => f.concat({ id: item.agenda_id }));
        getFavListAPI();
      })
      .catch(renewSilentToken);
  };

  const removeFavourites = async (e) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    e.stopPropagation();
    const params = {
      user_id: currUserId, //loggedInUser ID
      type: "fav_agenda",
      favourite_ids: [item.agenda_id.toString()],
    };

    await axios
      .post(`${process.env.REACT_APP_API_KEY}/favourites/remove-favourite`, params, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        setFavData((f) => {
          getFavListAPI();
          return f.filter(({ id }) => id !== item.agenda_id);
        });
      })
      .catch(renewSilentToken);
  };

  return (
    <>
      <div>
        <div style={{ display: "flex", justifyContent: "flex-end", height: "20px" }}>
          <Close
            style={{
              fontSize: "17px",
              cursor: "pointer",
              position: "fixed",
              marginRight: "-27px",
              marginTop: "-20px",
            }}
            onClick={close}
          />
        </div>
        <div className="modal-top-bar">
          <div className="home-post-details-top">
            <div className="home-post-details-author">
              <Square className="home-post-details-square" />
              {item.title}
            </div>
            <div className="home-post-details-time">
              <AccessTime className="home-post-details-clock" />
              {moment(item.start_datetime).format("DD/MM/YYYY")}
              {/* {" - "} */}
              {/* {typeof(item.start_datetime) == "object" ? ` - item.start_datetime` : ""} */}
              {item.start_datetime !==  "" || item.start_datetime !==  null ||
              item.start_datetime !==  undefined ? ` -  ${displayTime(item.start_datetime)}` : ""}
            </div>
          </div>
          <div className="home-post-details-favourite">
            <FavouriteStar
              addFav={addFavourites}
              removeFav={removeFavourites}
              selectedId={item.agenda_id}
              favType="fav_agenda"
            />
          </div>
        </div>
        <div className="modal-image"></div>
        <Typography className="modal-title" variant="h5" style={{ fontWeight: "bold" }}>
          {item.organizer}
        </Typography>
        <Typography
          className="modal-description"
          variant="subtitile2"
          style={{ alignContent: "center" }}
        >
          {
            <div
              dangerouslySetInnerHTML={{ __html: displayText(item.description) }}
            ></div>
          }
        </Typography>
      </div>
    </>
  );
}
