import React from "react";
import StakeholderProfileFrentes from "../Informacoes/Frentes/FrentesCard";
import StakeholdersProfileFrentesMembros from "./Frentes/StakeholdersProfileFrentesMembros";
import StakeholdersProfileMesa from "./Mesa/StakeholdersProfileMesa";
import StakeholdersProfileOrgaosMembros from "./Orgaos/StakeholdersProfileOrgaosMembros";
import { Grid, Box, Divider } from "@mui/material";
import PessoasMessage from "../FooterMessages/Js/PessoasMessage";

export default function StakeholderProfileConexoesPoliticas({
  conexoesPoliticas,
  StakeholderId,
  informacoes,
}) {
  if (!conexoesPoliticas) return "Loading...";

  return (
    <>

      <Box mt={2}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

          <Grid item xs={6}>
            <StakeholdersProfileMesa mesa={conexoesPoliticas.legislaturas} />
            <Divider />
            <br />
          </Grid>

          <Grid item xs={6}>
            <StakeholdersProfileFrentesMembros
              membros={conexoesPoliticas.frentes}
              FrentesID={conexoesPoliticas.FrentesID}
              StakeholderId={StakeholderId}
              frentesMembros={informacoes.frentes}
            />
          </Grid>

          <Grid item xs={6}>
            <StakeholdersProfileOrgaosMembros
              membros={conexoesPoliticas.orgaos}
              orgosMembros={informacoes.orgaos}
            />
          </Grid>
        </Grid>
      </Box>

      <PessoasMessage />
    </>
  );
}
