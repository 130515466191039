import { Box, Button, Collapse } from "@mui/material";
import React from "react";
import MembrosCard from "./MembrosCard";

export default function StakeholdersProfileOrgaosMembros({ membros, orgosMembros }) {
  const [collapse, setCollapse] = React.useState(false);
  const [membrosOut, membrosIn] = [
    orgosMembros.slice(0, 5),
    orgosMembros.slice(5, orgosMembros.length),
  ];
  return (
    <Box mb={3}  style={{maxHeight: "50vh", overflowY: "scroll"}}>
      <h5  style={{ marginBottom: "15px", fontWeight: "bold" }}>Órgãos (Membros)</h5>
      {membrosOut.map((item) => (
        <MembrosCard {...{ item }} />
      ))}
      {membrosIn.length > 0 && !collapse && (
        <Button type="button" variant="outlined" onClick={() => setCollapse(true)}>
          Expandir
        </Button>
      )}
      <Collapse in={collapse} timeout="auto">
        {membrosIn.map((item) => (
          <MembrosCard {...{ item }} />
        ))}
        <Button type="button" variant="outlined" onClick={() => setCollapse(false)}>
          Minimizar
        </Button>
      </Collapse>
    </Box>
  );
}
