import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";
import { Box, Tabs } from "@mui/material";
import { usePageHeader } from "../../../MasterPage/MasterPage";
import InstituteProfileCard from "./InstitutionProfileCard";
import TabPanel from "../../Pessoas/StakeholderProfileTabPanel";
import Tab from "../../Pessoas/StakeholderProfileTab";
import { useFetchBackend } from "../../../hooks/useFetchBackend";
import Information from "./InstitutionTabs/InstitutionInformation";
import GoogleNoticias from "./InstitutionTabs/InstitutionNoticias";
import Conexoes from "./InstitutionTabs/InstitutionConexoes";
import ScrollButton from "../../../MasterPage/scrollToTop/ScrollButton";
import { AppContext } from "../../../App";
import InstitutionPosicionmentos from "./InstitutionTabs/InstitutionPosicionmentos";
export default function Instituteprofile() {
  usePageHeader("Stakeholders - Instituições");
  const [tabIndex, setTabIndex] = React.useState(0);
  const handleChangeTab = (event, newValue) => setTabIndex(newValue);
  const { isMobile } = React.useContext(AppContext);
  const mobileClass = isMobile ? "mobile" : "";
  return (
    <>
      <Box>
        <Link
          to="/stakeholders-instituicoes"
          className={`back ${mobileClass}`}
        >
          <KeyboardBackspaceIcon /> Voltar
        </Link>
        <InstituteProfileCard />
      </Box>
      <Tabs value={tabIndex} onChange={handleChangeTab} textColor="primary" indicatorColor="primary">
        <Tab label="Informações" index={0} />
        <Tab label="Conexões" index={1} />
        <Tab label="Posicionamentos" index={2} />
        <Tab label="Notícias" index={3} />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <Information />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <Conexoes />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
      <InstitutionPosicionmentos/>
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <GoogleNoticias />
      </TabPanel>
    
      
    </>
  );
}
