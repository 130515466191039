import React from "react";
import { AppContext } from "../App";

export const camaraUrl = `${process.env.REACT_APP_API_KEY}`;

const url_params = (url, params) => {
  if (!params || Object.keys(params).length === 0) return url;
  return `${url}?${new URLSearchParams(params)}`;
};

export const fetchCamara = (endpoint, params, options) =>
  fetch(`${camaraUrl}/${url_params(endpoint, params)}`, options).then((r) => r.json());

export const useFetchCamara = (endpoint, params) => {
  const [response, setResponse] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const { currIdToken } = React.useContext(AppContext);

  React.useEffect(() => {
    setLoading(true);
    fetchCamara(endpoint, params, { headers: { Authorization: "Bearer " + currIdToken } })
      .then(setResponse)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [endpoint, params]);

  return { data: response, setData: setResponse, error, loading };
};

export const useInfinitePosts = (endpoint, items, params) => {
  const [data, setData] = React.useState([]);
  const [pagina, setPagina] = React.useState(1);

  React.useEffect(() => {
    setPagina(1);
    setData([]);
  }, [params]);

  const fetchParams = React.useMemo(
    () => ({
      ...(params && { ...params }),
      itens: items || 10,
      pagina,
    }),
    [pagina, items, params]
  );

  const { data: fetchedData, loading, error } = useFetchCamara(endpoint, fetchParams);

  const iObserver = React.useRef(null);
  React.useEffect(() => {
    iObserver.current = new IntersectionObserver(([{ isIntersecting }]) => {
      isIntersecting && setPagina((p) => p + 1);
    });
  }, []);

  //Observed Item Logic
  const [observedItem, setObservedItem] = React.useState(null);
  React.useEffect(() => {
    const currItem = observedItem;
    const currObserver = iObserver.current;
    currItem && currObserver && currObserver.observe(currItem);
    return () => currItem && currObserver.unobserve(currItem);
  }, [observedItem]);

  //data accumulation logic
  React.useEffect(() => {
    fetchedData?.length && !loading && setData((list) => [...list, ...fetchedData]);
  }, [fetchedData, loading]);

  return { data, loading, empty: !data?.length, error, observerRef: setObservedItem };
};
