import React, { useEffect, useState } from "react";
import "../../../../ReuseableComponents/css/ScrollToTop.css";
import { KeyboardArrowUpTwoTone } from "@mui/icons-material";
import '../css/OutrosMessage.css';
import RestrictedImg from "../../../../image/Restricted_Img.png";

function OutrosMessage() {
  /////////////////scrollto top ///////////////////
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  return (
    <div style={{
      position: "fixed",
      bottom: "0px",
      display: "block",
      paddingTop: "8px",
      backgroundColor: "#fff",
      width: "90%",
      zIndex: 1,
      marginTop: "60px",
    }}>
      <p className="outros-msg" style={{ marginLeft: "12%", fontSize: "11px" }}>Os dados contidos nesta página são apresentados conforme perfis solicitados e disponibilizados via Patri

      </p>
      <div  >
        <a title="Scroll to top" className="scroll-to-top" onClick={scrollToTop} >
          <KeyboardArrowUpTwoTone
            aria-hidden="true"
            style={{
              width: "20px",
              height: "20px",
              display: "block",
              marginLeft: "40%",
              marginRight: "56%",
              cursor: "pointer",
              border: "1px solid #fff",
              backgroundColor: "var(--bayer-dark-green)",
              color: "#fff",
              borderRadius: "10px",             
            }}
            value="Scroll to top"
          /></a>
          <img
          src={RestrictedImg}
          className="restricted-img r2"
          style={{
            width: "80px",
            height: "30px",
            float: "right",
            marginTop: "-30px",
            right:"0px",
            
          }}
        ></img>
      </div>
      <div>
      
      </div>
    </div>
  )
}

export default OutrosMessage