import React from "react";

export default function DelayedInput({
  onChangeAfterDelay=()=>{},
  onChange,
  delay,
  InputComponent,
  ...props
}) {
  const FETCH_DELAY_MS = delay ?? 200;

  const timeoutRef = React.useRef(null);
  const handleInput = (el) => {
    onChange(el);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      onChangeAfterDelay(el);
    }, FETCH_DELAY_MS);
  };
  if (InputComponent) return <InputComponent {...props} onChange={handleInput} />;
  return <input {...props} onChange={handleInput} />;
}
