import React, { useState, useEffect } from "react";
import { usePageHeader } from "../../MasterPage/MasterPage";
import AgendaPosts from "./AgendaPosts";
import axios from "axios";
import { AppContext } from "../../App";
import { DateRangePicker } from "@mui/lab";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import "../CSS/Agenda.css";
import InfiniteScroll from "react-infinite-scroll-component";

export default function Agenda() {
  const [loader, setLoader] = useState(false);
  const [agendaData, setAgendaData] = React.useState([]);
  const [newData, setNewData] = React.useState([]);
  const { isMobile, currIdToken, expiresOn, renewSilentToken, event } =
    React.useContext(AppContext);

  usePageHeader("Agenda");

  useEffect(() => {
    if (!currIdToken) return;
    handleAgenda();
  }, [currIdToken]);
  const today = new Date(); // get current date
  const first = new Date(today.setDate(today.getDate() - today.getDay() + 1)); // First day is the day of the month - the day of the week
  const last = new Date(today.setDate(today.getDate() - today.getDay() + 7)); // last day is the first day + 6

  const formatedFirstDay = moment(first).format("MM-DD-YYYY");
  const formatedLastDay = moment(last).format("MM-DD-YYYY");

  const dataLimit = 10;
  const handleAgenda = async () => {
    let pageNo = Math.ceil(agendaData.length / dataLimit);
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }

    await axios
      .get(`${process.env.REACT_APP_API_KEY}/agenda/get-all?pagina=${pageNo}&itens=10`, {
        headers: { Authorization: "Bearer " + currIdToken },
        params: {
          start_date: formatedFirstDay,
          end_date: formatedLastDay,
        },
      })
      .then((response) => {
        if (response.data !== "") {
          const apiRes = response.data;
          const mergeData = [...agendaData, ...apiRes];
          setNewData(apiRes);
          setAgendaData(mergeData);
          setLoader(true);
        }
      })
      .catch(renewSilentToken);
  };

  const fetchMoreData = () => {
    if (agendaData.length > 0) {
      handleAgenda();
    }
  };

  return (
    <>
      {loader ? (
        <div
          style={{ width: "100%", paddingRight: !isMobile ? "50px" : "" }}
          className={isMobile ? "mt-4" : ""}
        >
          <div
            style={{
              float: "left",
              width: "100%",
              paddingTop: "10px",
            }}
            id="scrollDiv"
          >
            <InfiniteScroll
              dataLength={agendaData.length}
              next={fetchMoreData}
              hasMore={newData.length > 0}
              loader={<h4>Loading...</h4>}
            >
              {agendaData.length > 0 ? (
                agendaData.map((item, index) => {
                  return (
                    <React.Fragment key={`agenda-${index}`}>
                      <AgendaPosts item={item} />
                    </React.Fragment>
                  );
                })
              ) : (
                <div
                  className="agenda-error-msg"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  <b>Atualmente, os dados não estão disponíveis para a Agenda.</b>
                </div>
              )}
            </InfiniteScroll>
          </div>
        </div>
      ) : (
        <div
          className="normalspinner"
          style={{
            width: "100%",
            alignItems: "center",
            textAlign: "center",
            marginTop: "80px",
          }}
        >
          <Spinner animation="border"></Spinner>
        </div>
      )}
    </>
  );
}
