import axios from "axios";
import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../App";
import { useFetchCamara } from "../../hooks/useFetchCamara";
import { CalendarContext } from "../Board/context";

export default function Reminder({
  calendarItem: {
    date,
    id,
    time,
    item,
    title,
    description,
    calendar_user_event_date,
    calendar_user_event_id,
    calendar_user_event_time,
    calendar_user_event_title,
    ...rest
  },
  tag,
  ...props
}) {
  const { closeModal, openEditReminderForm } = useContext(CalendarContext);
  const handleClick = () => {
    const date = calendar_user_event_date;
    const id = calendar_user_event_id;
    openEditReminderForm(date, closeModal, id);
  };
  if (tag)
    return (
      <div {...props} className="reminder" onClick={handleClick}>
        <b className="reminder-time">{calendar_user_event_time}</b>
        <div className="title">{calendar_user_event_title}</div>
      </div>
    );

  return (
    <div {...props} className="reminder full" onClick={handleClick}>
      <b>{time}</b>
      <div className="reminder-content">
        <h3 className="title">{title}</h3>
        <i>{description}</i>
      </div>
    </div>
  );
}
