import { Button, Collapse, Box } from "@mui/material";
import React from "react";
import MembrosCard from "./MembrosCard";

export default function StakeholderProfileFrentes({ frentesMembros }) {
  const [collapse, setCollapse] = React.useState(false);
  const [frentesOut, frentesIn] = [
    frentesMembros.slice(0, 5),
    frentesMembros.slice(5, frentesMembros.length),
  ];
  return (
    <Box mb={3} style={{ maxHeight: "50vh", overflowY: "scroll" }}>
      <h5 style={{ marginBottom: "15px", fontWeight: "bold" }}>Frentes (Membros)</h5>
      {frentesOut.map((item) => (
        <MembrosCard {...item} />
      ))}
      {frentesIn.length > 0 && !collapse && (
        <Button type="button" variant="outlined" onClick={() => setCollapse(true)}>
          Expandir
        </Button>
      )}
      <Collapse in={collapse} timeout="auto">
        {frentesIn.map((item) => (
          <MembrosCard {...item} />
        ))}
        <Button type="button" variant="outlined" onClick={() => setCollapse(false)}>
          Minimizar
        </Button>
      </Collapse>
    </Box>
  );
}
