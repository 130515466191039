import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import { Button, Checkbox, Typography } from "@mui/material";
import axios from "axios";
import { propTypes } from "react-bootstrap/esm/Image";
import { AppContext } from "../../App";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Divider from "@mui/material/Divider";
import { Close } from "@mui/icons-material";
import moment from "moment";
import Navbar, { NavbarContext } from "../../MasterPage/Navbar/Navbar";

export const KeywordPopulationForm = ({
  isMobile,
  getCheckboxValues,
  existingKeywordData,
  closeModalForm,
  getKeywordsAPI,
  getCount,
}) => {

  const [currentOpenparent, setCurrentOpenparent] = useState({});
  const { setNotifications } = React.useContext(NavbarContext);
  const {
    currIdToken,
    expiresOn,
    renewSilentToken,
    currUserId,
    keywordData,
    setKeywordData,
    event,
    setCountNotification,
    checked,
    parentsChecked,
    subTopic,
    data,
    setSubTopic,
    setParentsChecked,
    setChecked,
    parentsCheckedDisplay,
  } = React.useContext(AppContext);
  const parentsCheckedList = {};
  useEffect(() => {
    if (!currIdToken) return;
  }, [currIdToken]);

  const current = new Date();
  const curDate = `${current.getFullYear()}-${current.getMonth() + 1}-${
    current.getDate() + 1
  }`;

  const handleSubmit = async (e, id) => {
    // parentsChecked
    e.preventDefault();
    await addSelectedKeywords(id);
    let checkedKeys = Object.keys(parentsCheckedDisplay)
      .sort(() => 0.5 - Math.random())
      .slice(0, 0);
    getCheckboxValues(checkedKeys);
    closeModalForm();
    getKeywordsAPI();
    getCount();
    getNotif();
  };

  //To get last 7th date from today
  const lastDate = new Date();
  const seventhDate = lastDate.setDate(lastDate.getDate() - 7);
  const seventhDateNew = moment(seventhDate).format("YYYY-MM-DD");

  const getNotif = async () => {
    const data = {
      user_id: currUserId,
      since: seventhDateNew, //Date Format YYYY-MM-DD
    };
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }

    await axios
      .post(`${process.env.REACT_APP_API_KEY}/notifications/get-notifications`, data, {
        headers: {
          Authorization: "Bearer " + currIdToken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.length != null) {
          setNotifications(response.data);
        }
      })
      .catch((error) => {});
  };

  const addSelectedKeywords = async (keywordsId) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    const params = {
      user_id: currUserId, //loggedInUser ID
      mstr_keyword_id: keywordsId,
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/news/keywords/add-selected-keywords`,
        params,
        {
          headers: { Authorization: "Bearer " + currIdToken },
        }
      )
      .then((response) => {
        if (response.data != "") {
        }
      })
      .catch(renewSilentToken);
  };

  const handleParentChange = (value) => {
    var currentData = value.target.value;
    const parentsCheckedUpdated = { ...parentsChecked };
    parentsCheckedUpdated[currentData] = value.target.checked;

    let sub_topic_ids = [...subTopic];
    Object.keys(data[currentData]).map((topic, index) => {
      checked[topic] = parentsCheckedUpdated[currentData];
      const mstr_keyword_id = data[currentData][topic].mstr_keyword_id;
      if (checked[topic] && !sub_topic_ids.includes(mstr_keyword_id)) {
        sub_topic_ids = sub_topic_ids.concat(mstr_keyword_id.toString());
      } else if (!checked[topic] && sub_topic_ids.includes(mstr_keyword_id.toString())) {
        sub_topic_ids = sub_topic_ids.filter(function (e) {
          return e !== mstr_keyword_id.toString();
        });
      }
    });
    setSubTopic(sub_topic_ids);
    setParentsChecked(parentsCheckedUpdated);
    setChecked(checked);
  };

  const handleChange = (sub_topic, isChecked, parent, mstr_keyword_id) => {
    let sub_topic_ids = [...subTopic];
    if (isChecked && !sub_topic_ids.includes(mstr_keyword_id)) {
      sub_topic_ids = sub_topic_ids.concat(mstr_keyword_id.toString());
    } else if (!isChecked && sub_topic_ids.includes(mstr_keyword_id.toString())) {
      sub_topic_ids = sub_topic_ids.filter(function (e) {
        return e !== mstr_keyword_id.toString();
      });
    }
    setSubTopic(sub_topic_ids);
    checked[sub_topic] = isChecked;
    setChecked({ ...checked });
  };

  const getSelectedKeywords = async (checkedKeys) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    const params = {
      user_id: currUserId, //loggedInUser ID
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/news/keywords/get-selected-keywords`,
        params,
        {
          headers: { Authorization: "Bearer " + currIdToken },
        }
      )
      .then((response) => {
        if (response.data != "") {
          setKeywordData(response.data);
        }
      })
      .catch(renewSilentToken);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end", height: "20px" }}>
        <Close
          style={{
            fontSize: "17px",
            cursor: "pointer",
            position: "fixed",
            marginRight: "-27px",
            marginTop: "-20px",
          }}
          onClick={closeModalForm}
        />
      </div>
      <div className="font-link" style={{ marginBottom: "3%" }}>
        <h5
          style={{
            textAlign: "center",
            color: "var(--bayer-md-blue)",
            fontWeight: "bold",
          }}
        >
          Adicionar novas palavras-chave
        </h5>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {Object.keys(data).map((parentTopic) => {
          return (
            <>
              <div
                style={{ display: "flex", width: "100%" }}
                onClick={() => {
                  currentOpenparent[parentTopic] = !currentOpenparent[parentTopic];
                  setCurrentOpenparent({ ...currentOpenparent });
                }}
              >
                <div style={{ width: "90%" }}>
                  <Typography variant="subtitle1" style={{ fontWeight: "30px" }}>
                    {currentOpenparent[parentTopic] ? (
                      <RemoveCircleOutlineIcon
                        style={{
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <AddCircleOutlineIcon
                        style={{
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                      />
                    )}{" "}
                    {parentTopic}
                  </Typography>
                </div>
                <div style={{ width: "10%", marginTop: "-9px" }}>
                  <Checkbox
                    onChange={(value) => handleParentChange(value)}
                    value={parentTopic}
                    checked={!!parentsChecked[parentTopic]}
                  />
                </div>
              </div>

              {currentOpenparent[parentTopic]
                ? Object.keys(data[parentTopic]).map((sub_topic, index) => {
                    let topic = data[parentTopic][sub_topic];
                    return (
                      <React.Fragment key={`home-keywordpop-${index}`}>
                        <div style={{ display: "flex", width: "100%" }}>
                          <div style={{ width: "90%" }}>
                            <Typography variant="subtitle1">
                              {" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {topic.sub_topic}
                            </Typography>
                          </div>
                          <div style={{ width: "10%", marginTop: "-10px" }}>
                            <Checkbox
                              onChange={(value) =>
                                handleChange(
                                  topic.sub_topic,
                                  value.target.checked,
                                  parentTopic,
                                  topic.mstr_keyword_id
                                )
                              }
                              value={topic.sub_topic}
                              checked={!!checked[topic.sub_topic]}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })
                : null}
            </>
          );
        })}
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="success"
            type="submit"
            style={{ marginTop: "10px" }}
            key="submit"
            onClick={(e) => handleSubmit(e, subTopic)}
          >
            Enviar
          </Button>
        </div>
      </form>
    </>
  );
};
