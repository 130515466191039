import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Button,
  Box,
  Typography,
  InputAdornment,
  CircularProgress,
  Chip,
  Autocomplete,
  Input,
} from "@mui/material";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import DelayedInput from "../ReuseableComponents/DelayedInput/DelayedInput";
import StakeholderCard from "./StakeholderCard";
import MinisterCard from "./MinisterCard";
import { FaSearch } from "react-icons/fa";
import { usePageHeader } from "../MasterPage/MasterPage";
import { AppContext } from "../App";
import { useInfiniteCamara, fetchCamara, useFetchCamara } from "../hooks/useFetchCamara";
import { formatReferenciasDeputados, getUFValida } from "./utils";
import { Link } from "react-router-dom";
import { useFetchBackend } from "../hooks/useFetchBackend";
import { v } from "../utils";
import "./stakeholder.css";
import OutrosCard from "./OutrosCard";
import axios from "axios";
import Modal from "@mui/material/Modal";
import { CloseButton } from "react-bootstrap";
import $ from "jquery";
import { RiEyeCloseFill } from "react-icons/ri";

const InputProps = (loading) => ({
  startAdornment: (
    <InputAdornment position="start">
      {loading ? (
        <CircularProgress style={{ padding: 0, height: "16px", width: "16px", margin: 0 }} />
      ) : (
        <FaSearch />
      )}
    </InputAdornment>
  ),
});

const gridMobileProps = (isMobile) => ({
  columns: isMobile ? 6 : 12,
  sx: {
    overflowY: "auto",
    maxHeight: isMobile ? "600px" : "450px",
    mt: isMobile ? 2 : 1,
    pr: 4,
    pb: 2,
    pl: 2,
  },
});

const StakeholdersSelect = {
  "Câmara dos Deputados": "Câmara dos Deputados",
  "Ministério da Saúde": "Ministério da Saúde",
  Outros: "Outros",
};

export function Stakeholderss({ currentLegislatura }) {
  usePageHeader("Stakeholders - Pessoas");
  const defaultDeputadosParams = {
    ordem: "ASC",
    ordenarPor: "nome",
    idLegislatura: currentLegislatura,
  };
  const defaultMinisterParams = {
    ordem: "ASC",
    ordenarPor: "nome",
  };
  const defaultOutrosParams = {
    ordem: "ASC",
    ordenarPor: "nome",
  };

  const [input, setInput] = React.useState("");
  const [keyword, setKeyword] = React.useState("");
  const [deputadosParams, setDeputadoParams] = React.useState(defaultDeputadosParams);
  const [ministerParams, setMinisterParams] = React.useState(defaultMinisterParams);
  const [outrosParams, setOutrosParams] = React.useState(defaultOutrosParams);
  const [referencias, setReferencias] = React.useState(null);
  const { data: ministrosStakeholders } = useFetchBackend("minister");
  const { data: outrosStakeholders } = useFetchBackend("person/outros-get-all");
  const [defaultStakeholdersSelect] = Object.keys(StakeholdersSelect);
  const [show, setShow] = React.useState(defaultStakeholdersSelect);
  const [recentProfiles, setRecentProfiles] = React.useState([]);
  const [recentProfilesMinister, setRecentProfilesMinister] = React.useState([]);
  const [recentProfilesOutros, setRecentProfilesOutros] = React.useState([]);

  const OutrosParamsSelect = {
    Nome: (value) => ({ nome: value }),
    Cargo: (value) => ({ condicaoEleitoral: value }),
  };
  const [defaultOutrosSelectParams] = Object.keys(OutrosParamsSelect);

  const [outrosSelectParams, setOutrosSelectParams] = React.useState(defaultOutrosSelectParams);

  const { isMobile, currIdToken, expiresOn, renewSilentToken, currUserId } =
    React.useContext(AppContext);

  const [getState, setGetState] = React.useState([]);
  var storeResponse;
  var getEstado_response;
  useEffect(() => {
    if (!currIdToken) return;
  }, [currIdToken]);
  const [stateObj, setStateObj] = useState([]);
  var names;
  var arr = [];

  const getEstado = async (keyword) => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/person/get-state/` + keyword, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        if (response.data) {
          setStateObj(response.data);

          for (var i = 0; i < response.data.length; i++) {
            arr.push(response.data[i].state_code);
          }
        }
        names = arr.join(",");
        return names;
      })
      .catch(renewSilentToken);
    names = arr.join(",");
    return arr.join(",");
  };

  const itemsPorPagina = 10;

  const DeputadosParamsSelect = {
    Nome: (value) => ({ nome: value }),
    Estado: (value) => ({ siglaUf: value }),
    Partido: (value) => ({ siglaPartido: value }),
  };

  const MinisterParamsSelect = {
    Nome: (value) => ({ minister_name: value }),
    Cargo: (value) => ({ minister_desigination: value }),
  };
  const [defaultMinisterSelectParams] = Object.keys(MinisterParamsSelect);
  const [defaultDeputadosSelectParams] = Object.keys(DeputadosParamsSelect);

  const [deputadosSelectParams, setDeputadoSelectParams] = React.useState(
    defaultDeputadosSelectParams
  );
  const [ministerSelectParams, setMinisterSelectParams] = React.useState(
    defaultMinisterSelectParams
  );

  React.useEffect(() => {
    fetchCamara("referencias/deputados").then((r) => setReferencias(formatReferenciasDeputados(r)));
    RecentFunction();
    recentProfileMinister();
    RecentFunctionOutros();
  }, []);

  const RecentFunction = () => {
    let recentProfilesLocal = JSON.parse(localStorage.getItem("recent-profiles")) || {
      profiles: {},
      order: [],
    };
    let recentProfileObjs = recentProfilesLocal.order.map((id) => recentProfilesLocal.profiles[id]);
    recentProfileObjs = recentProfileObjs.filter((item) => item !== undefined);
    setRecentProfiles(recentProfileObjs);
  };
  const recentProfileMinister = () => {
    let recentProfilesLocalMinister = JSON.parse(localStorage.getItem("recent-minister")) || {
      profiles: {},
      order: [],
    };
    let recentProfileObjsMinister = recentProfilesLocalMinister.order.map(
      (id) => recentProfilesLocalMinister.profiles[id]
    );
    recentProfileObjsMinister = recentProfileObjsMinister.filter((item) => item !== undefined);
    setRecentProfilesMinister(recentProfileObjsMinister);
  };

  const RecentFunctionOutros = () => {
    let recentProfilesLocalOutros = JSON.parse(localStorage.getItem("recent-outros")) || {
      profiles: {},
      order: [],
    };
    let recentProfileObjsOutros = recentProfilesLocalOutros.order.map(
      (id) => recentProfilesLocalOutros.profiles[id]
    );
    recentProfileObjsOutros = recentProfileObjsOutros.filter((item) => item !== undefined);
    setRecentProfilesOutros(recentProfileObjsOutros);
  };
  const {
    data: deputadosStakeholders,
    loading,
    observerRef,
  } = useInfiniteCamara("deputados", itemsPorPagina, deputadosParams);

  const getDeputadosParams = React.useCallback(
    (searchString) => {
      if (!searchString) return {};
      const paramValue = searchString;
      return DeputadosParamsSelect[deputadosSelectParams](paramValue);
    },
    [referencias, deputadosSelectParams]
  );

  const getMinisterParams = React.useCallback(
    (value) => {
      if (!value) return {};
      const paramValue =
        ministerSelectParams !== "Estado" ? value : getUFValida(referencias, value);
      return MinisterParamsSelect[ministerSelectParams](paramValue);
    },
    [referencias, ministerSelectParams]
  );
  const getOutrosParams = React.useCallback(
    (value) => {
      if (!value) return {};
      const paramValue = outrosSelectParams !== "Estado" ? value : getUFValida(referencias, value);
      return OutrosParamsSelect[outrosSelectParams](paramValue);
    },
    [referencias, outrosSelectParams]
  );

  const handleParams = (searchString) => {
    if (searchString != undefined && searchString != null && searchString != "") {
      var selectedSearchCategory = $("#search-category").text().trim().toUpperCase();
      if (selectedSearchCategory == "CÂMARA DOS DEPUTADOS") {
        setDeputadoParams({
          ...defaultDeputadosParams,
          ...getDeputadosParams(searchString),
        });
      } else if (selectedSearchCategory == "MINISTÉRIO DA SAÚDE") {
        setMinisterParams({
          ...defaultMinisterParams,
          ...getMinisterParams(searchString),
        });
      } else if (selectedSearchCategory == "OUTROS") {
        setOutrosParams({ ...defaultOutrosParams, ...getOutrosParams(searchString) });
      }
    }
  };
  const mobileClass = isMobile ? "mobile" : "";
  const sizeStyle = isMobile ? { width: "90vw", height: "80vh" } : { width: 1000, height: 500 };

  ////for handling pdf////////
  const [pdfLink, setPdfLink] = useState([]);
  const handleLinkPdf = async (name) => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/person/outros-get-pdf/${name}`, {
        headers: { Authorization: "Bearer " + currIdToken },
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(blob);
        let newWindow = window.open(fileURL);
        setPdfLink(fileURL);
      })
      .catch(renewSilentToken);
  };

  ////Pdf modal
  const [showModal, setShowModal] = React.useState(false);
  const PdfOpenModal = () => {
    setShowModal(true);
  };

  const PdfCloseModal = () => {
    setShowModal(false);
  };

  /**
   * Function to replace a character of a string at particular index
   */
  String.prototype.replaceAt = function (index, replacement) {
    return this.substring(0, index) + replacement + this.substring(index + replacement.length);
  };

  return (
    <>
      <h5
        className="stakeholder-heading"
        style={{ color: "var(--bayer-md-blue)", fontWeight: "bold" }}
      >
        Pessoas
      </h5>
      <Typography mt={1}>
        Digite no campo de busca o nome, Sigla do estado ou partido político para localizar a pessoa
        desejada
      </Typography>
      <FormControl sx={{ minWidth: 180, mt: 3 }} size="small">
        <InputLabel id="filter-select-label">Fonte</InputLabel>
        <Select
          labelId="filter-select-label"
          value={show}
          id="search-category"
          onChange={v(setShow)}
        >
          {Object.keys(StakeholdersSelect).map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="d-flex gap-2 mt-3">
        {show === StakeholdersSelect["Câmara dos Deputados"] && (
          <>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <InputLabel id="params-select-label">Filtro</InputLabel>
              <Select
                // disabled={!referencias}
                value={deputadosSelectParams}
                onChange={v(setDeputadoSelectParams)}
                labelId="params-select-label"
              >
                {Object.keys(DeputadosParamsSelect).map((key) => {
                  return <MenuItem value={key}>{key}</MenuItem>;
                })}
              </Select>
            </FormControl>
            {deputadosSelectParams != "Estado" ? (
              <DelayedInput
                InputProps={InputProps(loading)}
                fullWidth
                value={input}
                onChange={(e) => setInput(e.target.value === "" ? " " : e.target.value.trim())}
                label="Pesquisar"
                size="small"
                InputComponent={TextField}
                onChangeAfterDelay={v(handleParams)}
                delay={300}
              />
            ) : (
              <DelayedInput
                InputProps={InputProps(loading)}
                fullWidth
                value={input}
                onChange={(e) => setInput(e.target.value === "" ? " " : e.target.value.trim())}
                label="Pesquise pela sigla do Estado"
                size="small"
                InputComponent={TextField}
                onChangeAfterDelay={v(handleParams)}
                delay={100}
              />
            )}
          </>
        )}
        {show === StakeholdersSelect["Ministério da Saúde"] && (
          <>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <InputLabel id="params-select-label">Filtro</InputLabel>
              <Select
                // disabled={!referencias}
                value={ministerSelectParams}
                onChange={v(setMinisterSelectParams)}
                labelId="params-select-label"
              >
                {Object.keys(MinisterParamsSelect).map((key) => {
                  return <MenuItem value={key}>{key}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <DelayedInput
              InputProps={InputProps(loading)}
              fullWidth
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
                // handleSearch(e.target.value);
              }}
              label="Pesquisar"
              size="small"
              InputComponent={TextField}
              delay={300}
            />
          </>
        )}
        {show === StakeholdersSelect["Outros"] && (
          <>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <InputLabel id="params-select-label">Filtro</InputLabel>
              <Select
                // disabled={!referencias}
                value={outrosSelectParams}
                onChange={v(setOutrosSelectParams)}
                labelId="params-select-label"
              >
                {Object.keys(OutrosParamsSelect).map((key) => {
                  return <MenuItem value={key}>{key}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <DelayedInput
              InputProps={InputProps(loading)}
              fullWidth
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              label="Pesquisar"
              size="small"
              InputComponent={TextField}
              delay={300}
            />
          </>
        )}
      </div>

      <Grid
        container
        spacing={2}
        style={{ maxHeight: "54vh", marginTop: "2%" }}
        {...gridMobileProps(isMobile)}
      >
        {show === StakeholdersSelect["Câmara dos Deputados"] &&
          (deputadosStakeholders.length > 0 ? (
            deputadosStakeholders?.map((item, i) =>
              i === deputadosStakeholders.length - 1 &&
              !(deputadosStakeholders.length < itemsPorPagina) ? (
                <StakeholderCard deputado item={item} ref={observerRef} />
              ) : (
                <StakeholderCard deputado item={item} />
              )
            )
          ) : (
            <b>
              Olá, devido às mudanças recentes decorrentes de transição governamental, estamos
              enfrentando instabilidade no portal de dados abertos e por isso alguns dados estão
              inacessíveis. Assim que o serviço se reestabelecer, os dados ficarão automaticamente
              disponíveis
            </b>
          ))}
        {show === StakeholdersSelect["Ministério da Saúde"] && (
          <MinisterCard keyword={keyword} ministerSelectParams={ministerSelectParams} />
        )}
        {show === StakeholdersSelect["Outros"] && (
          <OutrosCard
            keyword={keyword}
            outrosSelectParams={outrosSelectParams}
            RecentFunctionOutros={RecentFunctionOutros}
          />
        )}
      </Grid>
    </>
  );
}
