import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "../CSS/HomePostDetails.css";
import { AccessTime, Close, Square, StarBorder, StarRounded } from "@mui/icons-material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import axios from "axios";
import * as moment from "moment";
import PatriLogo from "../../image/Logo_Patri.png";
import YoutubeLogo from "../../image/Fav_Youtube_3.png";
import TwitterLogo from "../../image/Fav_Twitter_3.png";
import $ from "jquery";
import { AppContext } from "../../App";
import FavouriteStar from "../../ReuseableComponents/FavouriteStar";
import JotaLogoUpdated from "../../image/Logo_Jota_2.png";
import PatriLogoUpdated from "../../image/Logo_Patri_2.png";
import HomeButtonDetails from "./HomeButtonDetails";
import { HomeContext } from "./Home";

export default function HomePostDetailsBackFavs({ item, close, getFavList, type }) {

  console.log(item, "item")

  const { currIdToken, currUserId, expiresOn, renewSilentToken, favData, setFavData, isMobile } =
    React.useContext(AppContext);

  const { setModalContent, keywordModalData } = React.useContext(HomeContext);

  useEffect(() => {
    if (!currIdToken) return;
  }, [currIdToken]);

  const displayText = (text) => {
    text = text.replace(/\\/g, "");
    text = text.replace(
      /\s*style=(["'])(.*?)\1/gm,
      'style="text-align:justify; font-weight: normal; font-size: 1rem;"'
    );
    // text=text.replace(/span/gm,'p');
    // $("h5").css({ "font-size": "20px", "text-transform": "uppercase" });
    $("h3").css({ "font-size": "19px" });
    $("h1").css({ "font-size": "22px" });
    text = text.replace(/Repórter/g, '');
    text = text.replace(/brstyle="text-align:justify; font-weight: normal; font-size: 1rem;"/gm, 'br/');
    text = text.replace(/André Rossi/g, 'André Rossi<br/>Repórter');

    let newText = text;
    var condition = newText.includes("url=");
    var conditionImg = newText.includes("<img");
    var conditionText = newText.includes("<a href");

    if (conditionImg === true) {
      return text;

    } else if (conditionText === true) {
      return text;
    } else if (condition === true) {
      text = text.replace(/(https:\/\/[^\s]+)/g, "<a href='$1' target='_blank'>$1</a>");

    } else {
      return text;
    };

    $("p").html(function (i, h) {
      return h.replace(/&nbsp;/g, "");
    });

    var remove = [
      "border",
      "cellspacing",
      "cellpadding",
      "height",
      "max-width",
      "valign",
      "align",
      "Helvetica",
      "sans-serif",
      "font-weight",
      "bold",
    ];
    const removeAttr = (text, attr) => {
      return text.replace(
        new RegExp("(<[^>]+)\\s" + attr + "\\s*=['\"].*?['\"]", "gi"),
        "$1"
      );
    };

    remove.forEach(function (attr) {
      text = removeAttr(text, attr);
    });
    return text;
  };

  const time = (text) => {
    let timeAgo = text.split("~");
    return timeAgo[0];
  };

  const removeFavourites = async (e) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    const params = {
      user_id: currUserId, //loggedInUser ID
      type: type,
      favourite_ids: item.news_id
        ? [item.news_id.toString()]
        : item.agenda_id
          ? [item.agenda_id.toString()]
          : item.social_media_twitter_id
            ? [item.social_media_twitter_id.toString()]
            : item.social_media_youtube_id
              ? [item.social_media_youtube_id.toString()]
              : "",
    };

    await axios
      .post(`${process.env.REACT_APP_API_KEY}/favourites/remove-favourite`, params, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        setFavData((f) => {
          return f.filter(({ id }) =>
            id !== item.news_id
              ? item.news_id
              : item.agenda_id
                ? item.agenda_id
                : item.social_media_twitter_id
                  ? item.social_media_twitter_id
                  : item.social_media_youtube_id
                    ? item.social_media_youtube_id
                    : ""
          );
        });
        close();
        getFavList();
      })
      .catch(renewSilentToken);
  };

  const openLink = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div>
      <div
        className="fav-back-btn"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          height: "20px",
          position: "fixed",
          background: "white",
          width: !isMobile ? "94%" : "",
        }}
      >
        <p style={{ cursor: "pointer" }} onClick={close}>
          <KeyboardBackspaceIcon /> Voltar
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          height: "20px",
          marginBottom: "10px",
        }}
      >
        <Close
          style={{
            fontSize: "17px",
            cursor: "pointer",
            position: "fixed",
            marginRight: "-27px",
            marginTop: "-20px",
          }}
          onClick={close}
        />
      </div>
      <div className="modal-top-bar">
        <div className="home-post-details-top">
          <div className="home-post-details-time">
            <AccessTime className="home-post-details-clock" />
            {item.start_datetime
              ? moment(item.start_datetime).format("DD/MM/YYYY")
              : item.created_at
                ? moment(item.created_at).format("DD/MM/YYYY")
                : item.published_at && item.social_media_youtube_id
                  ? moment(item.published_at).format("DD/MM/YYYY")
                  : ""}
            <div className="access-time" style={{ marginLeft: "27px" }}>
              <Typography>
                Alguns links disponibilizados nesta notícia, podem conter conteúdos pagos
              </Typography>
              {item.time_ago ? <div>{time(item.time_ago)} atrás</div> : ""}
            </div>
          </div>
        </div>
        <div className="home-post-details-favourite">
          <StarRounded
            className="star"
            style={{ cursor: "pointer" }}
            key={favData.id}
            onClick={() =>
              removeFavourites(
                item.news_id
                  ? item.news_id
                  : item.agenda_id
                    ? item.agenda_id
                    : item.social_media_twitter_id
                      ? item.social_media_twitter_id
                      : item.social_media_youtube_id
                        ? item.social_media_youtube_id
                        : ""
              )
            }
          />
        </div>
      </div>
      <div id="container">
        <div id="floated">
          <div className="modal-image">
            <Box
              component="img"
              sx={{
                maxHeight: 300,
                width: "90%",
                margin: "5px",
              }}
              alt="The house from the offer."
              src={
                item.datasource_name === "JOTA"
                  ? item.news_image
                  : item.social_media_youtube_id
                    ? YoutubeLogo
                    : item.social_media_twitter_id
                      ? TwitterLogo
                      : PatriLogo
              }
            />
          </div>
        </div>
        <Typography
          className="modal-title"
          variant="h5"
          style={{ fontWeight: "bold", textTransform: "capitalize" }}
        >
          {item.news_title ? item.news_title : item.title ? item.title : ""}
        </Typography>
        <br />
        <Typography id="edited"
          className="modal-description"
          variant="subtitile2"
          style={{ fontWeight: "bold", alignContent: "center" }}
        >
        <div id="newsandtwitter" dangerouslySetInnerHTML={{ __html: item.news_text ? displayText(item.news_text) : "" }}></div>
        <div id="agendaandyoutube" dangerouslySetInnerHTML={{ __html: item.description ? displayText(item.description) : "" }}></div>
        </Typography>
        {item.twitter_url || item.video_url ? (
          <Typography className="modal-url" variant="h7">
            <a
              href="#"
              onClick={() =>
                openLink(
                  item.twitter_url
                    ? item.twitter_url
                    : item.video_url
                      ? item.video_url
                      : ""
                )
              }
            >
              URL:{" "}
              {item.twitter_url ? item.twitter_url : item.video_url ? item.video_url : ""}
            </a>
          </Typography>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
