import React, { useEffect, useState } from "react";
import RestrictedImg from "../image/Restricted_Img.png";
import * as Scroll from "react-scroll";
import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import { KeyboardArrowUpTwoTone } from "@mui/icons-material";
import { ScrollToTop } from "./ScrollToTop";

export default function Footer(props) {
  return (
    <>
      <div>
        <ScrollToTop />
        <img
          src={RestrictedImg}
          className="restricted-img"
          style={{
            width: "80px",
            height: "30px",
            float: "right",
            marginTop: "-30px",
            marginRight: "30px",
            zIndex: "1",
          }}
        ></img>
      </div>
    </>
  );
}
