import { Box, Grid } from "@mui/material";
import React from "react";
export default function StakeholderProfileProfissoes({ profissoes }) {
  return (
    <div>
      <h5 style={{ marginBottom: "15px", fontWeight: "bold" }}>
        Histórico Político e Profissional
      </h5>
      <Box mt={2}>
        {profissoes.map(({ dataHora, titulo, codTipoProfissao }) => {
          return (
            <Grid my={2} container columnSpacing={5}>
              <Grid item>
                <>{titulo}</>
              </Grid>
            </Grid>
          );
        })}
      </Box>
    </div>
  );
}
