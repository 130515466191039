import React from "react";
import Button from "@mui/material/Button";
import "../CSS/HomeButtonDetails.css";
import { Link } from "react-router-dom";

export default function HomeRecentSearchPagesDetails({ item }) {
  return (
    <>
      <div
        style={{
          display: "flex",
          backgroundColor: "#e6f3ff",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "10px",
          height: "60px",
          borderRadius: "10px",
          marginTop: "15px",
          border: "1px solid var(--bayer-md-blue)",
        }}
      >
        <Link
          component={Link}
          to={item.path}
          style={{
            textDecoration: "none",
          }}
        >
          <Button style={{ color: "var(--bayer-md-blue)" }}>
            {item.icon}
            <span style={{ marginLeft: "5px" }}>{item.text}</span>
          </Button>
        </Link>
      </div>
    </>
  );
}
