import React from "react";
import { AppContext } from "../App";
import { getAuthHeader } from "../utils";
const backendUrl = process.env.REACT_APP_API_KEY;

const fetchBackend = (endpoint, options) =>
  fetch(endpoint, options).then((r) => r.json());

export const useGetFetch = () => {
  const { currIdToken } = React.useContext(AppContext);
  return (endpoint, { headers, ...options }) =>
    fetchBackend(endpoint, {
      headers: {
        ...getAuthHeader(currIdToken),
        ...(headers && { ...headers }),
      },
      ...options,
    });
};

export const useFetchBackend = (endpoint) => {
  const [response, setResponse] = React.useState(null);
  const [error, setError] = React.useState(null);
  const { currIdToken } = React.useContext(AppContext);

  React.useEffect(() => {
    currIdToken &&
      fetch(`${backendUrl}/${endpoint}`, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
        .then((r) => r.json())
        .then(setResponse)
        .catch(setError);
  }, [endpoint, currIdToken]);

  return { data: response, setData: setResponse, error };
};
