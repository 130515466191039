import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AppContext } from "../../../../App";

function MinisterPoliticalConnection({ stakeholder }) {

  const { isMobile} =
    React.useContext(AppContext);
  const mobileClass = isMobile ? "mobile" : "";

  return (
    <div>
      <Box mt={2}>
        <Grid my={2} container columnSpacing={5}>
          <Grid item>

            <h5 style={{ marginBottom: "15px", fontWeight: "bold" }}>
              Gabinete
            </h5>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default MinisterPoliticalConnection
