import React from "react";
import { Box, Grid } from "@mui/material";

export default function StakeholderProfileFrentes({ titulo }) {
  return (
    <Grid my={2} container columnSpacing={5}>
      <Grid item>
        <b style={{ color: "var(--bayer-md-green)" }}>{titulo}</b>
      </Grid>
    </Grid>
  );
}
