import React from 'react'
import { Box, Grid } from "@mui/material";

export default function StakeholderProfileOcupacoes({ocupacoes}) {
  if(ocupacoes.anoInicio === "null")
    return[];
   else{
  return (
      <div>
        <h5 style={{ marginBottom: "15px", fontWeight: "bold" }}>
          Histórico de Ocupações
        </h5>
        <Box mt={2}>
          {ocupacoes.map(({ anoFim, anoInicio, entidade, entidadePais, entidadeUF, titulo }) => {
            return anoInicio && (
              <Grid my={2} container columnSpacing={5}>
                <Grid item>
                  <b style={{ color: "var(--bayer-md-green)" }}>
                    {anoInicio} - {anoFim ?? "Atual"}
                  </b>
                </Grid>
                <Grid item>
                  <b>{titulo}</b>
                  <p>{entidade}</p>
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </div>
  )}
}
