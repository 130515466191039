import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Avatar, Box, Card, Divider } from "@mui/material";
import { AccessTime, Square, StarBorder, StarRounded } from "@mui/icons-material";
import { Spinner } from "react-bootstrap";
import StakeholderProfileNewsDetails from "../../Stakeholders/Pessoas/Noticias/StakeholderProfileNewsDetails";
import axios from 'axios'
import { AppContext } from "../../App";

export default function GoogleNews({ item }) {
  const [googleNews, setGoogleNews] = useState([]);
  const [newsFlag, setNewsFlag] = useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const {
    currIdToken,
    currUserId,
    expiresOn,
    renewSilentToken,
    favData,
    setFavData,
    isMobile,
  } = React.useContext(AppContext);

  const sizeStyle = isMobile
  ? { width: "70vw", height: "80vh" }
  : { width: 1000, height: 500 };

  const openModal = (item) => {
    setModalContent(<StakeholderProfileNewsDetails item={item} close={closeModal} />);
    setShowModal(true);
  };
  
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div
        style={{
          paddingRight: "12px"
        }}
        onClick={() => openModal(item)}
      >
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "10px",
              cursor: "pointer",
            }}
          >
            <div style={{ marginLeft: "10px", width: "100%" }}>
              <Typography
                className="modal-title"
                style={{ fontWeight: "bold" }}
              >
                {item.title}{" "}
              </Typography>
              <Typography
                className="modal-description"
                dangerouslySetInnerHTML={{
                  __html: item.desc,
                }}
              ></Typography>
              <div className="modal-author-time">
                <div className="author">
                  <Square
                    style={{ padding: "4px" }}
                    className="home-btn-details-square"
                  />{" "}
                  {item.media}{" "}
                </div>
                <div className="time" style={{ textTransform: "capitalize" }}>
                  <AccessTime
                    style={{ padding: "4px" }}
                    className="home-btn-details-clock"
                  />{" "}
                  {item.date}
                </div>
              </div>
            </div>
            <Divider className="border" />
          </div>
          <Divider />
        </>
      </div>

      <Modal open={showModal} onClose={closeModal}>
        <Box sx={{ ...styleModal, ...sizeStyle }}>
          <div
            style={{
              width: "100%",
              overflowY: "scroll",
              overflowX: "hidden",
              paddingRight: "16px",
            }}
          >
            {modalContent}
          </div>
        </Box>
      </Modal>
    </>
  );
}
const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  pr: 2,
  borderRadius: "10px",
  display: "flex",
};