import React, { useState, useEffect } from "react";
import "../../../card.css";
import { Grid, Paper, Button, Box, Typography } from "@mui/material";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../../CSS/institutionCard.css";
import { AppContext } from "../../../../App";
import EmptyProfile from "../../../../image/EmptyProfile.png";
import axios from "axios";
import { withRouter } from "react-router-dom";
import ScrollButton from "../../../../MasterPage/scrollToTop/ScrollButton";

function Information(props) {
  const { id } = props.match.params;
  const { isMobile, currIdToken, expiresOn, renewSilentToken, institutionData } =
    React.useContext(AppContext);
  const mobileClass = isMobile ? "mobile" : "";
  const [imageErrorFlag, setImageErrorFlag] = React.useState(false);
  const handleImageError = () => setImageErrorFlag(true);

  const informationPara = (text) => {
    let paragraph = text
      .replace(/ATUAÇÃO/i, "ATUAÇÃO\n")
      .replace("_Botões/Virada/Banner_Virada_720x300.jpg", "")
      .replace("<a href=\"/images/fluxo_incorporacao_gde.png\"></a>", "")
      .replace(/YouTube video player/i, "");
    paragraph = paragraph
      .replace(/\n+/gm, "\n")
      .replace(/&nbsp;/gm, "")

      .replace(/\t+/gm, " ")
      .replace(/(<([^>]+)>)/gi, "")
      .replace(/[\[\]']+/g, '')
      .replace("/<div>\s*?<\/div>/", "")
      .replace(
        /(?:https?:\/\/)?(?:www\.)?youtu(?:\.be\/|be.com\/\S*(?:watch|embed)(?:(?:(?=\/[-a-zA-Z0-9_]{11,}(?!\S))\/)|(?:\S*v=|v\/)))([-a-zA-Z0-9_]{11,})/gm,
        ""
      )
      .replace(
        /((?:https?|ftp?|http):\/\/[a-z./_0-9A-Z\-]+)|(\n\/[a-z_A-Z\-\0-9\\u00f5]+)/gm,
        ""
      )
      .replace(/(;\s+[a-z]\s+)|(;\s+)/gm, "\n")
      .split("\n");
    return paragraph;
  };
const dataFormate =(text)=>{
  let demo = text.replace("/<div>\s*?<\/div>/", "")
  return demo
}
  return (
    <div>
      {institutionData.length > 0 ? (
        institutionData
          .filter((data) => data.institution_id == id)
          .map((items) => {
            return (
              <div style={{ textAlign: "justify" }}>
                <div className="card-profile">
                  <Typography classsName="institution-info"
                  > 
                    {informationPara(items.institution_info).map((data, index) => {
                      return (
                        <div>
                          {data != "&nbsp;"  ?
                         
                            <div>
                              <div dangerouslySetInnerHTML={{ __html: data }} />
                              <br />
                            </div> : ""
                          }

                        </div>
                      );
                    })}
                  </Typography>

                  <br />
                  <Typography>
                    <span className="ContactDetails">Número de telefone:</span>{" "}
                    {items.institution_telephone !== "" &&
                      items.institution_telephone !== "NA" &&
                      items.institution_telephone !== "No Data"
                      ? items.institution_telephone
                      : "Não disponível"}{" "}
                    <br />
                    <span className="ContactDetails">Número do WhatsApp:</span>{" "}
                    {items.institution_whatsapp !== "" &&
                      items.institution_whatsapp !== "NA" &&
                      items.institution_whatsapp !== "No Data"
                      ? items.institution_whatsapp
                      : "Não disponível"}{" "}
                    <br />
                    <span className="ContactDetails">Endereço de e-mail:</span>{" "}
                    <a href={`mailto:${items.institution_mail}`}>
                      {items.institution_mail !== "" &&
                        items.institution_mail !== "NA" &&
                        items.institution_mail !== "No Data"
                        ? items.institution_mail
                        : "Não disponível"}
                    </a>{" "}
                    <br />
                    <span className="ContactDetails">Endereço:</span>{" "}
                    {items.institution_address !== "" &&
                      items.institution_address !== "NA" &&
                      items.institution_address !== "No Data"
                      ? items.institution_address
                      : "Não disponível"}{" "}
                    <br />
                  </Typography>
                </div>
              </div>
            );
          })
      ) : (
        <b>Atualmente, os dados não estão disponíveis para o Informações.</b>
      )}

    </div>
  );
}

export default withRouter(Information);
