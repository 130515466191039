import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Avatar, Card, Divider } from "@mui/material";
import { AccessTime, Square, StarBorder, StarRounded } from "@mui/icons-material";
import "../CSS/AgendaPosts.css";
import AgendaPostDetails from "./AgendaPostDetails";
import * as moment from "moment";
import JotaLogo from "../../image/Logo_Jota.png";
import PatriLogo from "../../image/Logo_Patri.png";
import axios from "axios";
import { AppContext } from "../../App";
import FavouriteStar from "../../ReuseableComponents/FavouriteStar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 5,
  p: 4,
  overflowY: "auto",
  borderRadius: "10px",
};

export default function AgendaPost({ item }) {
  const [showModal, setShowModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  const {
    currIdToken,
    currUserId,
    expiresOn,
    renewSilentToken,
    favData,
    setFavData,
    isMobile,
    getFavListAPI,
  } = React.useContext(AppContext);

  useEffect(() => {
    if (!currIdToken) return;
  }, [currIdToken]);

  const openModal = (item) => {
    setModalContent(<AgendaPostDetails item={item} close={closeModal} />);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const sizeStyle = isMobile
    ? { width: "90vw", height: "80vh" }
    : { width: 1000, height: 500 };

  const addFavourites = async (e) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    e.stopPropagation();
    const params = {
      user_id: currUserId, //loggedInUser ID
      type: "fav_agenda",
      favourite_ids: [item.agenda_id.toString()],
    };

    await axios
      .post(`${process.env.REACT_APP_API_KEY}/favourites/add-favourite`, params, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        setFavData((f) => f.concat({ id: item.agenda_id }));
        getFavListAPI();
      })
      .catch(renewSilentToken);
  };

  const removeFavourites = async (e) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    e.stopPropagation();
    const params = {
      user_id: currUserId, //loggedInUser ID
      type: "fav_agenda",
      favourite_ids: [item.agenda_id.toString()],
    };

    await axios
      .post(`${process.env.REACT_APP_API_KEY}/favourites/remove-favourite`, params, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        getFavListAPI();
        setFavData((f) => {
          return f.filter(({ id }) => id !== item.agenda_id);
        });
      })
      .catch(renewSilentToken);
  };

  const displayText = (text) => {
    text = text.replace(/\\/g, "");
    text = text.replace(/\s*style=(["'])(.*?)\1/gm, 'style="text-align:justify;"');
    var remove = [
      "border",
      "cellspacing",
      "cellpadding",
      "height",
      "width",
      "valign",
      "align",
      "Helvetica",
      "sans-serif",
    ];
    const removeAttr = (text, attr) => {
      return text.replace(
        new RegExp("(<[^>]+)\\s" + attr + "\\s*=['\"].*?['\"]", "gi"),
        "$1"
      );
    };

    remove.forEach(function (attr) {
      text = removeAttr(text, attr);
    });
  };

  const displayTime = (text) => {
    let finalTime = text.split("T");
    return finalTime[1];
  };

  return (
    <>
      <div style={{ paddingRight: "12px" }}>
        <>
          <div
            style={{ marginBottom: "10px", cursor: "pointer" }}
            onClick={() => openModal(item)}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
              }}
            >
              <div
              className="agenda-image"
                style={{
                  height: "65px",
                  width: "8%",
                }}
              >
                <Card
                  style={{
                    backgroundImage:
                      item.datasource_name === "JOTA"
                        ? `url('${JotaLogo}')`
                        : `url('${PatriLogo}')`,

                    width: "100%",
                    height: "100%",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    overflowY: "hidden",
                    cursor: "pointer",
                  }}
                ></Card>
              </div>
              <div style={{ marginLeft: "10px", width: "82%" }}>
                <Typography
                  className="modal-title"
                  variant="body2"
                  style={{ fontWeight: "bold" }}
                >
                  {item.organizer}{" "}
                </Typography>
                <Typography
                  className="modal-description"
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: displayText(item.description),
                  }}
                ></Typography>
                <div className="author">
                  <Square
                    style={{ padding: "4px" }}
                    className="home-btn-details-square"
                  />{" "}
                  {item.title}{" "}
                </div>
                <div className="time">
                  <AccessTime style={{ padding: "4px" }} />{" "}
                  {moment(item.start_datetime).format("DD/MM/YYYY")}
                  {" - "}
                  {item.start_datetime.length > 0 ? displayTime(item.start_datetime) : ""}
                </div>
              </div>
              <div className="favorites" style={{ width: "10%" }}>
                <FavouriteStar
                  addFav={addFavourites}
                  removeFav={removeFavourites}
                  selectedId={item.agenda_id}
                  favType="fav_agenda"
                />
              </div>
            </div>
          </div>
          <Divider className="border" />
        </>

        <Modal open={showModal} onClose={closeModal}>
          <Box sx={{ ...styleModal, ...sizeStyle }}>
            <div
              style={{
                width: "100%",
                overflowY: "scroll",
                overflowX: "hidden",
                paddingRight: "16px",
              }}
            >
              {modalContent}
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  pr: 2,
  borderRadius: "10px",
  display: "flex",
};
