// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pdf_container__7-TJt {\n  width: 1080px;\n  padding: 30px;\n}\n.pdf_container__7-TJt h5 {\n  color: var(--bayer-md-blue);\n}\n\n/* .container h5 {\n  font-size: 56px !important;\n  font-weight: bold;\n  margin-bottom: 15px;\n}\n\n.container * {\n  font-size: 30px;\n  line-height: 20px;\n  margin-bottom: 15px;\n} */\n\n.pdf_header__vf9LH {\n  display: flex;\n  flex-direction: column;\n}\n\n.pdf_twocolumns__3U2YS {\n  display: grid;\n  grid-template-columns: 400px 1fr;\n  gap: 20px;\n  margin-bottom: 15px;\n}\n.pdf_stackholderImg__uxsai{\n  height: 91px;\n  width: 90px;\n  margin-bottom: 10px;\n  border-radius: 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/Stakeholders/Pessoas/pdf.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;AACf;AACA;EACE,2BAA2B;AAC7B;;AAEA;;;;;;;;;;GAUG;;AAEH;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,gCAAgC;EAChC,SAAS;EACT,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".container {\n  width: 1080px;\n  padding: 30px;\n}\n.container h5 {\n  color: var(--bayer-md-blue);\n}\n\n/* .container h5 {\n  font-size: 56px !important;\n  font-weight: bold;\n  margin-bottom: 15px;\n}\n\n.container * {\n  font-size: 30px;\n  line-height: 20px;\n  margin-bottom: 15px;\n} */\n\n.header {\n  display: flex;\n  flex-direction: column;\n}\n\n.twocolumns {\n  display: grid;\n  grid-template-columns: 400px 1fr;\n  gap: 20px;\n  margin-bottom: 15px;\n}\n.stackholderImg{\n  height: 91px;\n  width: 90px;\n  margin-bottom: 10px;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "pdf_container__7-TJt",
	"header": "pdf_header__vf9LH",
	"twocolumns": "pdf_twocolumns__3U2YS",
	"stackholderImg": "pdf_stackholderImg__uxsai"
};
export default ___CSS_LOADER_EXPORT___;
