import axios from "axios";
import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import OrgosMembrosDetails from "./OrgosMembrosDetails";
import Modal from "@mui/material/Modal";
import { AppContext } from "../../../../App";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  pr: 2,
  borderRadius: "10px",
  display: "flex",
};

export default function MembrosCard({ item }) {
  const [showModal, setShowModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  const [details, setDetails] = React.useState(null);
  const { isMobile } = React.useContext(AppContext);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const sizeStyle = isMobile
    ? { width: "70vw", height: "80vh" }
    : { width: 1000, height: 500 };

  const membrosDetails = async (id) => {
    openModal();
    setModalContent("Loading...");
    await axios
      .get(`https://dadosabertos.camara.leg.br/api/v2/orgaos/${id}/membros`)
      .then((response) => {
        if (response !== "" || response !== null || response.length > 0) {
          setModalContent(
            <OrgosMembrosDetails item={response.data.dados} close={closeModal} />
          );
        } else {
          setModalContent("Atualmente os dados não estão disponíveis");
        }
      });
  };
  return (
    <>
      <Grid my={2} container columnSpacing={5}>
        <Grid item>
          <div onClick={() => membrosDetails(item.idOrgao)}>
            <b style={{ color: "var(--bayer-md-green)", cursor: "pointer" }}>
              {item.nomeOrgao}
            </b>
          </div>
        </Grid>
      </Grid>
      <Modal open={showModal} onClose={closeModal}>
        <Box sx={{ ...styleModal, ...sizeStyle }}>
          <div
            style={{
              width: "100%",
              overflowY: "scroll",
              overflowX: "hidden",
              paddingRight: "16px",
            }}
          >
            {modalContent}
          </div>
        </Box>
      </Modal>
    </>
  );
}
