import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "../CSS/HomePostDetails.css";
import { AccessTime, Close, Square, StarBorder, StarRounded } from "@mui/icons-material";
import axios from "axios";
import * as moment from "moment";
import PatriLogo from "../../image/Logo_Patri.png";
import $ from "jquery";
import { AppContext } from "../../App";
import FavouriteStar from "../../ReuseableComponents/FavouriteStar";
import JotaLogoUpdated from "../../image/Logo_Jota_2.png";
import PatriLogoUpdated from "../../image/Logo_Patri_2.png";

export default function HomePostDetails({ item, close }) {
  //Add-remove favourite
  const { currIdToken, currUserId, expiresOn, renewSilentToken, favData, setFavData } =
    React.useContext(AppContext);

  useEffect(() => {
    if (!currIdToken) return;
  }, [currIdToken]);

  const displayText = (text) => {
    text = text.replace(/\\/g, "");
    text = text.replace(
      /\s*style=(["'])(.*?)\1/gm,
      'style="text-align:justify; font-weight: normal; font-size: 1rem;"'
    );
    // text=text.replace(/span/gm,'p');
    // $("h5").css({ "font-size": "20px", "text-transform": "uppercase" });
    $("h3").css({ "font-size": "19px" });
    $("h1").css({ "font-size": "22px" });
    text=text.replace(/Repórter/g,'');
    text=text.replace(/brstyle="text-align:justify; font-weight: normal; font-size: 1rem;"/gm,'br/');
    text=text.replace(/André Rossi/g,'André Rossi<br/>Repórter');
    
    let newText = text;
    var condition = newText.includes("url=");
    var conditionImg = newText.includes("<img");
    var conditionText = newText.includes("<a href");

    if (conditionImg === true) {
      return text;

    } else if (conditionText === true) {
      return text;

    } else if (condition === true) {
      text = text.replace(/(https:\/\/[^\s]+)/g, "<a href='$1' target='_blank'>$1</a>");

    } else {
      return text;
    };
    
    $("p").html(function (i, h) {
      return h.replace(/&nbsp;/g, "");
    });

    var remove = [
      "border",
      "cellspacing",
      "cellpadding",
      "height",
      "max-width",
      "valign",
      "align",
      "Helvetica",
      "sans-serif",
      "font-weight",
      "bold",
    ];
    const removeAttr = (text, attr) => {
      return text.replace(
        new RegExp("(<[^>]+)\\s" + attr + "\\s*=['\"].*?['\"]", "gi"),
        "$1"
      );
    };

    remove.forEach(function (attr) {
      text = removeAttr(text, attr);
    });
    return text;
  };

  const time = (text) => {
    let timeAgo = text.split("~");
    return timeAgo[0];
  };

  const addFavourites = async (e) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    e.stopPropagation();
    const params = {
      user_id: currUserId, //loggedInUser ID
      type: "fav_news",
      favourite_ids: [item.news_id.toString()],
    };

    await axios
      .post(`${process.env.REACT_APP_API_KEY}/favourites/add-favourite`, params, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        setFavData((f) => {
          return f.concat({ id: item.news_id });
        });
      })
      .catch(renewSilentToken);
  };

  const removeFavourites = async (e) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    e.stopPropagation();
    const params = {
      user_id: currUserId, //loggedInUser ID
      type: "fav_news",
      favourite_ids: [item.news_id.toString()],
    };

    await axios
      .post(`${process.env.REACT_APP_API_KEY}/favourites/remove-favourite`, params, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        setFavData((f) => {
          return f.filter(({ id }) => id !== item.news_id);
        });
      })
      .catch(renewSilentToken);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end", height: "20px" }}>
        <Close
          style={{
            fontSize: "17px",
            cursor: "pointer",
            position: "fixed",
            marginRight: "-27px",
            marginTop: "-20px",
          }}
          onClick={close}
        />
      </div>
      <div className="modal-top-bar">
        <div className="home-post-details-top">
          <div className="home-post-details-time">
            <AccessTime className="home-post-details-clock" />
            {moment(item.news_date).format("DD/MM/YYYY")}
            <div className="access-time" style={{ marginLeft: "27px" }}>
              <Typography>
                Alguns links disponibilizados nesta notícia, podem conter conteúdos pagos
              </Typography>
              {time(item.time_ago)} atrás
            </div>
          </div>
        </div>
        <div className="home-post-details-favourite">
          <FavouriteStar
            addFav={addFavourites}
            removeFav={removeFavourites}
            selectedId={item.news_id}
          />
        </div>
      </div>
      <div id="container">
        <div id="floated">
          <div className="modal-image">
            <Box
              component="img"
              sx={{
                maxHeight: 300,
                width: "90%",
                margin: "5px",
              }}
              alt="The house from the offer."
              src={item.datasource_name === "JOTA" ? item.news_image : PatriLogo}
            />
          </div>
        </div>
        <Typography
          className="modal-title"
          variant="h5"
          style={{ fontWeight: "bold", textTransform: "capitalize" }}
        >
          {item.news_title}
        </Typography>
        <br/>
        <Typography
          className="modal-description"
          variant="subtitile2"
          style={{ fontWeight: "bold", alignContent: "center" }}
        >
          <div  id="edited" dangerouslySetInnerHTML={{ __html: displayText(item.news_text) }}></div>
        </Typography>
      </div>
    </div>
  );
}
 