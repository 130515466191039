import React, { useContext, useEffect, useState } from "react";
import useForm from "../../hooks/useForm";
import * as moment from "moment";
import {
  areDatesEqual,
  getDefaultReminder,
  getFullDateString,
  getRemindersByDateAndId,
  insertToCalendarDb,
  updateToCalendarDb,
  moveItemInCalendarDb,
  deleteFromCalendarDb,
  padMonth,
  formatISODateStringToJSDateObj,
} from "../../utils_calendar";
import { CalendarContext } from "../Board/context";
import DatePicker from "../DatePicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from "date-fns/locale";
import axios from "axios";
import { AppContext } from "../../App";
import { useModalReducer } from "../Modal/reducer";
registerLocale("ptBR", ptBR);

export default function ReminderForm({ id, date: dateProp, onSubmit }) {
  const {
    isMobile,
    currIdToken,
    expiresOn,
    renewSilentToken,
    currUserId,
    userEvent,
    setUserEvent,
  } = React.useContext(AppContext);
  const { calendarDb, setCalendarDb, closeModal } = useContext(CalendarContext);

  setCalendarDb(userEvent);
  const [values, setValues] = useForm(() => {
    var reminders = {};

    if (id) {
      reminders = getRemindersByDateAndId(userEvent, dateProp, id);
    } else {
      reminders = getDefaultReminder(dateProp);
    }
    return reminders;
  });
  const {
    calendar_user_event_description,
    calendar_user_event_title,
    calendar_user_event_time,
    calendar_user_event_place,
  } = values == undefined ? {} : values;
  const [date, setDate] = useState(dateProp);

  /*
   * error while installing uuid, error while using node v 16 (for crypto randomUUID module)
   * Then, decided to use the crypto web api randomUUID from the browser
   */
  useEffect(() => {
    !id && setValues({ target: { name: "id", value: crypto.randomUUID() } });
    if (!currIdToken) return;
  }, [id, setValues, currIdToken]);

  const addEvent = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/calender_event/add_user_event`,
        {
          user_id: currUserId,
          calendar_user_event_title: values.calendar_user_event_title,
          calendar_user_event_date: date,
          calendar_user_event_time: values.calendar_user_event_time,
          calendar_user_event_place: values.calendar_user_event_place,
          calendar_user_event_description: values.calendar_user_event_description,
        },
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then(({ data }) => {
        userEvents();
      })
      .catch(renewSilentToken);
  };

  const updateEvent = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/calender_event/update_user_event`,
        {
          user_id: currUserId,
          calendar_user_event_title: values.calendar_user_event_title,
          calendar_user_event_date: date,
          calendar_user_event_time: values.calendar_user_event_time,
          calendar_user_event_place: values.calendar_user_event_place,
          calendar_user_event_description: values.calendar_user_event_description,
          event_id: values.calendar_user_event_id,
        },
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then(({ data }) => {
        userEvents();
      })
      .catch(renewSilentToken);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedValues = { ...values, date };

    if (id) {
      const didDateUpdated = !areDatesEqual(date, dateProp);
      !didDateUpdated &&
        setCalendarDb(updateToCalendarDb(calendarDb, date, updatedValues, id));
      didDateUpdated &&
        setCalendarDb(
          moveItemInCalendarDb(calendarDb, dateProp, date, updatedValues, id)
        );
      updateEvent();
    } else {
      setCalendarDb(insertToCalendarDb(calendarDb, date, updatedValues));
      addEvent();
    }
    onSubmit && onSubmit(updatedValues);
  };

  const userEvents = async () => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(
        `${process.env.REACT_APP_API_KEY}/calender_event/get-all-event/${currUserId}`,

        {
          headers: { Authorization: "Bearer " + currIdToken },
        }
      )
      .then((response) => {
        setUserEvent(response.data);
      })
      .catch(renewSilentToken);
  };

  const deleteEvent = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/calender_event/delete_user_event`,
        {
          user_id: currUserId,
          calendar_user_event_title: values.calendar_user_event_title,
        },
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then(({ data }) => {
        userEvents();
      })
      .catch(renewSilentToken);
  };
  const handleDelete = (e) => {
    e.preventDefault();

    if (id) {
      deleteEvent();
      closeModal();
    } else {
      closeModal();
    }
  };

  const formatedDate = (currDate) =>
    new Date(currDate.getFullYear(), currDate.getMonth() + 1, currDate.getDate());

  const convertDateToString = (date) => {
    var formatedDate =
      (date.getMonth() + 1).toString() +
      "/" +
      date.getDate().toString() +
      "/" +
      date.getFullYear().toString() +
      " " +
      date
        .toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
        .toLowerCase();

    return formatedDate;
  };
  return (
    <div className="form-wrapper">
      <h4>
        {!id ? "Novo" : "Edit"} lembrete
        <button className="end" onClick={closeModal}>
          X
        </button>
      </h4>
      <form className="reminder-form" onSubmit={handleSubmit}>
        <h4>
          {getFullDateString(formatISODateStringToJSDateObj(date)).replace(".", "")}

          <DatePicker date={formatISODateStringToJSDateObj(date)} changeDate={setDate} />
        </h4>
        <div className="input-group">
          <input
            style={{ fontSize: "1rem" }}
            name="calendar_user_event_title"
            placeholder="Adicionar um novo título"
            value={calendar_user_event_title}
            onChange={setValues}
          />
        </div>
        <div className="time-input" title="Select Time">
          {calendar_user_event_time ? calendar_user_event_time : "00:00"}
          <DatePicker
            locale="pt-BR"
            timeIcon
            showTimeSelectOnly
            showTimeSelect
            shouldCloseOnSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            changeDate={(thisTime) => {
              setValues({
                target: {
                  name: "calendar_user_event_time",
                  value: `${thisTime.getHours()}:${padMonth(thisTime.getMinutes())}`,
                },
              });
            }}
          />
        </div>
        <div className="input-group">
          <i className="fas fa-map-marker-alt"></i>
          <input
            name="calendar_user_event_place"
            value={calendar_user_event_place}
            placeholder="Nome da cidade"
            onChange={setValues}
          />
        </div>
        <div className="input-group">
          <i className="fas fa-align-justify"></i>
          <textarea
            placeholder="Adicionar uma Descrição"
            name="calendar_user_event_description"
            value={calendar_user_event_description}
            onChange={setValues}
          />
        </div>
        <div>
          <a
            style={{
              marginTop: "20px",
              marginBottom: "30px",
              marginLeft: "auto",
              float: "left",
            }}
            type="button"
            onClick={handleDelete}
            className="exit-button"
          >
            Excluir
          </a>
          <button
            className="salvar-button"
            style={{
              marginTop: "20px",
              marginBottom: "30px",
              marginLeft: "auto",
              float: "right",
            }}
            type="submit"
          >
            Salvar 💾
          </button>
        </div>
      </form>
    </div>
  );
}

