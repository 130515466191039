import React, { useState } from "react";
import { AppContext } from "../../App";
import { getMonthYearString } from "../../utils_calendar";
import DatePicker from "../DatePicker";

export const CalendarContainer = ({ children }) => {
  return <div className="calendar-container">{children}</div>;
};

//Header
export const CalendarHeader = () => {
  const { isMobile } = React.useContext(AppContext);
  const mobileClass = isMobile ? "mobile" : "";

  return (
    <div className="header-container">
      {["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"].map(
        (weekday, index) => (
          <div className="header-card" key={index}>
            {isMobile ? weekday.substring(0, 3) : weekday}
          </div>
        )
      )}
    </div>
  );
};

export const BoardHeader = ({ date, onChooseDate, children }) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const [first, ...rest] = children;
  const styleDate = new Date(year, month - 1);

  return (
    <>
      <div className="board-header">
        <div className="start">{first}</div>
        <h1>
          <div style={{ fontSize: "24px" }}>
            {getMonthYearString(year, month).charAt(0).toUpperCase() +
              getMonthYearString(year, month).slice(1)}
          </div>
          <DatePicker showMonthYearPicker date={styleDate} changeDate={onChooseDate} />
        </h1>
        <div className="end">{rest}</div>
      </div>
    </>
  );
};
