import { Button, Collapse, Box } from "@mui/material";
import React from "react";
import FrentesCard from "./FrentesCard";

export default function StakeholderProfileFrentes({ frentes }) {
  const [collapse, setCollapse] = React.useState(false);
  const [frentesOut, frentesIn] = [frentes.slice(0, 8), frentes.slice(8, frentes.length)];
  return (
    <Box mb={3}>
      <h5 style={{ marginBottom: "15px", fontWeight: "bold" }}>Histórico de Frentes</h5>
      {frentesOut.map((item) => (
        <FrentesCard {...item} />
      ))}
      {!collapse && <Button type="button" variant="outlined" onClick={() => setCollapse(true)}>
        Expandir
      </Button>}
      <Collapse in={collapse} timeout="auto">
        {frentesIn.map((item) => (
          <FrentesCard {...item} />
        ))}
        <Button type="button" variant="outlined" onClick={() => setCollapse(false)}>
          Minimizar
        </Button>
      </Collapse>
    </Box>
  );
}