import Card from "../Card";
import { CalendarContext } from "./context";
import { BoardHeader, CalendarContainer, CalendarHeader } from "./styles";
import useCalendar from "../../hooks/useCalendar";
import { initialState } from "./reducer";
import { useState } from "react";
import { useModal } from "../Modal";
import { AppContext } from "../../App";
import { useFetchCamara } from "../../hooks/useFetchCamara";
import axios from "axios";
import React, { useEffect } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";

export default function Board() {
  const {
    isMobile,
    currIdToken,
    expiresOn,
    renewSilentToken,
    currUserId,
    userEvent,
    setUserEvent,
  } = React.useContext(AppContext);

  const { calendar, date, setDate, Button } = useCalendar();
  const [calendarDb, setCalendarDb] = useState(userEvent ? userEvent : "");
  const [activeDate, setActiveDate] = useState(new Date());

  const { Modal, openNewReminderForm, closeModal, openEditReminderForm } = useModal();
  const mobileClass = isMobile ? "mobile" : "";
  const handleClickCard = ({ detail }) => {openNewReminderForm(date, closeModal);
  };

  return (
    <>
      <CalendarContext.Provider
        value={{
          calendarDb,
          setCalendarDb,
          activeDate,
          setActiveDate,
          openEditReminderForm,
          closeModal,
          openNewReminderForm,
        }}
      >
        {isMobile ? (
          <div onClick={handleClickCard} style={{ textAlign: "end" }}>
            <AddBoxIcon style={{ color: "#66b512", cursor: "pointer" }} />
          </div>
        ) : (
          ""
        )}
        <div className={`main-wrapper ${mobileClass}`}>
          <div className="calendar-wrapper">
            <BoardHeader date={date} onChooseDate={setDate}>
              <Button.PrevMonth />
              <Button.NextMonth />
            </BoardHeader>
            <CalendarHeader />
            <CalendarContainer>
              {calendar?.map(({ date, enabled }, i) => (
                <Card key={i} date={date} enabled={enabled} />
              ))}
            </CalendarContainer>
          </div>
        </div>
        <Modal />
      </CalendarContext.Provider>
    </>
  );
}
