import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Avatar, Box, Card, Divider } from "@mui/material";
import { AccessTime, Square, StarBorder, StarRounded } from "@mui/icons-material";
import * as moment from "moment";
import axios from "axios";
import FavouriteStar from "../../../ReuseableComponents/FavouriteStar";
import { AppContext } from "../../../App";
import StakeholderProfileNewsDetails from "./StakeholderProfileNewsDetails";
import { Spinner } from "react-bootstrap";
import PessoasMessage from "../FooterMessages/Js/PessoasMessage";

export default function StakeholdersProfileNoticias({ noticias }) {
  const [showModal, setShowModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const { isMobile, currIdToken, expiresOn, renewSilentToken, currUserId, setFavData } =
    React.useContext(AppContext);
  const [stakeholderNews, setStakeholderNews] = useState([]);
  const [newsFlag, setNewsFlag] = useState(true);

  const sizeStyle = isMobile
    ? { width: "70vw", height: "80vh" }
    : { width: 1000, height: 500 };

  useEffect(() => {
    if (!currIdToken && !noticias) return;
    getNews();
  }, [currIdToken, noticias]);

  const getNews = async (e) => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/news/get-google-news/` + noticias, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        if (
          response.data !== "" ||
          response.data !== undefined ||
          response.data !== null ||
          response.data !== []
        ) {
          setStakeholderNews(response.data);
          setNewsFlag(true);
          setLoading(true);
        } else setNewsFlag(false);
      })
      .catch(renewSilentToken);
  };
  const openModal = (item) => {
    setModalContent(<StakeholderProfileNewsDetails item={item} close={closeModal} />);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {loading ? (
        <div style={{ marginBottom: "40px" }}>
          {stakeholderNews.length > 0
            ? stakeholderNews.map((item) => {
              return (
                <>
                  <div style={{ paddingRight: "12px" }}>
                    {loading ? (
                      <>
                        <div
                          style={{
                            paddingRight: "12px",
                          }}
                          onClick={() => openModal(item)}
                        >
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: "10px",
                                cursor: "pointer",
                              }}
                            >
                              <div style={{ marginLeft: "10px", width: "100%" }}>
                                <Typography
                                  className="modal-title"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {item.title}{" "}
                                </Typography>
                                <Typography
                                  className="modal-description"
                                  dangerouslySetInnerHTML={{
                                    __html: item.desc,
                                  }}
                                ></Typography>
                                <div className="modal-author-time">
                                  <div className="author">
                                    <Square
                                      style={{ padding: "4px" }}
                                      className="home-btn-details-square"
                                    />{" "}
                                    {item.media}{" "}
                                  </div>
                                  <div
                                    className="time"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    <AccessTime
                                      style={{ padding: "4px" }}
                                      className="home-btn-details-clock"
                                    />{" "}
                                    {item.date}
                                  </div>
                                </div>
                              </div>
                              <Divider className="border" />
                            </div>
                            <Divider />
                          </>
                        </div>
                      </>
                    ) : (
                      <div
                        className="normalspinner"
                        style={{
                          width: "100%",
                          zIndex: 9999,
                          alignItems: "center",
                          textAlign: "center",
                          marginTop: "150px",
                        }}
                      >
                        <Spinner animation="border"></Spinner>
                      </div>
                    )}
                  </div>
                </>
              );
            })
            : "Não é possível buscar notícias no momento. Por favor, tente novamente após algum tempo."}
        </div>
      ) : (
        <div
          className="normalspinner"
          style={{
            width: "100%",
            zIndex: 9999,
            alignItems: "center",
            textAlign: "center",
            marginTop: "150px",
          }}
        >
          <Spinner animation="border"></Spinner>
        </div>
      )}

      <Modal open={showModal} onClose={closeModal}>
        <Box sx={{ ...styleModal, ...sizeStyle }}>
          <div
            style={{
              width: "100%",
              overflowY: "scroll",
              overflowX: "hidden",
              paddingRight: "16px",
            }}
          >
            {modalContent}
          </div>
        </Box>
      </Modal>
      <PessoasMessage />
    </>
  );
}
const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  pr: 2,
  borderRadius: "10px",
  display: "flex",
};
