import React, { useEffect } from "react";
import ReactModal from "react-modal";
import { AppContext } from "../../App";
import { useModalReducer } from "./reducer";

export default function CalendarModal({ isOpen, children, onRequestClose }) {
  useEffect(() => {
    ReactModal.setAppElement("body");
  }, []);
  const { isMobile } = React.useContext(AppContext);
  const mobileClass = isMobile ? "mobile" : "";
  return (
    <ReactModal
      overlayClassName={{
        base: "CalendarModal__Overlay",
        afterOpen: "CalendarModal__Overlay--after-open",
        beforeClose: "CalendarModal__Overlay--before-close",
      }}
      className={`CalendarModal__Content ${mobileClass}`}
      closeTimeoutMS={250}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      {children}
    </ReactModal>
  );
}

export const useModal = () => {
  const [{ isOpen, content }, dispatch] = useModalReducer();

  const closeModal = () => {
    dispatch({ type: "CLOSE_MODAL", payload: false });
  };

  const Modal = () => {
    return (
      <CalendarModal isOpen={isOpen} onRequestClose={closeModal}>
        {content}
      </CalendarModal>
    );
  };
  return {
    Modal,
    openNewReminderForm: (date, onSubmit) => {
      dispatch({ type: "OPEN_NEW_REMINDER_FORM", payload: { date, onSubmit } });
    },
    openEditReminderForm: (date, onSubmit, id) => {
      dispatch({ type: "OPEN_EDIT_REMINDER_FORM", payload: { date, onSubmit, id } });
    },
    closeModal,
  };
};
