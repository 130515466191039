import { Button, Collapse, Box } from "@mui/material";
import React from "react";
import DadosCard from "./DadosCard";

export default function StakeholdersProfileDados({ dados }) {

  return (
    <Box mb={3}>
      <DadosCard/>
    </Box>
  );
}

