import * as React from "react";
import Carousel, { consts } from "react-elastic-carousel";
import HomePostDetails from "./HomePostDetails";
import "../CSS/style.css";
import HomePostCard from "./HomePostCard";
import { AppContext } from "../../App";
import HomePostModal from "./HomePostModal";
import { useState, useEffect } from "react";
import "../CSS/style.css";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { useGetFetch } from "../../hooks/useFetchBackend";
import { getAuthHeader } from "../../utils";
import { KeyboardArrowLeftSharp, KeyboardArrowRightSharp } from "@mui/icons-material";
import { Spinner } from "react-bootstrap";

function HomePosts({ post }) {
  const [showModal, setShowModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  const { isMobile, currIdToken, expiresOn, renewSilentToken, newsData } =
    React.useContext(AppContext);

  const openModal = (item) => {
    setModalContent(<HomePostDetails item={item} close={closeModal} />);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const carouselRef = React.useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(newsData.length ?? 0);
    }
  };
  const resetTimeout = React.useRef(null);
  const onNextEnd = ({ index }) => {
    clearTimeout(resetTimeout.current);
    if (index + 1 === newsData.length) {
      resetTimeout.current = setTimeout(() => {
        carouselRef.current.goTo(0);
      }, 5000); // same time
    }
  };

  return (
    <>
      <Carousel
        ref={carouselRef}
        onNextStart={onNextStart}
        onPrevStart={onPrevStart}
        onNextEnd={onNextEnd}
        enableAutoPlay
        disableArrowsOnEnd={false}
        autoPlaySpeed={7000}
        className="home-carousel"
      >
        {!(Array.isArray(newsData) && newsData.length) && (
          <CircularProgress style={{ height: "16px", width: "16px" }} />
        )}
        {newsData?.map((item, index) => (
          <HomePostCard
            item={item}
            onClick={() => openModal(item)}
            key={`home-postcard-${index}`}
          />
        ))}
      </Carousel>
      <HomePostModal open={showModal} onClose={closeModal} isMobile={isMobile}>
        {modalContent}
      </HomePostModal>
    </>
  );
}

export default HomePosts;
