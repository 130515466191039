import { Box, Grid, Paper, Button } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

export default function StakeholderProfileTabPanel({ children, value, index, ...other }) {
  const cardWidth = 275;

  const Item = styled(Paper)(({ theme }) => ({
    minWidth: cardWidth,
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  const scrollRef = React.useRef();

  //Scroll right for the column
  const handleButtonClick = () => {
    scrollRef.current.scroll({
      left: scrollRef.current.scrollLeft + 500,
      behavior: "smooth",
    });
  };

  //Scroll left for the column
  const handleButtonClick2 = () => {
    scrollRef.current.scroll({
      left: scrollRef.current.scrollLeft - 500,
      behavior: "smooth",
    });
  };


  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box px={1} py={3}>
            {children}
          </Box>
        )}
      </div>
    </>
  );
}
