import { Box, Grid, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import StakeholdersProfileDados from "./Dados/StakeholderProfileDados";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { textAlign } from "@mui/system";
import { Tag } from "@mui/icons-material";
import * as moment from "moment";
import { Card, Divider } from "@mui/material";
import axios from "axios";
import { AppContext } from "../../../App";
import { AccessTime, Square, StarBorder, StarRounded } from "@mui/icons-material";
import { Spinner } from "react-bootstrap";
import PessoasMessage from "../FooterMessages/Js/PessoasMessage";

export default function StakeholderPosition({ posicionamentos, nome }) {
  const { isMobile, currIdToken, expiresOn, renewSilentToken } =
    React.useContext(AppContext);
  return (
    <Grid>
      <Box mt={2}>
        {posicionamentos &&
        typeof posicionamentos === "object" &&
        posicionamentos.length ? (
          posicionamentos.map(
            ({
              aseEvento,
              titulo,
              tipoDiscurso,
              sumario,
              transcricao,
              dataHoraInicio,
            }) => (
              <Grid my={2} container columnSpacing={5}>
                <Grid item>
                  <b style={{ color: "var(--bayer-md-green)" }}>
                    {moment(dataHoraInicio).format("DD/MM/YYYY")}
                  </b>
                </Grid>
                <Grid
                  item
                  style={{ textAlign: "justify", maxWidth: !isMobile ? "90%" : "" }}
                >
                  <h5
                    style={{
                      color: "var(--bayer-md-green)",
                      fontWeight: "strong",
                      paddingBottom: "10px",
                    }}
                  >
                    {tipoDiscurso}
                  </h5>
                  {sumario !== null && sumario !== undefined && sumario !== "" ? (
                    <ReactReadMoreReadLess
                      charLimit={200}
                      readMoreText={"EXPANDIR ▼"}
                      readLessText={"MINIMIZER ▲"}
                      readMoreClassName="read-more-less--more"
                      readLessClassName="read-more-less--less"
                    >
                      {sumario}
                    </ReactReadMoreReadLess>
                  ) : (
                    ""
                  )}
                  <br />
                  <br />
                  {transcricao !== null &&
                  transcricao !== undefined &&
                  transcricao !== "" ? (
                    <ReactReadMoreReadLess
                      charLimit={200}
                      readMoreText={"EXPANDIR ▼"}
                      readLessText={"MINIMIZER ▲"}
                      readMoreClassName="read-more-less--more"
                      readLessClassName="read-more-less--less"
                    >
                      {transcricao}
                    </ReactReadMoreReadLess>
                  ) : (
                    ""
                  )}
                  <Divider />
                </Grid>
              </Grid>
            )
          )
        ) : (
          <Typography>Atualmente os dados não estão disponíveis!</Typography>
        )}
      </Box>
    </Grid>
  );
}