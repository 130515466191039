import React, { useState, useEffect, useRef, useContext } from "react";
import "../../card.css";
import { Grid, Paper, Button, Box } from "@mui/material";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../CSS/institutionCard.css";
import { AppContext } from "../../../App";
import EmptyProfile from "../../../image/EmptyProfile.png";
import axios from "axios";
import { withRouter } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import jsPDF from "jspdf";
import InstitutionProfilePdf from "./InstitutionProfilePdf";
import PDF from "./InstitutionPdf";
const doc = new jsPDF("p", "px", [1528, 1080]);

function InstituteProfileCard(props) {
  const { id } = props.match.params;
  const {
    isMobile,
    currIdToken,
    expiresOn,
    renewSilentToken,
    institutionData,
    institutionSocialMedia,
    institutionNoticias,
  } = React.useContext(AppContext);
  const mobileClass = isMobile ? "mobile" : "";
  const [imageErrorFlag, setImageErrorFlag] = React.useState(false);
  const handleImageError = () => setImageErrorFlag(true);

  //Generate PDF
  const [loadingPdf, setLoadingPdf] = React.useState(false);
  const handleDownload = () => {
    setLoadingPdf(true);
    const doc = new jsPDF("p", "px", [1528, 1080]);
    doc.html(
      ReactDOMServer.renderToString(
        <PDF
          institutionId={id}
          institutionInfo={institutionData}
          institutionSocial={institutionSocialMedia}
          institutionNoticias={institutionNoticias}
        />
      ),
      {
        autoPaging: "text",
        margin: [40, 20, 40, 10],
        callback: function (doc) {
          doc.save("InstitutionProfile.pdf");
          setLoadingPdf(false);
        },
      }
    );
  };

  return (
    <div>
      {institutionData
        .filter((data) => data.institution_id == id)
        .map((items) => {
          return (
            <Box
              className={`card-grid profile ${mobileClass}`.trim()}
              style={{ minHeight: "90px" }}
            >
              <div className={`card-img ${mobileClass}`.trim()}>
                {!imageErrorFlag && (
                  <img
                    src={items.institution_image}
                    alt="img"
                    onError={handleImageError}
                  />
                )}
                {imageErrorFlag && <img src={EmptyProfile} alt="img" />}
              </div>
              <div className="card-profile">
                <h5 title={items.institution_title}>{items.institution_title.charAt(0).toUpperCase() + items.institution_title.slice(1)}</h5>
              </div>
              <div className={`card-interaction ${mobileClass}`.trim()}>
                <div className="card-link-button" style={{ margin: "0 0 auto 0" }}>
                  <Button
                    variant="contained"
                    onClick={handleDownload}
                  >
                    {loadingPdf ? "Loading..." : "Gerar PDF"}
                  </Button>
                </div>
              </div>
            </Box>
          );
        })}
    </div>
  );
}
export default withRouter(InstituteProfileCard);
