import { useCallback } from 'react';
import { useState } from 'react';

export default function useForm(initialValues) {
  const [values, setValues] = useState(initialValues);

  return [
    values,
    useCallback(
      (e) => {
        setValues((values) => ({
          ...values,
          [e.target.name]: e.target.value
        }));
      },
      [setValues]
    )
  ];
}
