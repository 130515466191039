import React, { useState, useEffect } from "react";
import {
  Typography,
  InputAdornment,
  CircularProgress,
  Paper,
  CardHeader,
  Box,
  Divider,
  Link,
} from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { AppContext } from "../../App";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import BayerLogo from "../../image/Logo_BayerCross_Black.jpeg";
import "../CSS/style.css";
import { AccessTime, Square, StarBorder, StarRounded } from "@mui/icons-material";
import axios from "axios";
import { useInfinitePosts } from "../../hooks/useLazyLoad";
import moment from "moment";
import Modal from "@mui/material/Modal";
import FavouriteStar from "../../ReuseableComponents/FavouriteStar";
import ErrorBoundary from "../../ReuseableComponents/ErrorBoundary";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const gridMobileProps = (isMobile) => ({
  columns: isMobile ? 6 : 12,
  sx: {
    overflowY: "auto",
    maxHeight: isMobile ? "600px" : "450px",
    mt: isMobile ? 2 : 1,
    pr: 4,
    pb: 2,
    pl: 2,
  },
});

const Item = styled(Paper)(({ theme, isMobile }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export default function SocialMediaYoutube({ props }) {
  const [expanded, setExpanded] = React.useState(false);
  const {
    currIdToken,
    expiresOn,
    renewSilentToken,
    currUserId,
    getFavListAPI,
    favData,
    setFavData,
  } = React.useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [youtubeVideo, setYoutubeVideo] = useState([]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (!currIdToken && !props) return;
    getVideos();
  }, [currIdToken, props]);

  const addFavourites = async (
    e,
    youtubeId,
    youtubeTitle,
    youtubeDesc,
    youtubePublishedAt,
    youtubeChannelTitle,
    youtubeKind,
    youtubeUrl
  ) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    e.stopPropagation();
    const params = {
      kind: youtubeKind,
      videoId: youtubeId.toString(),
      publishedAt: youtubePublishedAt,
      title: youtubeTitle,
      description: youtubeDesc,
      channelTitle: youtubeChannelTitle,
      video_url: youtubeUrl,
      user_id: currUserId,
      type: "fav_socialmedia_youtube",
    };

    await axios
      .post(`${process.env.REACT_APP_API_KEY}/add-youtube-fav`, params, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        setFavData((f) =>
          f.concat({
            video_id: youtubeId,
            title: youtubeTitle,
            type: "fav_socialmedia_youtube",
          })
        );
        getFavListAPI();
      })
      .catch(renewSilentToken);
  };

  const removeFavourites = async (e, youtubeId) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    e.stopPropagation();
    const filteredArray = favData.filter(({ id, type, video_id }) => {
      return (
        type === "fav_socialmedia_youtube" && youtubeId.toString() === video_id.toString()
      );
    });
    const itemTobeRemoved =
      filteredArray &&
      Array.isArray(filteredArray) &&
      filteredArray.length &&
      filteredArray[0].id
        ? filteredArray[0].id.toString()
        : "";
    if (itemTobeRemoved) {
      const params = {
        user_id: currUserId,
        type: "fav_socialmedia_youtube",
        favourite_ids: [itemTobeRemoved],
      };

      await axios
        .post(`${process.env.REACT_APP_API_KEY}/favourites/remove-favourite`, params, {
          headers: { Authorization: "Bearer " + currIdToken },
        })
        .then((response) => {
          getFavListAPI();
          setFavData((f) => {
            return f.filter(({ id }) => id !== youtubeId);
          });
        })
        .catch(renewSilentToken);
    }
  };

  const { data: videos2 } = useInfinitePosts("youtube-videos/" + props, 10);
  const getVideos = async (e) => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await fetch(`${process.env.REACT_APP_API_KEY}/youtube-videos/` + props, {
      method: "POST",
      headers: { Authorization: "Bearer " + currIdToken },
    })
      .then((response) => {
        return response.json();
      })
      .then(setYoutubeVideo)
      .catch(renewSilentToken);
  };
  return (
    <div>
      <Item>
        <div className="CardHeader" style={{ backgroundColor: "var(--bayer-raspberry)" }}>
          YouTube
        </div>
        <ErrorBoundary>
          <Card variant="outlined" sx={{ overflowY: "auto", height: 345 }}>
            {youtubeVideo.length > 0 ? (
              youtubeVideo.map((item, index) => {
                return (
                  <Box p={2} key={`youtube-data-${index}`}>
                    <Link
                      href={item.video_url}
                      target="_blank"
                      style={{ textDecoration: "none", color: "#3c3c3c" }}
                    >
                      <CardHeader
                        title={item.title}
                        titleTypographyProps={{ variant: "h6" }}
                      />
                    </Link>
                    <Square
                      style={{ padding: "4px" }}
                      className="home-btn-details-square"
                    />
                    {item.channelTitle}
                    {<br />}
                    <AccessTime
                      style={{ padding: "4px", margin: "0" }}
                      className="home-btn-details-clock"
                    />
                    {moment(item.publishedAt).format("DD/MM/YYYY")}
                    <FavouriteStar
                      addFav={addFavourites}
                      removeFav={removeFavourites}
                      selectedId={item.videoId}
                      text={item.title}
                      author={item.description}
                      createdAt={item.publishedAt}
                      lang={item.channelTitle}
                      geo={item.kind}
                      twitterUrl={item.video_url}
                    />
                    <Link
                      href={item.video_url}
                      target="_blank"
                      style={{ textDecoration: "none", color: "#3c3c3c" }}
                    >
                      <CardContent>
                        <Typography variant="body2">{item.description}</Typography>
                      </CardContent>
                    </Link>
                    <Divider />
                  </Box>
                );
              })
            ) : (
              <div style={{ width: "100%", textAlign: "center" }}>
                <b>Resultados não disponiveis para busca realizada</b>
              </div>
            )}
          </Card>
        </ErrorBoundary>
      </Item>
    </div>
  );
}
