import { Box, Grid } from "@mui/material";
import moment from "moment";
import React from "react";
import PessoasMessage from "../FooterMessages/Js/PessoasMessage";

export default function StakeholderProfileAtuacaoLegislativa({ atuacaoLegislativa }) {
  if (!atuacaoLegislativa) return "Loading...";
  return (
    <>
      <div style={{ marginBottom: "40px" }}>
        <h5 style={{ marginBottom: "15px", fontWeight: "bold" }}>
          Histórico de atuação Legislativa
        </h5>
        <Box mt={2}>
          {atuacaoLegislativa.proposicoes.map(
            ({ dataApresentacao, dataHora, siglaTipo, ano, numero, ementa, codTipo }) => {
              return (
                <Grid my={2} container columnSpacing={5}>
                  <Grid item style={{ width: "100%" }}>
                    <b style={{ color: "var(--bayer-md-green)" }}>
                      {ano}
                      <br />
                    </b>
                    <b>
                      {numero} {" número"}
                    </b>
                    <br />
                    {ementa} -{" "}
                    <b>
                      <small>{siglaTipo}</small>
                    </b>
                  </Grid>
                </Grid>
              );
            }
          )}
        </Box>
      </div>
      <PessoasMessage />
    </>
  );
}
