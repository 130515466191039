export const v = (callback, ...args) => {
  if (typeof callback !== "function")
    throw new Error("The first v parameter should always be a function");
  return ({ target: t }) => callback(t.value, ...args);
};

export const t = (callback) => {
  if (typeof callback !== "function")
    throw new Error("The t parameter should always be a function");
  return () => callback((toggle) => !toggle);
};

export const st = (callback) => {
  if (typeof callback !== "function")
    throw new Error("The st parameter should always be a function");
  return () => callback(true);
};

export const getAuthHeader = (accessToken) => {
  var headers = new Headers();
  var bearer = "Bearer " + accessToken;
  headers.append("Authorization", bearer);
  return headers;
};

export const toBase64Browser = (arrayBuffer) =>
  btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
