import React, { useState, useEffect } from "react";
import "../../../card.css";
import { Grid, Paper, Button, Box, SpeedDialAction, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaLinkedinIn,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "../../CSS/institutionCard.css";
import { AppContext } from "../../../../App";
import EmptyProfile from "../../../../image/EmptyProfile.png";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { SocialIcon } from "react-social-icons";
import { Card, Divider } from "@mui/material";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Conexoes(props) {
  const { id } = props.match.params;
  const { isMobile, currIdToken, expiresOn, renewSilentToken, institutionSocialMedia } =
    React.useContext(AppContext);
  const mobileClass = isMobile ? "mobile" : "";
  const [imageErrorFlag, setImageErrorFlag] = React.useState(false);
  const handleImageError = () => setImageErrorFlag(true);

  return (
    <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
      <div style={{ flex: "1" }}>
        {institutionSocialMedia.length > 0 ? (
          institutionSocialMedia
            .filter((data) => data.institution_id == id)
            .map((items) => {
              return (
                <div>
                  {items.socialmedia_type.toLowerCase() == "youtube" ? (
                    <div style={{ margin: "20px", marginBottom: "0px" }}>
                      {items.socialmedia_url != "NA" &&
                      items.socialmedia_url != "No Data" ? (
                        <a
                          href={items.socialmedia_url}
                          target="_blank"
                          className="Social-Link"
                        >
                          <SocialIcon
                            network="youtube"
                            style={{ width: "30px", height: "30px", marginRight: "1%" }}
                          />
                          YouTube
                        </a>
                      ) : (
                        <div>
                          <SocialIcon
                            network="youtube"
                            style={{ width: "30px", height: "30px", marginRight: "1%" }}
                          />
                          Não disponível
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {items.socialmedia_type.toLowerCase() == "facebook" ? (
                    <div style={{ margin: "20px", marginBottom: "0px" }}>
                      {items.socialmedia_url != "NA" &&
                      items.socialmedia_url != "No Data" ? (
                        <a
                          href={items.socialmedia_url}
                          target="_blank"
                          className="Social-Link"
                        >
                          <SocialIcon
                            network="facebook"
                            style={{ width: "30px", height: "30px", marginRight: "1%" }}
                          />
                          Facebook
                        </a>
                      ) : (
                        <div>
                          <SocialIcon
                            network="facebook"
                            style={{ width: "30px", height: "30px", marginRight: "1%" }}
                          />
                          Não disponível
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {items.socialmedia_type.toLowerCase() == "instagram" ? (
                    <div style={{ margin: "20px", marginBottom: "0px" }}>
                      {items.socialmedia_url != "NA" &&
                      items.socialmedia_url != "No Data" ? (
                        <a
                          href={items.socialmedia_url}
                          target="_blank"
                          className="Social-Link"
                        >
                          <SocialIcon
                            network="instagram"
                            style={{ width: "30px", height: "30px", marginRight: "1%" }}
                          />
                          Instagram
                        </a>
                      ) : (
                        <div>
                          <SocialIcon
                            network="instagram"
                            style={{ width: "30px", height: "30px", marginRight: "1%" }}
                          />
                          Não disponível
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {items.socialmedia_type.toLowerCase() == "twitter" ? (
                    <div style={{ margin: "20px", marginBottom: "0px" }}>
                      {items.socialmedia_url != "NA" &&
                      items.socialmedia_url != "No Data" ? (
                        <a
                          href={items.socialmedia_url}
                          target="_blank"
                          className="Social-Link"
                        >
                          <SocialIcon
                            network="twitter"
                            style={{ width: "30px", height: "30px", marginRight: "1%" }}
                          />
                          Twitter
                        </a>
                      ) : (
                        <div>
                          <SocialIcon
                            network="twitter"
                            style={{ width: "30px", height: "30px", marginRight: "1%" }}
                          />
                          Não disponível
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {items.socialmedia_type.toLowerCase() == "linkedin" ? (
                    <div style={{ margin: "20px", marginBottom: "0px" }}>
                      {items.socialmedia_url != "NA" &&
                      items.socialmedia_url != "No Data" ? (
                        <a
                          href={items.socialmedia_url}
                          target="_blank"
                          className="Social-Link"
                        >
                          <SocialIcon
                            network="linkedin"
                            style={{ width: "30px", height: "30px", marginRight: "1%" }}
                          />
                          LinkedIn
                        </a>
                      ) : (
                        <div>
                          <SocialIcon
                            network="linkedin"
                            style={{ width: "30px", height: "30px", marginRight: "1%" }}
                          />
                          Não disponível
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })
        ) : (
          <b>Atualmente, os dados não estão disponíveis para o Conexões.</b>
        )}
      </div>
    </div>
  );
}

export default withRouter(Conexoes);
