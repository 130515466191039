import React from "react";
import Typography from "@mui/material/Typography";
import * as moment from "moment";
import { AccessTime, Close, Square, StarBorder, StarRounded } from "@mui/icons-material";
import { Grid } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  borderRadius: "10px",
};

export default function OrgosMembrosDetails({ item, close }) {

  return (
    <>
      <div>
        <div style={{ display: "flex", justifyContent: "flex-end", height: "20px" }}>
          <Close
            style={{
              fontSize: "17px",
              cursor: "pointer",
              position: "fixed",
              marginRight: "-27px",
              marginTop: "-20px",
            }}
            onClick={close}
          />
        </div>

        <div className="container">
          <Typography
            className="modal-title"
            variant="h5"
            style={{
              fontWeight: "bold",
              textAlign: "center",
              color: "var(--bayer-md-blue)",
            }}
          >
            Órgãos Membros
          </Typography>
        </div>
            {item.map((value) => {
              return (
                <div className="container">
                <div className="row">
                <div className="col-6">
                  <Typography
                    style={{ fontWeight: "bold", color: "var(--bayer-md-green)" }}
                  >
                    {value.nome} - {value.titulo}
                    <br />
                  </Typography>
                  <Typography>
                    <a
                      href={value.email}
                      target="_blank"
                      style={{ textDecoration: "none", cursor: "pointer" }}
                    >
                      {value.email}
                    </a>
                  </Typography>
                  <small>
                    {value.siglaPartido ? value.siglaPartido : "NA"} /{" "}
                    {value.siglaUf ? value.siglaUf : "NA"}
                  </small>
                  <br />
                  <br />
                  </div>
                  </div>
                </div>
              );
            })}
      </div>
    </>
  );
}
