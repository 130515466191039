import React from 'react'
import Information from '../JS/InstitutionTabs/InstitutionInformation'
import Conexoes from '../JS/InstitutionTabs/InstitutionConexoes'


export default function InstitutionProfilePdf({pdfData:{Information,Conexoes}}) {
  return (
    <div>
      <Information />
      <Conexoes />

    </div>
  )
}
 