import "./App.css";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Route, Switch, withRouter } from "react-router-dom";
import MasterPage from "./MasterPage/MasterPage";
import { useState, createContext } from "react";
import { LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDateFns";
import { useSaveHistory } from "./hooks/useSaveHistory";
import { useWindowWidth } from "./hooks/useWindowWidth";
import { generateRoutes } from "./Routes";
import RouteChangeTracker from "./RouteChangeTracker";
import FavouriteItemList from "./Favourite/JS/FavouriteItemList";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
  useMsalAuthentication,
} from "@azure/msal-react";
import { InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { getAuthHeader, toBase64Browser } from "./utils";
import { Box } from "@mui/material";
import axios from "axios";
import Home, { HomeContext } from "./Home/JS/Home";
import Footer from "./ReuseableComponents/Footer";
import set from "date-fns/fp/set/index.js";
import { ScrollToTop } from "./ReuseableComponents/ScrollToTop";
import moment from "moment";

// const TRACKING_ID = "GTM-WCMTSQ2"; // TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

export const AppContext = createContext({});

function App({ history: h }) {
  const { login, error, acquireToken, result } = useMsalAuthentication(
    InteractionType.Redirect,
    loginRequest
  );

  React.useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Popup, loginRequest);
    }
  }, [error, login]);
  const { instance, accounts, inProgress } = useMsal();
  const [graphInfo, setGraphInfo] = React.useState(null);
  const [profilePicture, setProfilePicture] = React.useState(null);
  const [currAccessToken, setCurrAccessToken] = React.useState(null);
  const [currIdToken, setCurrIdToken] = React.useState(null);
  const [expiresOn, setExpiresOn] = React.useState(null);
  const [currUserId, setCurrUserId] = React.useState(null);
  const [favData, setFavData] = React.useState([]);
  const isAuthenticated = useIsAuthenticated();
  const [institutionData, setInstitutionData] = useState([]);
  const [ministerData, setMinsiterData] = useState([]);
  const [outrosData, setOutrosData] = useState([]);
  const [userId, setUserId] = React.useState(null);
  const [event, setEvent] = React.useState([]);
  const [userEvent, setUserEvent] = React.useState([]);
  const [countNotification, setCountNotification] = React.useState(event.length);
  const [newData, setNewData] = React.useState([]);

  const parentsCheckedList = {};
  const [parentsCheckedDisplay, setParentsCheckedDisplay] = useState({});
  const [checked, setChecked] = useState({});
  const [parentsChecked, setParentsChecked] = useState({});
  const [subTopic, setSubTopic] = useState([]);
  const [data, setData] = useState([]);

  const [newsData, setNewsData] = React.useState([]);
  const [notifications, setNotifications] = useState([]);

  const [check, setCheck] = useState([]);
  const [keywordData, setKeywordData] = useState([]);

  const renewSilentToken = () => {
    instance
      .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
      .then(({ accessToken, idToken, expiresOn, graphInfo, account, ...rest }) => {
        setCurrIdToken(idToken);
        setCurrAccessToken(accessToken);
        setExpiresOn(expiresOn);
        setCurrUserId(accounts[0]?.idTokenClaims["https://bayer.com/cwid"]);
      });
  };
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (isAuthenticated) return renewSilentToken();
    instance.ssoSilent(loginRequest);
  }, [isAuthenticated]);

  React.useEffect(() => {
    if (!currAccessToken) return;
    var headers = getAuthHeader(currAccessToken);
    fetch("https://graph.microsoft.com/v1.0/me", {
      headers,
    })
      .then((resp) => resp.json())
      .then((r) => {
        setGraphInfo(r);
        ReactGA.set({ userId: r.id });
      });

    fetch("https://graph.microsoft.com/v1.0/me/photos/64x64/$value", {
      headers,
    })
      .then((resp) => resp.arrayBuffer())
      .then((r) => `data:image/jpeg;base64, ${toBase64Browser(r)}`)
      .then(setProfilePicture)
      .catch();
  }, [currAccessToken]);

  const [pageHeader, setPageHeader] = useState(null);
  const { isMobile } = useWindowWidth();
  useSaveHistory(h.location);

  // Default config options
  const defaultOptions = {
    baseURL: `${process.env.REACT_APP_API_KEY}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  //To get last 7th date from today
  const lastDate = new Date();
  const seventhDate = lastDate.setDate(lastDate.getDate() - 7);
  const seventhDateNew = moment(seventhDate).format("YYYY-MM-DD");

  React.useEffect(() => {
    handleInformation();
    handleMinister();
    handleOutros();
    handleSocialMedia();
    handleNews();
    stakeholderEvents();
    userEvents();
    NewsLetterAPI();
    getSelectedKeywords();
    getKeywords();
    if (!graphInfo) return;
    getFavListAPI();
    currUserIdDetails();
    getCount();
  }, [graphInfo]);

  React.useEffect(() => {
    if (!keywordData) return;
    getKeywords();
  }, [keywordData]);

  const getSelectedKeywords = async () => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    const params = {
      user_id: currUserId,
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/news/keywords/get-selected-keywords`,
        params,
        {
          headers: { Authorization: "Bearer " + currIdToken },
        }
      )
      .then((response) => {
        if (response.data != "") {
          setKeywordData(response.data);

          let parentKeywords = response.data.map((item, index) => item.topic);
          parentKeywords = [...new Set(parentKeywords)];
          setCheck(parentKeywords);
        }
      })
      .catch(renewSilentToken);
  };

  const [isBottom, setIsBottom] = useState(false);

  const addItems = () => {
    setNewsData();
    setIsBottom(false);
  };

  useEffect(() => {
    if (isBottom) {
      addItems();
    }
  }, [isBottom]);
  const currUserIdDetails = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/user/add-user`,
        {
          user_id: currUserId, //loggedInUser ID
        },
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then(({ data }) => {})
      .catch(renewSilentToken);
  };

  //To get today's date
  const current = new Date();
  const curDate = `${current.getFullYear()}-${current.getMonth() + 1}-${
    current.getDate() + 1
  }`;

  const dataLimit = 10;
  const NewsLetterAPI = async () => {
    let pageNo = Math.ceil(newsData.length / dataLimit);
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/news/get-all`,
        {
          start_date: seventhDateNew,
          end_date: moment(current).format("YYYY-MM-DD"),
        },
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then((response) => {
        if (Object.keys(response.data)[0] === "Error") {
          return renewSilentToken();
        }
        if (response.data !== "") {
          const apiRes = response.data;
          const mergeData = [...newsData, ...apiRes];
          setNewData(apiRes);
          setNewsData(mergeData);
        }
      })
      .catch(renewSilentToken);
  };

  const getFavListAPI = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/favourites/get-all`,
        {
          user_id: currUserId, //loggedInUser ID
        },
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then(({ data }) => {
        if (data?.length) {
          const formattedData = data.map(({ data: item }) => {
            return JSON.parse(item);
          });
          setFavData(formattedData);
        }
      })
      .catch(renewSilentToken);
  };

  const handleInformation = async (e) => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(
        `${process.env.REACT_APP_API_KEY}/institutions/get-all-institution-information`,
        {
          headers: { Authorization: "Bearer " + currIdToken },
        }
      )
      .then((response) => {
        setInstitutionData(response.data);
      })
      .catch((err) => {
        if (err.response.data.Error === "Signature has expired") {
          window.confirm("Sua sessão expirou. Por favor, faça login novamente")
            ? instance.logout()
            : alert("Por favor, faça logout e login novamente");
        }
      });
  };
  const handleMinister = async (e) => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/minister`, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        setMinsiterData(response.data);
      })
      .catch(renewSilentToken);
  };
  const handleOutros = async (e) => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/person/outros-get-all`, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        setOutrosData(response.data);
      })
      .catch(renewSilentToken);
  };

  /////////Handle institution Socialmedia
  const [institutionSocialMedia, setinstitutionSocialMedia] = useState([]);

  const handleSocialMedia = async () => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(
        `${process.env.REACT_APP_API_KEY}/institutions/get-all-institution-socialmedias`,
        {
          headers: { Authorization: "Bearer " + currIdToken },
        }
      )
      .then((response) => {
        setinstitutionSocialMedia(response.data);
      })
      .catch(renewSilentToken);
  };
  ////////Handle institution News
  const [institutionNoticias, setinstitutionNoticias] = useState([]);
  const handleNews = async () => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/institutions/get-all-institution-news`, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        setinstitutionNoticias(response.data);
      })
      .catch(renewSilentToken);
  };
  //Stakeholders Event API
  const stakeholderEvents = async (e) => {
    var firstDay = new Date();
    var formattedFirstDate = moment(firstDay).format("YYYY-MM-DD");
    var lastDay = new Date(new Date(firstDay.setDate(firstDay.getDate() + 10)));
    var formattedLastDate = moment(lastDay).format("YYYY-MM-DD");

    await axios
      .get(
        `https://dadosabertos.camara.leg.br/api/v2/eventos?dataInicio=${formattedFirstDate}&dataFim=${formattedLastDate}&itens=100&ordem=DESC&ordenarPor=dataHoraInicio`
      )
      .then((response) => {
        if (response) {
          setEvent(response.data.dados);
        }
      });
  };

  //User Event API
  const userEvents = async () => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(
        `${process.env.REACT_APP_API_KEY}/calender_event/get-all-event/${currUserId}`,

        {
          headers: { Authorization: "Bearer " + currIdToken },
        }
      )
      .then((response) => {
        setUserEvent(response.data);
      })
      .catch(renewSilentToken);
  };

  const getCount = async () => {
    const data = {
      user_id: currUserId,
      since: seventhDateNew,
    };
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/notifications/unread-notification-count`,
        data,
        {
          headers: {
            Authorization: "Bearer " + currIdToken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data) {
          setCountNotification(response.data.Unread_Notification);
        }
      })
      .catch((error) => {});
  };

  const getKeywords = async () => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/news/keywords/get-all`, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        if (response.data != "") {
          let allKeywords = {};
          response.data.map((item, index) => {
            if (!allKeywords[item.topic]) {
              allKeywords[item.topic] = {};
            }
            allKeywords[item.topic][item.sub_topic] = item;
          });
          setData(allKeywords);

          let existingSubTopics = [];
          keywordData.map((topic, index) => {
            parentsCheckedDisplay[topic.topic] = true;
            checked[topic.sub_topic] = true;
            existingSubTopics = existingSubTopics.concat(
              allKeywords[topic.topic][topic.sub_topic].mstr_keyword_id.toString()
            );
          });

          response.data.map((item, index) => {
            if (!parentsCheckedList[item.topic]) {
              parentsCheckedList[item.topic] = true;
            }
            parentsCheckedList[item.topic] =
              parentsCheckedList[item.topic] && !!checked[item.sub_topic];
            parentsChecked[item.topic] = parentsCheckedList[item.topic];
          });

          setSubTopic(existingSubTopics);
          setParentsCheckedDisplay(parentsCheckedDisplay);
          setChecked(checked);
          setParentsChecked({ ...parentsChecked });
        }
      })
      .catch(renewSilentToken);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <AppContext.Provider
          value={{
            pageHeader,
            setPageHeader,
            isMobile,
            graphInfo,
            account: accounts[0],
            profilePicture,
            currAccessToken,
            currIdToken,
            expiresOn,
            renewSilentToken,
            currUserId: accounts[0]?.idTokenClaims["https://bayer.com/cwid"],
            favData,
            setFavData,
            institutionData,
            ministerData,
            outrosData,
            institutionSocialMedia,
            institutionNoticias,
            event,
            userEvent,
            newsData,
            NewsLetterAPI,
            setUserEvent,
            getFavListAPI,
            setCountNotification,
            countNotification,
            checked,
            parentsChecked,
            subTopic,
            data,
            setSubTopic,
            setParentsChecked,
            setChecked,
            parentsCheckedDisplay,
            keywordData,
            check,
            setCheck,
            getSelectedKeywords,
            newData,
          }}
        >
          <RouteChangeTracker />
          <AuthenticatedTemplate>
            <MasterPage>
              <Switch>
                {generateRoutes()}
                <Route
                  path="/favourites/:mstr_info_type_name"
                  component={FavouriteItemList}
                ></Route>
              </Switch>
            </MasterPage>

            <Footer />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Box p={3}>
              <h3>Redirecionando ...</h3>
            </Box>
          </UnauthenticatedTemplate>
        </AppContext.Provider>
      </LocalizationProvider>
    </>
  );
}

export default withRouter(App);
