import React, { useState, useEffect } from "react";
import { Grid, Paper, Button, Box } from "@mui/material";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./card.css";
import "./ministerCard.css";
import { AppContext } from "../App";
import EmptyProfile from "../image/EmptyProfile.png";
import axios from "axios";
import Modal from "@mui/material/Modal";
import { CloseButton } from "react-bootstrap";
import OutrosMessage from ".//Pessoas/FooterMessages/Js/OutrosMessage";

function OutrosCard({ keyword, outrosSelectParams, RecentFunctionOutros }) {
  const { isMobile, currIdToken, expiresOn, renewSilentToken, outrosData } =
    React.useContext(AppContext);
  const mobileClass = isMobile ? "mobile" : "";
  const sizeStyle = isMobile
    ? { width: "70vw", height: "80vh" }
    : { width: 1000, height: 500 };
  const [imageErrorFlag, setImageErrorFlag] = React.useState(false);
  const [recentInstitution, setRecentInstitution] = React.useState({
    profiles: {},
    order: [],
  });
  const handleImageError = () => setImageErrorFlag(true);

  const handleRecent = (item) => {
    recentInstitution["profiles"][item.stakeholder_id] = item;
    recentInstitution["order"] = recentInstitution["order"].filter(
      (arrayItem) => arrayItem !== item.stakeholder_id
    );
    recentInstitution["order"].push(item.stakeholder_id);
    recentInstitution["order"] = recentInstitution["order"].slice(-5);
    localStorage.setItem("recent-outros", JSON.stringify(recentInstitution));
    setRecentInstitution(recentInstitution);
  };
  useEffect(() => {
    const recentInstitutionLocal =
      JSON.parse(localStorage.getItem("recent-outros")) || recentInstitution;
    setRecentInstitution(recentInstitutionLocal);
  }, []);
  const contactDetails = (text) => {
    let contact = text.split(",\n");
    return contact[0];
  };

  const contactDetailsAll = (text) => {
    let contact = text.split(",\n");
    return contact[1];
  };
  let filterData = [...outrosData];
  if (keyword !== "" && outrosData && outrosSelectParams == "Nome") {
    filterData = outrosData.filter((data) => {
      const lower_title = data.stakeholder_name.toLowerCase();
      const lower_searchKey = keyword.toLowerCase();
      var normal_lower_title = lower_title
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      var normal_lower_searchKey = lower_searchKey
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      return normal_lower_title.search(normal_lower_searchKey) != -1;
    });
  }
  if (keyword !== "" && outrosData && outrosSelectParams == "Estado") {
    filterData = outrosData.filter((data) => {
      var lower_title = data.stakeholder_location.toLowerCase();
      var lower_searchKey = keyword.toLowerCase();
      var normal_lower_title = lower_title
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      var normal_lower_searchKey = lower_searchKey
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      return normal_lower_title.search(normal_lower_searchKey) != -1;
    });
  }
  if (keyword !== "" && outrosData && outrosSelectParams == "Cargo") {
    filterData = outrosData.filter((data) => {
      const lower_title = data.stakeholder_designation.toLowerCase();
      const lower_searchKey = keyword.toLowerCase();
      var normal_lower_title = lower_title
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      var normal_lower_searchKey = lower_searchKey
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      return normal_lower_title.search(normal_lower_searchKey) != -1;
    });
  }

  ////for handling pdf////////
  const [pdfLink, setPdfLink] = useState([]);
  const handleLinkPdf = async (name) => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/person/outros-get-pdf/${name}`, {
        headers: { Authorization: "Bearer " + currIdToken },
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(blob);
        setPdfLink(fileURL);
        PdfOpenModal();
      })
      .catch(renewSilentToken);
  };
  ////for handling pdf for mobile////////
  const handleLinkPdfMobile = async (name) => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/person/outros-get-pdf/${name}`, {
        headers: { Authorization: "Bearer " + currIdToken },
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(blob);
        let newWindow = window.open(fileURL);
        setPdfLink(fileURL);
        RecentFunctionOutros();
      })
      .catch(renewSilentToken);
  };

  //for handling img/////////////////
  const [imgLink, setImgLink] = useState([]);
  const handleImgLink = async (id) => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .post(`${process.env.REACT_APP_API_KEY}/person/outros-get-img`, {
        headers: { Authorization: "Bearer " + currIdToken },
        responseType: "arraybuffer",
        params: { stakeholder_id: id },
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(blob);
        setImgLink(fileURL);
        return imgLink;
      })
      .catch(renewSilentToken);
  };

  const HandleRedirectPdf = () => {
    return window.open(pdfLink, "_blank");
  };

  ////modal
  const [showModal, setShowModal] = React.useState(false);
  const PdfOpenModal = () => {
    setShowModal(true);
  };

  const PdfCloseModal = () => {
    setShowModal(false);
    RecentFunctionOutros();
  };

  return (
    <>
      <>
        {filterData.map((item, index) => {
          return (
            <Grid item xs={6}>
              <Paper elevation={12} className={`minister-card-grid ${mobileClass}`.trim()}>
                <div className="minister-card-img">
                  {!imageErrorFlag && (
                    <img
                      src={item.stakeholder_img}
                      alt="img"
                      onError={handleImageError}
                    />
                  )}
                  {imageErrorFlag && <img src={EmptyProfile} alt="img" />}
                </div>
                <div className="minister-card-profile">
                  <h5 title={item.stakeholder_name}>{item.stakeholder_name}</h5>
                  <small>{item.stakeholder_designation}</small>
                  <small>{item.stakeholder_location}</small>
                </div>
                <div className={`minister-card-profile ${mobileClass}`.trim()}>
                  <div className="card-link-button mobileclass-outros">
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "var(--bayer-blue)" }}
                      onClick={() => {
                        handleLinkPdfMobile(item.stakeholder_name);
                        handleRecent(item);
                      }}
                    >
                      Ver Mais
                    </Button>
                  </div>
                  <div className="card-link-button browserclass-outros">
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "var(--bayer-blue)" }}
                      onClick={() => {
                        handleLinkPdf(item.stakeholder_name);
                        handleRecent(item);
                      }}
                    >
                      Ver Mais
                    </Button>
                  </div>
                </div>
              </Paper>
            </Grid>
          );
        })}
        <Modal open={showModal} onClose={PdfCloseModal}>
          <Box
            sx={{ ...styleModal, ...sizeStyle }}
            className={`card-grid-institution profile ${mobileClass}`.trim()}
          >
            <div
              style={{
                width: "100%",
                overflowY: "scroll",
                overflowX: "hidden",
                paddingRight: "16px",
              }}
            >
              <CloseButton
                onClick={PdfCloseModal}
                style={{ fontSize: "10px", margin: "8px", float: "right" }}
              />

              <iframe
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src={pdfLink}
              ></iframe>
            </div>
          </Box>
        </Modal>
      </>
      <OutrosMessage />
    </>
  );
}

export default OutrosCard;

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  pr: 2,
  borderRadius: "10px",
  display: "flex",
};
