import React, { useState, useEffect } from "react";
import {
  Typography,
  InputAdornment,
  CircularProgress,
  Paper,
  Box,
  Divider,
} from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { AppContext } from "../../App";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import BayerLogo from "../../image/Logo_BayerCross_Black.jpeg";
import "../CSS/style.css";
import {
  AccessTime,
  Square,
  StarBorder,
  StarRounded,
} from "@mui/icons-material";
import axios from "axios";
import { useInfiniteCamara, useInfinitePosts } from "../../hooks/useLazyLoad";
import FavouriteStar from "../../ReuseableComponents/FavouriteStar";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const FETCH_DELAY_MS = 200;

const InputProps = (loading) => ({
  startAdornment: (
    <InputAdornment position="start">
      {loading ? (
        <CircularProgress
          style={{ padding: 0, height: "16px", width: "16px", margin: 0 }}
        />
      ) : (
        <FaSearch />
      )}
    </InputAdornment>
  ),
});

const gridMobileProps = (isMobile) => ({
  columns: isMobile ? 6 : 12,
  sx: {
    overflowY: "auto",
    maxHeight: isMobile ? "600px" : "450px",
    mt: isMobile ? 2 : 1,
    pr: 4,
    pb: 2,
    pl: 2,
  },
});

const itemsPorPagina = 10;

const initialParams = {
  itens: itemsPorPagina,
  pagina: 1,
  ordem: "ASC",
  ordenarPor: "nome",
};
const cardWidth = 275;

const Item = styled(Paper)(({ theme }) => ({
  minWidth: window.innerWidth <= 768 ? 0 : cardWidth,
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export default function SocialMediaLikedin({ props }) {
  const [expanded, setExpanded] = React.useState(false);
  const { currIdToken, expiresOn, renewSilentToken, currUserId, setFavData } =
    React.useContext(AppContext);
  const [linkedinPost, setLinkedinPost] = useState([]);

  useEffect(() => {
    if (!currIdToken && !props) return;
    getLinkedinPost();
  }, [currIdToken, props]);

  const addFavourites = async (e) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    e.stopPropagation();
    const params = {
      user_id: currUserId, //loggedInUser ID
      type: "fav_news",
    };

    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/favourites/add-favourite`,
        params,
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then((response) => {
      })
      .catch(renewSilentToken);
  };

  const removeFavourites = async (e) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    e.stopPropagation();
    const params = {
      user_id: currUserId, //loggedInUser ID
      type: "fav_news",
    };

    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/favourites/remove-favourite`,
        params,
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then((response) => {
        setFavData((f) => {
        });
      })
      .catch(renewSilentToken);
  };

  const { data: LinkedinPost2 } = useInfinitePosts(
    "linkedin-profile-post/" + props,
    10
  );
  const getLinkedinPost = async (e) => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await fetch(
      `${process.env.REACT_APP_API_KEY}/linkedin-profile-post/` + props,
      {
        method: "GET",
        headers: { Authorization: "Bearer " + currIdToken },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then(setLinkedinPost)
      .catch(renewSilentToken);
  };
  return (
    <div>
      <Item>
        <div
          className="CardHeader"
          style={{ backgroundColor: "var(--bayer-dark-blue)" }}
        >
          LinkedIn
        </div>
        <Card variant="outlined" sx={{ overflowY: "auto", height: 345 }}>
          {linkedinPost.map((item, index) => {
            return (
              <Box p={2}>
                <CardMedia
                  component="img"
                  height="194"
                  image={BayerLogo}
                  alt="Paella dish"
                />
                <Square
                  style={{ padding: "4px" }}
                  className="home-btn-details-square"
                />
                {item.actor.image.attributes.miniProfile.firstName}
                <AccessTime
                  style={{ padding: "4px" }}
                  className="home-btn-details-clock"
                />{" "}
                <FavouriteStar
                  addFav={addFavourites}
                  removeFav={removeFavourites}
                  selectedId={item.news_id}
                  />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {item.commentary.text}
                  </Typography>
                </CardContent>
                <Divider />
              </Box>
            );
          })}
        </Card>
      </Item>
    </div>
  );
}
