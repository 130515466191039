import React, { useEffect, useState } from "react";
import { AppContext } from "../../../../App";
import axios from "axios";
import { Grid, Paper, Button, Box, SpeedDialAction, Typography } from "@mui/material";
import { Card, Divider } from "@mui/material";
import { withRouter } from "react-router-dom";
import * as moment from "moment";
import { AccessTime, Square, StarBorder, StarRounded } from "@mui/icons-material";
import { Spinner } from "react-bootstrap";
function InstitutionPosicionmentos(props) {
  const { id } = props.match.params;
  const { isMobile, currIdToken, expiresOn, renewSilentToken } =
    React.useContext(AppContext);

  useEffect(() => {
    // handleRecenTweets();
  }, []);
  const [loading, setLoading] = useState(false);
  const [tweets, settweets] = useState([]);
  const handleRecenTweets = async () => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(
        `${process.env.REACT_APP_API_KEY}/institutions/get-institution-tweets/${id} `,
        {
          headers: { Authorization: "Bearer " + currIdToken },
        }
      )
      .then((response) => {
        // if(response.data != ""){
        settweets(response.data);
        setLoading(true);
        // }
      })
      .catch(renewSilentToken);
  };
  return (
    <div>
      {" "}
      <div style={{ flex: "0.7" }}>
        <h5 style={{ marginBottom: "15px", fontWeight: "bold" }}>Feeds recentes</h5>
        {loading ? (
          <div>
            {tweets.length > 0
              ? tweets.map((item) => {
                  return (
                    <Card style={{ padding: "10px" }} className="mb-3">
                      <a
                        href={item.twitter_url}
                        style={{ textDecoration: "none", color: "black" }}
                        target="_blank"
                      >
                        <Typography>
                          <b style={{ fontSize: "16px" }}>
                            {" "}
                            {item.author ? item.author : item.username}
                          </b>{" "}
                          &nbsp;{" "}
                        </Typography>
                        <Typography>{item.twitter_text}</Typography>
                      </a>
                      <div className="modal-author-time">
                        <div className="author">
                          <Square
                            style={{ padding: "4px" }}
                            className="home-btn-details-square"
                          />{" "}
                          <a
                            href={`https://twitter.com/${item.username}`}
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            {item.username}{" "}
                          </a>
                        </div>
                        <div className="time" style={{ textTransform: "capitalize" }}>
                          <AccessTime
                            style={{ padding: "4px" }}
                            className="home-btn-details-clock"
                          />{" "}
                          {moment(item.created_at).format("DD/MM/YYYY")}
                        </div>
                      </div>
                    </Card>
                  );
                })
              : "Nenhum tweet disponível "}
          </div>
        ) : (
          <div>
         Funcionalidade temporariamente indisponível dadas às recentes
         mudanças e limitações de views e posts aplicadas pelo Twitter
          </div>
          // <div
          //   className="normalspinner"
          //   style={{
          //     width: "100%",
          //     zIndex: 9999,
          //     alignItems: "center",
          //     textAlign: "center",
          //     marginTop: "150px",
          //   }}
          // >
          //   <Spinner animation="border"></Spinner>
          // </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(InstitutionPosicionmentos);
