import React, { useEffect, useState } from "react";
import "../card.css";
import EmptyProfile from "../../image/EmptyProfile.png";
import { Button, Box } from "@mui/material";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import { AppContext } from "../../App";
import ReactDOMServer from "react-dom/server";
import jsPDF from "jspdf"; 
import StakeholderProfilePdf from "./StakeholderProfilePdf";
import axios from "axios";
import { withRouter } from "react-router-dom";


export default function StakeholderProfileCard({ stakeholder, pdfContent, deputado, ministro }) {
  if (deputado) return <StakeholderDeputadoProfileCard {...{ stakeholder, pdfContent }} />;
  return <></>; 
}

function StakeholderDeputadoProfileCard({ stakeholder, pdfContent }) {
  const { isMobile } = React.useContext(AppContext);
  const mobileClass = isMobile ? "mobile" : "";
  const [imageErrorFlag, setImageErrorFlag] = React.useState(false);
  const handleImageError = () => setImageErrorFlag(true);
  const doc = new jsPDF("p", "px", [1528, 1080]); 
  const [loadingPdf, setLoadingPdf] = React.useState(false);
  const imageContainerRef = React.useRef(null);
  const handleGerarPdf = () => {
    setLoadingPdf(true);
    doc.html(
      ReactDOMServer.renderToString(<StakeholderProfilePdf pdfData={{ ...pdfContent }} />),
      { autoPaging:"text",
        margin:[40,20,40,10],
        callback: () => {
          doc.save("StakeholderProfile.pdf");
          setLoadingPdf(false);
        },
      }
    );
  };
  const twitter = React.useMemo(
    () => stakeholder?.redeSocial?.filter((rs) => rs.includes("twitter")) || [],
    [stakeholder?.redeSocial]
  );
  const instagram = React.useMemo(
    () => stakeholder?.redeSocial?.filter((rs) => rs.includes("instagram")) || [],
    [stakeholder?.redeSocial]
  );
  const facebook = React.useMemo(
    () => stakeholder?.redeSocial?.filter((rs) => rs.includes("facebook")) || [],
    [stakeholder?.redeSocial]
  );
  const youtube = React.useMemo(
    () => stakeholder?.redeSocial?.filter((rs) => rs.includes("youtube")) || [],
    [stakeholder?.redeSocial]
  );

  const pageNotLoaded = Object.values(pdfContent).some((item) => !item);

  if (!stakeholder) return <Box height="150px"></Box>;

  return (
    <Box className={`card-grid profile ${mobileClass}`.trim()} style={{ minHeight: "90px" }}>
      <div className="card-img">
        {!imageErrorFlag && (
          <img
            ref={imageContainerRef}
            src={stakeholder.ultimoStatus.urlFoto}
            alt="img"
            onError={handleImageError}
          />
        )}
        {imageErrorFlag && <img src={EmptyProfile} alt="img" />}
      </div>
      <div className="card-profile">
        <h5>{stakeholder.ultimoStatus.nome}</h5>
        <small>{stakeholder.ultimoStatus.condicaoEleitoral}</small>
        <small>
          {stakeholder.ultimoStatus.siglaUf} / {stakeholder.ultimoStatus.siglaPartido}
        </small>
      </div>
      <div className={`card-interaction ${mobileClass}`.trim()}>
        <div className="card-link-button" style={{ margin: "0 0 auto 0" }}>
          <Button
            variant="contained"
            onClick={handleGerarPdf}
            disabled={pageNotLoaded}
            {...(pageNotLoaded && { title: "Aguarde o carregamento da página" })}
          >
            {loadingPdf ? "Loading..." : "Gerar PDF"}
          </Button>
        </div>
        <div className="card-social-media">
        {twitter[0] && (
            <a href={twitter[0]} target="_blank" rel="noopener noreferrer">
              <FaTwitter className={"icon"} />
            </a>
          )}
          {facebook[0] && (
            <a href={facebook[0]} target="_blank" rel="noopener noreferrer">
              <FaFacebookF className={"icon"} />
            </a>
          )}
          {instagram[0] && (
            <a
              href={`https://instagram.com/${instagram[0].substring(1)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className={"icon"} />
            </a>
          )}
          {youtube[0] && (
            <a href={youtube[0]} target="_blank" rel="noopener noreferrer">
              <FaYoutube className={"icon"} />
            </a>
          )}
        </div>
      </div>
    </Box>
  );
}