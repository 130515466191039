import { Close } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

export default function StakeholderDeceased({ close }) {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end", height: "20px" }}>
        <Close
          style={{
            fontSize: "17px",
            cursor: "pointer",
            position: "fixed",
            marginBottom: "20px",
          }}
          onClick={close}
        />
      </div>
      <Typography style={{ textAlign: "center" }}>
        <strong>
          O Stakeholder que você está procurando não está trabalhando agora, pois ele
          faleceu.
        </strong>
      </Typography>
      <br />
      <Stack direction="row" spacing={2}>
        <Button variant="contained" startIcon={<KeyboardBackspaceIcon />}>
          <Link
            to="/stakeholders-pessoas"
            style={{ textDecoration: "none", color: "white" }}
          >
            Voltar
          </Link>
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={close}
          style={{ marginLeft: "auto" }}
        >
          Continuar
        </Button>
      </Stack>
    </div>
  );
}
