export const initialState = {
  202201: {
    1: [
      {
        id: 1,
        time: '14:00',
        title: 'test',
        description: 'test',
        date: new Date('2022-01-1'),
        city: ''
      },
      {
        id: 2,
        time: '10:00',
        title: 'test',
        description: 'test',
        date: new Date('2022-01-1'),
        city: ''
      }
    ]
  },
  202112: {
    13: [
      {
        city: '',
        date: new Date('Mon Dec 13 2021'),
        description: 'teste',
        id: 'aa3ac683-9c45-404c-b592-3ba2467218ad',
        time: '00:00',
        title: 'teste'
      }
    ]
  }
};
