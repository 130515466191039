import React, { useEffect, useState } from "react";
import "../../../../ReuseableComponents/css/ScrollToTop.css";
import { KeyboardArrowUpTwoTone } from "@mui/icons-material";
import '../css/PessoasMessage.css';
import RestrictedImg from "../../../../image/Restricted_Img.png";

function PessoasMessage() {
  /////////////////scrollto top ///////////////////
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  return (
    <div
      style={{
        position: "fixed",
        bottom: "0px",
        display: "block",
        paddingTop: "8px",
        backgroundColor: "#fff",
        width: "100%",
        zIndex: 1,
      }}>
      <p style={{ marginLeft: "6%", width: "70%", textAlign: "center", marginBottom: "0px", fontSize: "11px" }}>Os dados contidos nesta página são extraídos conforme registro da conta do parlamentar no site institucional da Câmara   (<a
        style={{ cursor: "pointer", fontSize: "11px" }}
        href="https://www.camara.leg.br/"
        target="_blank">
        https://www.camara.leg.br/</a>)
         

      </p>
      <div style={{ marginLeft: "50px" }}>
        <a title="Scroll to top" className="scroll-to-top" onClick={scrollToTop}>
          <KeyboardArrowUpTwoTone
            aria-hidden="true"
            style={{
              width: "20px",
              height: "20px",
              display: "block",
              marginLeft: "36%",
              marginRight: "58%",
              cursor: "pointer",
              border: "1px solid #fff",
              backgroundColor: "var(--bayer-dark-green)",
              color: "#fff",
              borderRadius: "10px",
              zIndex: 1,
            }}
            value="Scroll to top"
          />
        </a>
        <img
          src={RestrictedImg}
          className="restricted-img-pessoas"
          style={{
            width: "80px",
            height: "30px",
            float: "right",
            marginTop: "-30px",
            marginRight: "134px",
          }}
        ></img>
      </div>
    </div>
  );
}

export default PessoasMessage;
