import React from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  CircleMarker,
  Tooltip,
} from "react-leaflet";
import { useState } from "react";
import "./StakeholdersProfileMapadeInfluencia.css";
import axios from "axios";
import MapaModal from "../../../Mapa/JS/MapaModal";
import { AppContext } from "../../../App";
import { Box, Modal } from "@mui/material";
import PessoasMessage from "../FooterMessages/Js/PessoasMessage";
import "../../../Mapa/CSS/Mapa.css";
import InfoIcon from "@mui/icons-material/Info";
import TooltipIcon from "@mui/material/Tooltip";

export default function StakeholdersProfileMapadeInfluência({
  stakeholderConnection,
}) {
  const [selected, setSelected] = useState({});
  const [showModal, setShowModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState();
  const { isMobile, currIdToken, expiresOn, renewSilentToken } =
    React.useContext(AppContext);

  const defaultCenter = {
    lat: -15.793889,
    lng: -47.882778,
  };

  const sizeStyle = isMobile
    ? { width: "70vw", height: "80vh" }
    : { width: 1000, height: 500 };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const getFrentesConnecDetails = async (id_x, id_y, nome_x, nome_y) => {
    openModal();
    setModalContent("Loading...");
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/moi/get-frentes-details`,
        {
          stakeholder_id_x: id_x.toString(),
          stakeholder_id_y: id_y.toString(),
        },
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then((response) => {
        if (response && response.data && Array.isArray(response.data)) {
          setModalContent(
            <MapaModal
              isMobile={isMobile}
              data={response.data}
              stakeholderNameX={nome_x}
              stakeholderNameY={nome_y}
              topicName={"Frentes Parlamentares que"}
              close={closeModal}
            />
          );
        }
      })
      .catch(renewSilentToken);
  };

  const getOrgaosConnecDetails = async (id_x, id_y, nome_x, nome_y) => {
    openModal();
    setModalContent("Loading...");
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/moi/get-orgao-details`,
        {
          stakeholder_id_x: id_x.toString(),
          stakeholder_id_y: id_y.toString(),
        },
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then((response) => {
        if (response && response.data && Array.isArray(response.data)) {
          setModalContent(
            <MapaModal
              isMobile={isMobile}
              data={response.data}
              stakeholderNameX={nome_x}
              stakeholderNameY={nome_y}
              topicName={"Órgãos que"}
              close={closeModal}
            />
          );
        }
      })
      .catch(renewSilentToken);
  };

  const getLegislativeConnecDetails = async (id_x, id_y, nome_x, nome_y) => {
    openModal();
    setModalContent("Loading...");
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/moi/get-legistative-details`,
        {
          stakeholder_id_x: id_x.toString(),
          stakeholder_id_y: id_y.toString(),
        },
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then((response) => {
        if (response && response.data && Array.isArray(response.data)) {
          setModalContent(
            <MapaModal
              isMobile={isMobile}
              data={response.data}
              stakeholderNameX={nome_x}
              stakeholderNameY={nome_y}
              topicName={"Atuações Legislativas que"}
              close={closeModal}
            />
          );
        }
      })
      .catch(renewSilentToken);
  };

  return (
    <>
    <p>Para ver as conexões entre os stakeholders, clique nos ícones azuis dispostos no mapa.</p>
      <div style={{ marginBottom: "40px" }}>
        <MapContainer
          className="map-stakeholder"
          center={defaultCenter}
          zoom={3.5}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {stakeholderConnection.map((item) => {
            return (
              <>
                <CircleMarker
                  radius={7}
                  key={item.nome_x}
                  center={item.location_x} //for circle marker
                  onMouseOver={(e) => {
                    e.target.openPopup();
                  }}
                  onMouseOut={(e) => {
                    e.target.closePopup();
                  }}
                  fillColor="white"
                >
                  <Popup
                    position={item.location_x}
                    clickable={true}
                    onCloseClick={() => setSelected({})}
                  >
                    <p style={{ fontSize: "12px" }}>Nome: {item.nome_x}</p>
                    {item.location_connection !== 1 &&
                    item.political_party_connection !== 1 &&
                    item.frentes_connection_count !== 1 &&
                    item.orgaos_connection_count !== 1 &&
                    item.legislativa_connection_count !== 1 ? (
                      <span>
                        A parte interessada selecionada não tem nenhuma conexão
                      </span>
                    ) : (
                      <>
                        <p style={{ fontSize: "12px" }}>
                          Estado: {item.state_x}
                        </p>
                        <p style={{ fontSize: "12px" }}>
                          Sigla Partido: {item.siglaPartido_x}
                        </p>
                      </>
                    )}
                  </Popup>
                  <Tooltip
                    direction="bottom"
                    offset={[0, 20]}
                    opacity={1}
                    permanent
                  >
                    {item.nome_x}
                  </Tooltip>
                </CircleMarker>
                {item.location_connection === 1 ||
                item.political_party_connection === 1 ||
                item.frentes_connection_count === 1 ||
                item.orgaos_connection_count === 1 ||
                item.legislativa_connection_count === 1 ? (
                  <CircleMarker
                    radius={4}
                    key={item.nome_y}
                    center={item.location_y} //for circle marker
                    onMouseOver={(e) => {
                      e.target.openPopup();
                    }}
                    onMouseOut={(e) => {
                      e.target.closePopup();
                    }}
                   
                  >
                    <Popup
                      position={item.location_y}
                      clickable={true}
                      onCloseClick={() => setSelected({})}
                    >
                      <p style={{ fontSize: "12px" }}>
                        {item.nome_x} tem uma conexão com {item.nome_y} com os
                        seguintes detalhes:
                      </p>
                      {item.state_x === item.state_y ? (
                        <p style={{ fontSize: "12px" }}>
                          Ambos possuem mandato pelo estado de {item.state_y}
                        </p>
                      ) : (
                        <></>
                      )}
                      {item.siglaPartido_x === item.siglaPartido_y ? (
                        <p style={{ fontSize: "12px" }}>
                          Ambos participam do mesmo Partido Político:{" "}
                          {item.siglaPartido_y}
                        </p>
                      ) : (
                        <></>
                      )}

                      {item.frentes_connection_count === 1 ? (
                        <p style={{ fontSize: "12px" }}>
                          Ambos trabalharam na mesma Organização de Frente
                          Parlamentar tendo um total de {""}
                          {item.frentes_connection_count_total} conexões
                          <a
                            href="#"
                            onClick={() =>
                              getFrentesConnecDetails(
                                item.id_x,
                                item.id_y,
                                item.nome_x,
                                item.nome_y
                              )
                            }
                            style={{ fontSize: "10px", textDecoration: "none" }}
                          >
                            {""} Ver Mais
                          </a>
                        </p>
                      ) : (
                        <></>
                      )}
                      {item.orgaos_connection_count === 1 ? (
                        <p style={{ fontSize: "12px" }}>
                          Ambos já trabalharam no mesmo Órgão Parlamentar tendo
                          um total de {""}
                          {item.orgaos_connection_count_total} conexões
                          <a
                            href="#"
                            onClick={() =>
                              getOrgaosConnecDetails(
                                item.id_x,
                                item.id_y,
                                item.nome_x,
                                item.nome_y
                              )
                            }
                            style={{ fontSize: "10px", textDecoration: "none" }}
                          >
                            {""} Ver Mais
                          </a>
                        </p>
                      ) : (
                        <></>
                      )}
                      {item.legislativa_connection_count === 1 ? (
                        <p style={{ fontSize: "12px" }}>
                          Ambos já trabalharam na mesma Frente Legislativa tendo
                          um total de {""}
                          {item.legislativa_connection_count_total} conexões
                          <a
                            href="#"
                            onClick={() =>
                              getLegislativeConnecDetails(
                                item.id_x,
                                item.id_y,
                                item.nome_x,
                                item.nome_y
                              )
                            }
                            style={{ fontSize: "10px", textDecoration: "none" }}
                          >
                            {""} Ver Mais
                          </a>
                        </p>
                      ) : (
                        <></>
                      )}
                    </Popup>
                    <Tooltip
                      direction="bottom"
                      offset={[0, 20]}
                      opacity={1}
                      permanent
                    >
                      {item.nome_y}
                    </Tooltip>
                  </CircleMarker>
                ) : (
                  <></>
                )}
              </>
            );
          })}
        </MapContainer>
        <Modal open={showModal} onClose={closeModal}>
          <Box sx={{ ...styleModal, ...sizeStyle }}>
            <div
              style={{
                width: "100%",
                overflowY: "scroll",
                overflowX: "hidden",
                paddingRight: "16px",
              }}
            >
              {modalContent}
            </div>
          </Box>
        </Modal>
        <PessoasMessage />
      </div>
    </>
  );
}

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  pr: 2,
  borderRadius: "10px",
  display: "flex",
};
