import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Avatar, Card, Divider } from "@mui/material";
import {
  AccessTime,
  Close,
  Square,
  StarBorder,
  StarRounded,
} from "@mui/icons-material";
import "../CSS/NoticiasPost.css";
import NewsLetterDetails from "./NewsLetterDetails";
import * as moment from "moment";
import JotaLogo from "../../image/Logo_Jota.png";
import PatriLogo from "../../image/Logo_Patri.png";
import axios from "axios";
import { AppContext } from "../../App";
import FavouriteStar from "../../ReuseableComponents/FavouriteStar";

export default function NoticiasPost({ item }) {
  const [showModal, setShowModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  const {
    isMobile,
    currIdToken,
    currUserId,
    expiresOn,
    renewSilentToken,
    favData,
    setFavData,
  } = React.useContext(AppContext);

  const sizeStyle = isMobile
    ? { width: "90vw", height: "80vh" }
    : { width: 1000, height: 500 };

  useEffect(() => {
    if (!currIdToken) return;
  }, [currIdToken]);

  const openModal = (item) => {
    // setModalContent(<NewsLetterDetails item={item} close={closeModal} />);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const addFavourites = async (e) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    e.stopPropagation();
    const params = {
      user_id: currUserId, //loggedInUser ID
      type: "fav_news",
      favourite_ids: [item.news_id.toString()],
    };

    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/favourites/add-favourite`,
        params,
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then((response) => {
        setFavData((f) => f.concat({ id: item.news_id }));
      })
      .catch(renewSilentToken);
  };

  const removeFavourites = async (e) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    e.stopPropagation();
    const params = {
      user_id: currUserId, //loggedInUser ID
      type: "fav_news",
      favourite_ids: [item.news_id.toString()],
    };

    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/favourites/remove-favourite`,
        params,
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then((response) => {
        setFavData((f) => {
          return f.filter(({ id }) => id !== item.news_id);
        });
      })
      .catch(renewSilentToken);
  };

  const displayText = (text) => {
    var remove = /<([a-z][a-z0-9]*)(?:[^>]*?((?:\s(?:class)=['\"][^'\"]*['\"]){0,3}))[^>]‌*?(\/?)>/
    text = text.replace(/\\/g, "");
    text = text.replace(
      /\s*style=(["'])(.*?)\1/gm,
      'style="text-align:justify;"'
    );
    var remove = [
      "border",
      "cellspacing",
      "cellpadding",
      "height",
      "width",
      "valign",
      "align",
      "Helvetica",
      "sans-serif",
    ];
    const removeAttr = (text, attr) => {
      return text.replace(
        new RegExp("(<[^>]+)\\s" + attr + "\\s*=['\"].*?['\"]", "gi"),
        "$1"
      );
    };

    remove.forEach(function (attr) {
      text = removeAttr(text, attr);
    });
  };

  const time = (text) => {
    let timeAgo = text.split("~");
    return timeAgo[0];
  };

  return (
    <>
      <div
        style={{ paddingRight: "12px" }}
      >
        <>

          <div style={{cursor:"pointer"}}

            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
            onClick={() => {
              setModalContent(
                <NewsLetterDetails
                  isMobile={isMobile}
                  item={item}
                  close={closeModal}
                />
              );
              openModal();
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
              }}
            >
              <div
                className="noticias-image"
                style={{
                  height: "65px",
                  width: "8%",
                }}
              >
                <Card
                  style={{
                    backgroundImage:
                      item.datasource_name === "JOTA"
                        ? `url('${JotaLogo}')`
                        : `url('${PatriLogo}')`,

                    width: "100%",
                    height: "100%",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    overflowY: "hidden",
                    cursor: "pointer",
                  }}
                ></Card>
              </div>
              <div style={{ marginLeft: "10px", width: "82%" }}>
                <Typography
                  className="modal-title"
                  variant="body2"
                  style={{ fontWeight: "bold" }}
                >
                  {item.news_title}{" "}
                </Typography>
                <Typography
                  className="modal-description"
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: displayText(item.news_text),
                  }}
                ></Typography>
                <div className="modal-author-time">
                  <div className="author">
                    <Square
                      style={{ padding: "4px" }}
                      className="home-btn-details-square"
                    />{" "}
                    {item.datasource_name}{" "}
                  </div>
                  <div className="time">
                    <AccessTime
                      style={{ padding: "4px" }}
                      className="home-btn-details-clock"
                    />{" "}
                    {moment(item.news_date).format("DD/MM/YYYY")}
                    <div className="access-time" style={{ marginLeft: "83px" }}>
                      {time(item.time_ago)} atrás
                    </div>
                  </div>
                </div>
              </div>
              <div className="favorites" style={{ width: "10%" }}>
                <FavouriteStar
                  addFav={addFavourites}
                  removeFav={removeFavourites}
                  selectedId={item.news_id}
                />
              </div>
            </div>
          </div>
          <Divider className="border" />
        </>

        <Modal open={showModal} onClose={closeModal}>
        <Box sx={{ ...styleModal, ...sizeStyle }}>
          <div
            style={{
              width: "100%",
              overflowY: "scroll",
              overflowX: "hidden",
              paddingRight: "16px",
            }}
          >
            {modalContent}
          </div>
        </Box>
      </Modal>
      </div>
    </>
  );
}

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  pr: 2,
  borderRadius: "10px",
  display: "flex",
};
