import { useReducer } from "react";
import ReminderForm from "../Reminder/Form";

const TYPES = {
  ACTIONS: {
    CLOSE_MODAL: "CLOSE_MODAL",
    OPEN_NEW_REMINDER_FORM: "OPEN_NEW_REMINDER_FORM",
    OPEN_EDIT_REMINDER_FORM: "OPEN_EDIT_REMINDER_FORM",
  },
};

const initialState = { isOpen: false };

const convertTZ = (date, tzString) => {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.ACTIONS.OPEN_NEW_REMINDER_FORM:
      const newFormDate = action.payload.date;
      const newFormOnSubmit = action.payload.onSubmit;
      return {
        ...state,
        isOpen: true,
        content: <ReminderForm date={newFormDate} onSubmit={newFormOnSubmit} />,
      };
    case TYPES.ACTIONS.OPEN_EDIT_REMINDER_FORM:
      const editFormDate = convertTZ(action.payload.date, "Asia/Jakarta");
      const editFormOnSubmit = action.payload.onSubmit;
      const editFormId = action.payload.id;
      return {
        ...state,
        isOpen: true,
        content: (
          <ReminderForm date={editFormDate} onSubmit={editFormOnSubmit} id={editFormId} />
        ),
      };
    case TYPES.ACTIONS.CLOSE_MODAL:
      return {
        ...state,
        isOpen: false,
        content: null,
      };
    default:
      return state;
  }
};

export const useModalReducer = (state = initialState) => {
  return useReducer(reducer, state);
};
