import { Grid, Box } from "@mui/material";
import React from "react";
import { StakeholderProfileContext } from "../StakeHolderProfile";
import StakeholderProfileFrentes from "./Frentes/StakeholderProfileFrentes";
import StakeholderProfileOcupacoes from "./Ocupacoes/StakeholderProfileOcupacoes";
import StakeholderProfileOrgaos from "./Orgaos/StakeholderProfileOrgaos";
import StakeholderProfileProfissoes from "./Profissoes/StakeholderProfileProfissoes";
import PessoasMessage from "../FooterMessages/Js/PessoasMessage";



export default function StakeholderProfileInformacoes({ informacoes }) {
  const { referencias } = React.useContext(StakeholderProfileContext);
  if (!informacoes || !referencias) return "Loading...";
  return (
    <>
    <Box mt={2} style={{marginBottom:"40px"}}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

        <Grid item xs={6}>
          <StakeholderProfileProfissoes profissoes={informacoes.profissoes} />
        </Grid>
        <Grid item xs={6}>
           <StakeholderProfileOcupacoes ocupacoes={informacoes.ocupacoes} /> 
        </Grid>
        <Grid item xs={6}>
           <StakeholderProfileOrgaos orgaos={informacoes.orgaos} /> 
        </Grid>
        <Grid item xs={6}>
           <StakeholderProfileFrentes frentes={informacoes.frentes} /> 
        </Grid>
      </Grid>
    </Box>
    <PessoasMessage/>
    </>
  );
}
