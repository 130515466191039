import React, { useState, useEffect } from "react";
import * as Scroll from "react-scroll";
import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import { Grid, Paper, Button, Box, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import "../../CSS/institutionCard.css";
import { AppContext } from "../../../../App";
import EmptyProfile from "../../../../image/EmptyProfile.png";
import axios from "axios";
import { withRouter } from "react-router-dom";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { HomeContext } from "../../../../Home/JS/Home";
import InstitutionNoticiasDetails from "./InstitutionNoticiasDetails";
import InstitutionProfileNewsDetails from "./InstitutionProfileNewsDetails";
import { Avatar, Card, Divider } from "@mui/material";
import { AccessTime, Square, StarBorder, StarRounded } from "@mui/icons-material";
import { Spinner } from "react-bootstrap";
import StakeholderProfileNewsDetails from "../../../Pessoas/Noticias/StakeholderProfileNewsDetails";

function GoogleNoticias(props) {
  let scroll = Scroll.animateScroll;
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  const { id } = props.match.params;
  const {
    isMobile,
    currIdToken,
    expiresOn,
    renewSilentToken,
    institutionNoticias,
    institutionData,
  } = React.useContext(AppContext);
  const mobileClass = isMobile ? "mobile" : "";
  const [imageErrorFlag, setImageErrorFlag] = React.useState(false);
  const handleImageError = () => setImageErrorFlag(true);
  const [loading, setLoading] = useState(false);
  //Read More toggle
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const sizeStyle = isMobile
    ? { width: "70vw", height: "80vh" }
    : { width: 1000, height: 500 };
  const informationPara = (text) => {
    let paragraph = text.split(".");
    return paragraph;
  };

  const [institutionNews, setInstitutionNews] = useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  const [googleNews, setGoogleNews] = useState([]);
  const [newsFlag, setNewsFlag] = useState(true);

  const [title, setTitle] = useState("");
  const handleTitle = () => {
    return (
      <div>
        {institutionData
          .filter((data) => data.institution_id == id)
          .map((items) => {
            setTitle(items.institution_title);
          })}
      </div>
    );
  };
  const noticias = title;

  useEffect(() => {
    if (!currIdToken && !noticias) return;
    handleTitle();
    getNews();
  }, [currIdToken, noticias]);

  const getNews = async (e) => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/news/get-google-news/` + noticias, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        if (response.data !== "") {
          setInstitutionNews(response.data);
          setLoading(true);
        }
      })
      .catch(renewSilentToken);
  };

  const openModal = (item) => {
    setModalContent(<StakeholderProfileNewsDetails item={item} close={closeModal} />);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {loading ? (
        <div>
          {institutionNews.length > 0
            ? institutionNews.map((item, index) => {
              return (
                <>
                  <div
                    style={{
                      paddingRight: "12px",
                    }}
                    onClick={() => openModal(item)}
                  >
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <div style={{ marginLeft: "10px", width: "100%" }}>
                          <Typography
                            className="modal-title"
                            style={{ fontWeight: "bold" }}
                          >
                            {item.title}{" "}
                          </Typography>
                          <Typography
                            className="modal-description"
                            dangerouslySetInnerHTML={{
                              __html: item.desc,
                            }}
                          ></Typography>
                          <div className="modal-author-time">
                            <div className="author">
                              <Square
                                style={{ padding: "4px" }}
                                className="home-btn-details-square"
                              />{" "}
                              {item.media}{" "}
                            </div>
                            <div
                              className="time"
                              style={{ textTransform: "capitalize" }}
                            >
                              <AccessTime
                                style={{ padding: "4px" }}
                                className="home-btn-details-clock"
                              />{" "}
                              {item.date}
                            </div>
                          </div>
                        </div>
                        <Divider className="border" />
                      </div>
                      <Divider />
                    </>
                  </div>
                </>
              );
            })
            : "Não foi possível buscar notícias no momento. Tente novamente depois de algum tempo."}
          <Modal open={showModal} onClose={closeModal}>
            <Box sx={{ ...styleModal, ...sizeStyle }}>
              <div
                style={{
                  width: "100%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  paddingRight: "16px",
                }}
              >
                {modalContent}
              </div>
            </Box>
          </Modal>
        </div>
      ) : (
        <div
          className="normalspinner"
          style={{
            width: "100%",
            zIndex: 9999,
            alignItems: "center",
            textAlign: "center",
            marginTop: "150px",
          }}
        >
          <Spinner animation="border"></Spinner>
        </div>
      )}
    </>
  );
}
const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  pr: 2,
  borderRadius: "10px",
  display: "flex",
};

export default withRouter(GoogleNoticias);
