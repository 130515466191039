import axios from "axios";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { AppContext } from "../../App";
import { areDatesEqual, getRemindersByDate } from "../../utils_calendar";
import { CalendarContext } from "../Board/context";
import Reminder from "../Reminder";
import * as moment from "moment";
import StakeholderEventDetails from "./StakeholderEventDetails";
import { HomeContext } from "../../Home/JS/Home";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";

export default function Card({ date, enabled, ...props }) {
  //get content by date using context method
  const { calendarDb, activeDate, setActiveDate, openNewReminderForm, closeModal } =
    useContext(CalendarContext);
  const [modalContent, setModalContent] = useState();
  const [showModal, setShowModal] = useState(false);
  const StakeholderOpenModal = () => {
    setShowModal(true);
  };

  const StakeholderCloseModal = () => {
    setShowModal(false);
  };

  const reminders = getRemindersByDate(calendarDb, date);

  const handleClickCard = ({ detail }) => {
    detail === 1 && enabled && setActiveDate(date);
    detail === 2 && enabled && openNewReminderForm(date, closeModal);
  };

  const enabledClass = enabled ? "enabled" : "";
  const activeClass = areDatesEqual(activeDate, date) ? "active" : "";
  const {
    isMobile,
    currIdToken,
    expiresOn,
    renewSilentToken,
    currUserId,
    event,
    userEvent,
  } = React.useContext(AppContext);
  const mobileClass = isMobile ? "mobile" : "";
  const sizeStyle = isMobile
    ? { width: "90vw", height: "80vh" }
    : { width: 1000, height: 500 };
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    pr: 2,
    borderRadius: "10px",
    display: "flex",
  };

  const displayTime = (text) => {
    let finalTime = text.split("T");
    return finalTime[1];
  };

  return (
    <div
      {...props}
      className={`calendar-card ${enabledClass} ${activeClass}`.trim()}
      onClick={handleClickCard}
    >
      <div className="header">{date.getDate()}</div>
      <div className="content">
        {userEvent?.map((calendarItem, i) =>
          moment(calendarItem.calendar_user_event_date).format("DD/MM/YYY") ===
          moment(date).format("DD/MM/YYY") ? (
            <Reminder key={i} calendarItem={calendarItem} tag />
          ) : (
            ""
          )
        )}
      </div>
      <div className="content">
        {event.map((item, i) => {
          return moment(item.dataHoraInicio).format("DD/MM/YYY") ===
            moment(date).format("DD/MM/YYY") ? (
            <div
              className="reminder"
              onClick={() => {
                setModalContent(
                  <StakeholderEventDetails
                    isMobile={isMobile}
                    item={item}
                    close={StakeholderCloseModal}
                  />
                );
                StakeholderOpenModal();
              }}
            >
              <b className="reminder-time">{displayTime(item.dataHoraInicio)}</b>
              <div className="title">{item.descricaoTipo}</div>
            </div>
          ) : (
            ""
          );
        })}
        <Modal open={showModal} onClose={StakeholderCloseModal}>
          <Box
            sx={{ ...styleModal, ...sizeStyle }}
            className={`card-grid-institution profile ${mobileClass}`.trim()}
          >
            <div
              style={{
                width: "100%",
                overflowY: "scroll",
                overflowX: "hidden",
                paddingRight: "16px",
              }}
            >
              {modalContent}
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
