import {
  Card,
  CircularProgress,
  Divider
} from "@mui/material";
import React from "react";
import Paper from "@mui/material/Paper";
import HomePostDetails from "./HomePostDetails";
import { AppContext } from "../../App";
import "../CSS/style.css";
import moment from "moment";
import { HomeContext } from "./Home";
import { AccessTime, Square } from "@mui/icons-material";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import { styled } from "@mui/material/styles";

export default function HomeNewsList({ close }) {
  const { openModal, setModalContent } = React.useContext(HomeContext);

  const {
    isMobile,
    newsData,
  } = React.useContext(AppContext);

  const time = (text) => {
    let timeAgo = text.split("~");
    return timeAgo[0];
  };

  const Item = styled(Paper)(({ theme, isMobile }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  return (
    <div>
      <Item>
        <div
          className="CardHeader"
          style={{ background: "var(--bayer-md-green)", color: "#fff" }}
        >
          <strong>Últimas Notícias</strong>{" "}
          <ManageSearchOutlinedIcon style={{ marginBottom: "2px" }} />
        </div>
        <Card variant="outlined" sx={{ overflowY: "auto", height: 243 }} style={{border: "1px solid black"}}>
          {!(Array.isArray(newsData) && newsData.length) && (
            <CircularProgress
              style={{
                height: "16px",
                width: "16px",
                position: "fixed",
                left: "82%",
                top: "55%",
                zIndex: 9999,
              }}
            />
          )}
          <div>
            {newsData.length
              ? newsData.map((item, index) => {
                  return (
                    <React.Fragment key={`home-newslist-${index}`}>
                      <div
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        onClick={() => {
                          setModalContent(
                            <HomePostDetails
                              isMobile={isMobile}
                              item={item}
                              close={close}
                            />
                          );
                          openModal();
                        }}
                      >
                        <div
                          className="newslist-text"
                          style={{ fontSize: "13px", padding: "12px" }}
                        >
                          {item.news_title}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <Square
                                style={{
                                  color: "rgb(17 203 83)",
                                  fontSize: "18px",
                                  marginRight: "5px",
                                }}
                              />
                              <span style={{ fontSize: "11px" }}>
                                {item.datasource_name}
                              </span>
                            </div>
                            <div>
                              <AccessTime
                                className="home-post-details-clock"
                                style={{ fontSize: "18px" }}
                              />
                              <span style={{ fontSize: "11px" }}>
                                {moment(item.news_date).format("DD/MM/YYYY")} /{" "}
                              </span>
                              <span style={{ fontSize: "11px" }}>
                                {time(item.time_ago)} atrás
                              </span>
                            </div>
                          </div>
                        </div>
                        <Divider />
                      </div>
                    </React.Fragment>
                  );
                })
              : null}{" "}
          </div>
        </Card>
      </Item>
    </div>
  );
}
