import React, { useEffect, useState } from "react";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { AppContext } from "../App";
import { Badge, Box, Divider, Modal, Popover, Typography } from "@mui/material";
import { CloseButton } from "react-bootstrap";
import axios from "axios";
import HomePostDetails from "../Home/JS/HomePostDetails";
import * as moment from "moment";
import AgendaPostDetails from "../Agenda/JS/AgendaPostDetails";
import StakeholderEventDetails from "../Calendar/Card/StakeholderEventDetails";
import { AccessTime } from "@mui/icons-material";
import { Spinner } from "react-bootstrap";

export default function Notification() {
  const {
    account,
    pageHeader: title,
    currIdToken,
    currUserId,
    event,
    countNotification,
    setCountNotification,
    isMobile,
    expiresOn,
    renewSilentToken,
  } = React.useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [modalContent, setModalContent] = React.useState();
  const [showModal, setShowModal] = React.useState(false);
  const [readnotification, setReadnotification] = React.useState();
  const [loader, setLoader] = useState(false);
  const StakeholderOpenModal = () => {
    setShowModal(true);
  };
  const StakeholderCloseModal = () => {
    setShowModal(false);
  };
  const handlePopoverOpen = (event) => {
    getKeywords();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const mobileClass = isMobile ? "mobile" : "";
  useEffect(() => {
    if (!currIdToken) return;
    getKeywords();
    getCount();
  }, [currIdToken, currUserId]);

  //To get last 5th date from today
  const lastDate = new Date();
  const seventhDate = lastDate.setDate(lastDate.getDate() - 7);
  const seventhDateNew = moment(seventhDate).format("YYYY-MM-DD");

  const getKeywords = async () => {
    const data = {
      user_id: currUserId,
      since: seventhDateNew,
    };
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }

    await axios
      .post(`${process.env.REACT_APP_API_KEY}/notifications/get-notifications`, data, {
        headers: {
          Authorization: "Bearer " + currIdToken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.length != null) {
          setNotifications(response.data);
          setLoader(true);
        }
      })
      .catch((error) => {});
  };

  const getCount = async () => {
    const data = {
      user_id: currUserId,
      since: seventhDateNew,
    };
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/notifications/unread-notification-count`,
        data,
        {
          headers: {
            Authorization: "Bearer " + currIdToken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data) {
          setCountNotification(response.data.Unread_Notification);
        }
      })
      .catch((error) => {});
  };

  const notficationRead = async (notification) => {
    const data = {
      user_id: currUserId, // currUserId,
      notification_id: notification.notification_id.toString(),
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/notifications/update-notification-readtime`,
        data,
        {
          headers: { Authorization: "Bearer " + currIdToken },
        }
      )
      .then((response) => {
        setReadnotification(true);
        getCount();
      })
      .catch((error) => {});
  };
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const handleClicks = (notification, notificationIdNews, notificationIdAgenda) => {
    getNotiItemDetailsForNews(notification, notificationIdNews);
    getNotiItemDetailsForAgenda(notification, notificationIdAgenda);
    notficationRead(notification);
    handlePopoverClose();
  };
  const getNotiItemDetailsForNews = async (notification, notificationId) => {
    openModal();
    setModalContent("Loading...");
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/news/get-selected-information`,
        {
          type: "fav_" + notification.notification_name,
          ids: [notificationId.toString()],
        },
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then((response) => {
        if (response && response.data && Array.isArray(response.data)) {
          response.data.map((item, index) => {
            setModalContent(
              <HomePostDetails isMobile={isMobile} item={item} close={closeModal} />
            );
            getKeywords();
          });
        }
      })
      .catch((error) => {});
  };

  const getNotiItemDetailsForAgenda = async (notification, notificationId) => {
    openModal();
    setModalContent("Loading...");
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/news/get-selected-information`,
        {
          type: "fav_" + notification.notification_name,
          ids: [notificationId.toString()],
        },
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then((response) => {
        if (response && response.data && Array.isArray(response.data)) {
          response.data.map((item, index) => {
            setModalContent(
              <AgendaPostDetails isMobile={isMobile} item={item} close={closeModal} />
            );
            getKeywords();
          });
        }
      })
      .catch((error) => {});
  };

  const time = (text) => {
    let timeAgo = text.split("~");
    return timeAgo[0];
  };
  const sizeStyle = isMobile
    ? { width: "90vw", height: "80vh" }
    : { width: 1000, height: 500 };

  return (
    <div>
      <div id="notification_icon">
        {countNotification !== "0" ? (
          <Badge badgeContent={countNotification} color="success">
            <NotificationsNoneOutlinedIcon
              id="noti_button"
              className="mx-2 notification-icon"
              aria-describedby={id}
              variant="contained"
              onClick={handlePopoverOpen}
            ></NotificationsNoneOutlinedIcon>
          </Badge>
        ) : (
          <NotificationsNoneOutlinedIcon
            id="noti_button"
            className="mx-2 notification-icon"
            aria-describedby={id}
            variant="contained"
            onClick={handlePopoverOpen}
            color={isMobile ? "black" : ""}
          ></NotificationsNoneOutlinedIcon>
        )}
      </div>
      <Popover
        className="navbar-Popover"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 1 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h3
                style={{
                  display: "block",
                  background: "#fff",
                  fontWeight: "bold",
                  fontSize: "15px",
                  padding: "8px",
                  margin: 0,
                  color: "var(--bayer-dark-blue)",
                }}
              >
                Notificações
              </h3>
            </div>
            <div>
              <CloseButton
                onClick={handlePopoverClose}
                style={{ fontSize: "10px", margin: "8px" }}
              />
            </div>
          </div>
          <Divider style={{ marginTop: "5px", marginBottom: "5px" }} />
          <Typography
            style={{
              height: "250px",
              width: "306px",
              paddingRight: "9px",
              paddingTop: "9px",
              paddingLeft: "5px",
              overflowY: "auto",
            }}
          >
            <div>
              {event.length > 0 ? (
                event.map((item, i) => {
                  return moment(item.dataHoraInicio).format("DD/MM/YYY") ===
                    moment(new Date()).format("DD/MM/YYY") ? (
                    <div>
                      <div
                        className="notification-heading"
                        style={{
                          fontWeight: "bold",
                          marginBottom: "2%",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          setModalContent(
                            <StakeholderEventDetails
                              isMobile={isMobile}
                              item={item}
                              close={StakeholderCloseModal}
                            />
                          );
                          StakeholderOpenModal();
                        }}
                      >
                        Evento das Partes Interessadas
                      </div>
                      <div
                        style={{
                          fontColor: "black",
                          fontSize: "12px",
                          cursor: "pointer",
                          marginBottom: "3px",
                        }}
                        onClick={() => {
                          setModalContent(
                            <StakeholderEventDetails
                              isMobile={isMobile}
                              item={item}
                              close={StakeholderCloseModal}
                            />
                          );
                          StakeholderOpenModal();
                        }}
                      >
                        {" "}
                        {item.descricaoTipo}
                        <Divider style={{ marginTop: "5px", marginBottom: "5px" }} />
                      </div>
                    </div>
                  ) : (
                    ""
                  );
                })
              ) : (
                <></>
              )}
            </div>

            {loader ? (
              <div>
                {notifications.length > 0 ? (
                  notifications.map(function (item, index, arr) {
                    const notification_data = JSON.parse(item.notification_data);

                    let title;
                    if (item.notification_name == "news") {
                      title = notification_data[0].title;
                    } else if (item.notification_name == "agenda") {
                      title = notification_data[0].title;
                    } else {
                      title = notification_data[0].title;
                    }
                    return (
                      <>
                        <React.Fragment key={`navbar-${index}`}>
                          <a
                            style={{ fontSize: "12px" }}
                            onClick={() => notficationRead(item)}
                          >
                            <div
                              style={{
                                fontWeight: "bold",
                                textTransform: "capitalize",
                                cursor: "pointer",
                              }}
                              className=" notification-heading"
                              onClick={() =>
                                handleClicks(
                                  item,
                                  notification_data[0].news_id,
                                  notification_data[0].agenda_id
                                )
                              }
                            >
                              {item.notification_name == "news"
                                ? "Nova mensagem"
                                : item.notification_name == "agenda"
                                ? "Agenda"
                                : item.notification_name}
                            </div>
                            <span
                              className="notification-text"
                              style={{
                                fontWeight: item.read_timestamp == null ? "bold" : "5%",
                                fontSize: "12px",
                              }}
                              onClick={() =>
                                handleClicks(
                                  item,
                                  notification_data[0].news_id,
                                  notification_data[0].agenda_id
                                )
                              }
                            >
                              {notification_data[0]?.news_title
                                ? notification_data[0]?.news_title
                                : notification_data[0]?.title
                                ? notification_data[0]?.title
                                : ""}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <span style={{ fontSize: "11px" }}></span>
                                </div>
                                <br />
                                <div>
                                  <AccessTime
                                    className="home-post-details-clock"
                                    style={{ fontSize: "18px" }}
                                  />

                                  <span style={{ fontSize: "11px" }}>
                                    {moment(item.notification_time).format("DD/MM/YYYY")}{" "}
                                    /{" "}
                                  </span>
                                  <span style={{ fontSize: "11px" }}>
                                    {time(item.time_ago)} atrás
                                  </span>
                                </div>
                              </div>
                            </span>
                          </a>
                          {index === arr.length - 1 ? null : (
                            <Divider
                              style={{
                                marginTop: "5px",
                                marginBottom: "5px",
                              }}
                            />
                          )}
                        </React.Fragment>
                      </>
                    );
                  })
                ) : (
                  <div style={{ width: "100%" }}>
                    <b>Nenhuma nova notificação.</b>
                  </div>
                )}
              </div>
            ) : (
              <div
                className="normalspinner"
                style={{
                  width: "100%",
                  alignItems: "center",
                  textAlign: "center",
                  marginTop: "80px",
                }}
              >
                <Spinner animation="border"></Spinner>
              </div>
            )}
          </Typography>
        </Typography>
      </Popover>
      <Modal open={showModal} onClose={closeModal}>
        <Box sx={{ ...styleModal, ...sizeStyle }}>
          <div
            style={{
              width: "100%",
              overflowY: "scroll",
              overflowX: "hidden",
              paddingRight: "16px",
            }}
          >
            {modalContent}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  pr: 2,
  borderRadius: "10px",
  display: "flex",
};
