import { Button, Collapse, Box } from "@mui/material";
import React from "react";
import OrgaosCard from "./OrgaosCard";

export default function StakeholdersProfileOrgaos({ orgaos }) {
  const [collapse, setCollapse] = React.useState(false);
  const [orgaosOut, orgaosIn] = [orgaos.slice(0, 5), orgaos.slice(5, orgaos.length)];
  return (
    <Box mb={3}>
      <h5 style={{ marginBottom: "15px", fontWeight: "bold" }}>Histórico de órgãos</h5>
      {orgaosOut.map((item) => (
        <OrgaosCard {...item} />
      ))}
      {!collapse && <Button type="button" variant="outlined" onClick={() => setCollapse(true)}>
        Expandir
      </Button>}
      <Collapse in={collapse} timeout="auto">
        {orgaosIn.map((item) => (
          <OrgaosCard {...item} />
        ))}
        <Button type="button" variant="outlined" onClick={() => setCollapse(false)}>
          Minimizar
        </Button>
      </Collapse>
    </Box>
  );
}

