import React, { useState, useEffect, createContext } from "react";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { Card, CircularProgress, Divider } from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import "./navbar.css";
import { AppContext } from "../../App";
import $ from "jquery";
import axios from "axios";
import moment from "moment";
import { CloseButton } from "react-bootstrap";
import RestrictedImg from "../../image/Restricted_Img.png";
import HomePostDetails from "../../Home/JS/HomePostDetails";
import StakeholderEventDetails from "../../Calendar/Card/StakeholderEventDetails";
import { Spinner } from "react-bootstrap";
import AgendaPostDetails from "../../Agenda/JS/AgendaPostDetails";
import { AccessTime } from "@mui/icons-material";
import Notification from "../../ReuseableComponents/Notification";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const NavbarContext = createContext({});

export default function Navbar() {
  const { expiresOn, renewSilentToken, isMobile } = React.useContext(AppContext);
  const sizeStyle = isMobile
    ? { width: "90vw", height: "80vh" }
    : { width: 1000, height: 500 };

  const {
    account,
    pageHeader: title,
    currIdToken,
    currUserId,
    event,
    countNotification,
    setCountNotification,
  } = React.useContext(AppContext);
  const mobileClass = isMobile ? "mobile" : "";
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [modalContent, setModalContent] = React.useState();
  const [showModal, setShowModal] = React.useState(false);
  const StakeholderOpenModal = () => {
    setShowModal(true);
  };
  const StakeholderCloseModal = () => {
    setShowModal(false);
  };
  const handlePopoverOpen = (event) => {
    getKeywords();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  //// For last  read notifictation
  const [readnotification, setReadnotification] = React.useState();
  const [loader, setLoader] = useState(false);

  const [opennotification, setOpennotification] = useState(false);
  const handleOpen = () => setOpennotification(true);
  const handleClose = () => {
    setOpennotification(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (!currIdToken) return;
    getKeywords();
    getCount();
    // handlequery();
  }, [currIdToken, currUserId]);

  //To get last 5th date from today
  const lastDate = new Date();
  const seventhDate = lastDate.setDate(lastDate.getDate() - 7);
  const seventhDateNew = moment(seventhDate).format("YYYY-MM-DD");

  const getKeywords = async () => {
    const data = {
      user_id: currUserId,
      since: seventhDateNew,
    };
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }

    await axios
      .post(`${process.env.REACT_APP_API_KEY}/notifications/get-notifications`, data, {
        headers: {
          Authorization: "Bearer " + currIdToken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.length != null) {
          setNotifications(response.data);
          setLoader(true);
        }
      })
      .catch((error) => {});
  };

  const getCount = async () => {
    const data = {
      user_id: currUserId,
      since: seventhDateNew,
    };
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/notifications/unread-notification-count`,
        data,
        {
          headers: {
            Authorization: "Bearer " + currIdToken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data) {
          setCountNotification(response.data.Unread_Notification);
        }
      })
      .catch((error) => {});
  };

  const notficationRead = async (notification) => {
    const data = {
      user_id: currUserId, // currUserId,
      notification_id: notification.notification_id.toString(),
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/notifications/update-notification-readtime`,
        data,
        {
          headers: { Authorization: "Bearer " + currIdToken },
        }
      )
      .then((response) => {
        setReadnotification(true);
        getCount();
      })
      .catch((error) => {});
  };
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const handleClicks = (notification, notificationIdNews, notificationIdAgenda) => {
    getNotiItemDetailsForNews(notification, notificationIdNews);
    getNotiItemDetailsForAgenda(notification, notificationIdAgenda);
    notficationRead(notification);
    handlePopoverClose();
  };
  const getNotiItemDetailsForNews = async (notification, notificationId) => {
    openModal();
    setModalContent("Loading...");
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/news/get-selected-information`,
        {
          type: "fav_" + notification.notification_name,
          ids: [notificationId.toString()],
        },
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then((response) => {
        if (response && response.data && Array.isArray(response.data)) {
          response.data.map((item, index) => {
            setModalContent(
              <HomePostDetails isMobile={isMobile} item={item} close={closeModal} />
            );
            getKeywords();
          });
        }
      })
      .catch((error) => {});
  };

  const getNotiItemDetailsForAgenda = async (notification, notificationId) => {
    openModal();
    setModalContent("Loading...");
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/news/get-selected-information`,
        {
          type: "fav_" + notification.notification_name,
          ids: [notificationId.toString()],
        },
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then((response) => {
        if (response && response.data && Array.isArray(response.data)) {
          response.data.map((item, index) => {
            setModalContent(
              <AgendaPostDetails isMobile={isMobile} item={item} close={closeModal} />
            );
            getKeywords();
          });
        }
      })
      .catch((error) => {});
  };

  const time = (text) => {
    let timeAgo = text.split("~");
    return timeAgo[0];
  };

  return (
    <NavbarContext.Provider value={{ setNotifications }}>
      <nav className="navbar navbar-light " style={{ height: "60px" }}>
        <h4 className="user-name" style={{ color: "#40899f", margin: 0 }}>
          {!title && <>{"Olá, " + account?.name}! Seja bem-vindo(a).</>}

          {title && <>{title}</>}
        </h4>
        {isMobile ? "" : <Notification />}
      </nav>
      <Divider className="header-divider" style={{ marginBottom: "15px" }} />

      <Modal
        open={opennotification}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <NotificationsActiveIcon className="Notification-icon-modal " />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
      <Modal open={showModal} onClose={closeModal}>
        <Box sx={{ ...styleModal, ...sizeStyle }}>
          <div
            style={{
              width: "100%",
              overflowY: "scroll",
              overflowX: "hidden",
              paddingRight: "16px",
            }}
          >
            {modalContent}
          </div>
        </Box>
      </Modal>
    </NavbarContext.Provider>
  );
}
const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  pr: 2,
  borderRadius: "10px",
  display: "flex",
};
