import { useState, useEffect } from "react";
import { routes } from "../Routes";
import ReactGA from "react-ga";

export const lastVisitedKey = "lastVisited";

export const excludedRoutes = ["/", "/home"];

const isExcludedRoutes = (path) => excludedRoutes.indexOf(path) + 1;

const hasPathOnItem = (item, path) => {
  const hasOnSubnav = item.subNav?.some((subnavItem) => subnavItem.path.includes(path));
  const hasOnParent = item.path === path;
  return hasOnSubnav || hasOnParent;
};

const isOnHistory = (history, path) =>
  history.some((routeIndex) => hasPathOnItem(routes[routeIndex], path));

const isAvailableRoute = (path) => routes.some((item) => hasPathOnItem(item, path));

const getRouteIndex = (path) => routes.findIndex((item) => hasPathOnItem(item, path));

const getNewHistory = (currHistory, path) => {
  const newItem = getRouteIndex(path);
  if (currHistory.length === 0) return [newItem];
  return [newItem, currHistory[0]];
};

/**
 *  *
 * TODO on the instantiation of the history useState, change the code from JSON.parse(localStorage.getItem(lastVisitedKey)) to the GA one
 */

export const useSaveHistory = (location) => {
  const [history, setHistory] = useState(
    JSON.parse(localStorage.getItem(lastVisitedKey)) || []
  );
  useEffect(() => {
    const path = location?.pathname;
    if (!isAvailableRoute(path) || isExcludedRoutes(path) || isOnHistory(history, path))
      return;

    setHistory((currHistory) => {
      const newHistory = getNewHistory(currHistory, path);
      ReactGA.set({ page: path });
      ReactGA.pageview(path);
      localStorage.setItem(lastVisitedKey, JSON.stringify(newHistory));
      return newHistory;
    });
  }, [history, location?.pathname]);
};
