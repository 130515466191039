import React from "react";
import {
  CalendarTodayOutlined,
  EmailOutlined,
  HomeOutlined,
  HouseOutlined,
  ManageSearch,
  MapOutlined,
  PeopleOutline,
  PersonOutline,
  StarBorder,
} from "@mui/icons-material";
import Noticias from "./Noticias/JS/Noticias";
import Stakeholders from "./Stakeholders/Stakeholders";
import StakeholdersProfile from "./Stakeholders/Pessoas/StakeHolderProfile";
import Favourite from "./Favourite/JS/Favourite";
import Home from "./Home/JS/Home";
import Agenda from "./Agenda/JS/Agenda";
import Mapa from "./Mapa/JS/Mapa";
import Calendar from "./Calendar/Calendar";
import SocialMedia from "./SocialMedia/JS/SocailMedia";
import FavouriteItemList from "./Favourite/JS/FavouriteItemList";
import { Route } from "react-router-dom";
import Institutions from "./Stakeholders/Institution/JS/Institutions";
import Instituteprofile from "./Stakeholders/Institution/JS/InstitutionProfile";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";

export const routes = [
  {
    title: "Home",
    path: "/",
    icon: <HomeOutlined />,
    component: Home,
  },
  {
    title: "Stakeholders",
    icon: <PeopleOutline />,
    subNav: [
      {
        title: "Pessoas",
        path: "/stakeholders-pessoas",
        icon: <PersonOutline />,
        component: Stakeholders,
        dynamic: {
          component: StakeholdersProfile,
        },
      },
      {
        title: "Instituições",
        path: "/stakeholders-instituicoes",
        icon: <HouseOutlined />,
        component: Institutions,
        dynamic: {
          component: Instituteprofile,
        },
      },
    ],
  },
  {
    title: "Notícias",
    path: "/noticias",
    icon: <FeedOutlinedIcon />,
    component: Noticias,
  },
  {
    title: "Agenda",
    path: "/agenda",
    icon: <EventNoteOutlinedIcon />,
    component: Agenda,
  },
  {
    title: "Mídias Sociais",
    path: "/social-media",
    icon: <EmailOutlined />,
    component: SocialMedia,
  },
  {
    title: "Calendário",
    path: "/calendario",
    icon: <CalendarMonthOutlinedIcon />,
    component: Calendar,
  },
  {
    title: "Mapa De Conexão",
    path: "/map",
    icon: <TravelExploreOutlinedIcon />,
    component: Mapa,
  },
  {
    title: "Favoritos",
    path: "/favourites",
    icon: <StarBorder />,
    component: Favourite,
  },
];

export const generateRoutes = () =>
  routes.reduce((acc, route) => {
    let response = [];
    if (route.subNav) {
      response = route.subNav.reduce((subAcc, subItem) => {
        let subResponse = [
          <Route exact path={subItem.path} component={subItem.component} />,
        ];
        if (subItem.dynamic) {
          subResponse.push(
            <Route
              exact
              path={`${subItem.path}/:id`}
              component={subItem.dynamic.component}
            />
          );
        }
        return [...subAcc, ...subResponse];
      }, []);
    } else {
      response = [<Route exact path={route.path} component={route.component} />];
      if (route.dynamic) {
        response.push(
          <Route exact path={`${route.path}/:id`} component={route.dynamic.component} />
        );
      }
    }
    return [...acc, ...response];
  }, []);
