import React, { useEffect } from "react";
import EmptyProfile from "../../image/EmptyProfile.png";
import moment from "moment";
import styles from "./pdf.module.css";

const isArrayEmptyOrNull = (array) => !array || !Array.isArray(array) || !array.length;

export default function StakeholderProfilePdf({
  pdfData: { stakeholder, informacoes, atuacaoLegislativa, conexoesPoliticas, profileImage },
}) {
  const [imageErrorFlag, setImageErrorFlag] = React.useState(false);
  const handleImageError = () => setImageErrorFlag(true);
  const stackholderImg = stakeholder.ultimoStatus.urlFoto;
  useEffect(() => {
    printIframe();
  }, [])

  const printIframe = () => {
    var proxyIframe = document.createElement("iframe");
    proxyIframe.style.width = "100%";
    proxyIframe.style.height = "100%";
    proxyIframe.style.display = "none";
    var body = document.getElementsByTagName("body")[0];
    body.appendChild(proxyIframe);
    const url = "https://www.camara.leg.br/internet/deputado/bandep/204554.jpg";
    var contentWindow = proxyIframe.contentWindow;
    contentWindow.document.open();
    contentWindow.document.write(
      '<iframe src="' +
      url +

      '" onload="print();" width="1000" height="1800" frameborder="0" marginheight="0" marginwidth="0">'
    );

    contentWindow.document.close();

  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h5>{stakeholder.ultimoStatus.nome}</h5>
        <small>Deputado</small>
        <small>
          {stakeholder.ultimoStatus.siglaUf} / {stakeholder.ultimoStatus.siglaPartido}
        </small>
      </div>
      <hr style={{ marginBottom: "10px" }} />
      <h4 style={{ marginTop: "30px" }}>Informações</h4>
      <h5 style={{ marginTop: "10px" }}>Histórico de Profissoes</h5>
      {isArrayEmptyOrNull(informacoes.profissoes) && <p>Não Há Histórico</p>}
      {informacoes.profissoes.map(({ dataHora, titulo, codTipoProfissao }) => {
        return (
          <div className={styles.twocolumns}>
            <b style={{ color: "var(--bayer-md-green)" }}>
              {moment(dataHora).format("DD/MM/YYYY")}
            </b>
            <span>{titulo}</span>
          </div>
        );
      })}
      <h5 style={{ marginTop: "10px" }}>Histórico de Ocupações</h5>
      {isArrayEmptyOrNull(informacoes.ocupacoes) && <p>Não Há Histórico</p>}
      {informacoes.ocupacoes.map(
        ({ anoFim, anoInicio, entidade, entidadePais, entidadeUF, titulo }) => {
          return (
            anoInicio && (
              <div>
                <b style={{ color: "var(--bayer-md-green)" }}>
                  {anoInicio} - {anoFim ?? "Atual"}
                </b>
                <div>
                  <p> <b>{titulo}</b> </p>

                  <p>{entidade}</p>
                </div>
              </div>
            )
          );
        }
      )}
      <h5 style={{ marginTop: "10px" }}>Histórico de Órgãos</h5>
      {isArrayEmptyOrNull(informacoes.orgaos) && <p>Não Há Histórico</p>}
      {informacoes.orgaos.map(
        ({ uriOrgao, siglaOrgao, nomePublicacao, nomeOrgao, codTitulo, titulo, dataInicio }) =>
          dataInicio && (
            <div className={styles.twocolumns}>
              <b style={{ color: "var(--bayer-md-green)" }}>
                {moment(dataInicio).format("DD/MM/YYYY")}
              </b>
              <div>
                <p> <b>{nomePublicacao}</b></p>
                <br />

                <p style={{ marginTop: "8px" }}>{titulo}</p>
              </div>
            </div>
          )
      )}
      <h5 style={{ marginTop: "10px" }}>Histórico de Frentes</h5>
      {isArrayEmptyOrNull(informacoes.frentes) && <p>Não Há Histórico</p>}
      {informacoes.frentes.map(({ titulo }) => (
        <div>
          <b style={{ color: "var(--bayer-md-green)" }}>{titulo}</b>
        </div>
      ))}
      <hr style={{ marginBottom: "10px !important" }} />
      <h4 style={{ marginTop: "30px !important" }}>Atuação Legislativa</h4>
      <h5>Histórico Atuacao Legislativa</h5>
      {atuacaoLegislativa.proposicoes.map(
        ({ dataApresentacao, dataHora, siglaTipo, ano, numero, ementa, codTipo }) => {
          return (
            <div className={styles.twocolumns}>
              <div>
                <div style={{ color: "var(--bayer-md-green)", fontWeight: "bold" }}>{ano}</div>
                {numero} {" numero"}
              </div>
              <div>
                <p>  {ementa} - {siglaTipo}
                </p>
              </div>
            </div>
          );
        }
      )}
      <hr style={{ marginBottom: "10px" }} />
      <h4 style={{ marginTop: "30px" }}>Conexões Políticas</h4>
      <h5>Legislaturas (Mesa)</h5>
      {conexoesPoliticas?.legislaturas.map(
        ({ dataInicio, dataFim, nome, siglaPartido, siglaUf, titulo }) => (
          <div className={styles.twocolumns}>
            <b style={{ color: "var(--bayer-md-green)" }}>
              {moment(dataInicio).format("MM/YYYY")} -{" "}
              {dataFim ? moment(dataFim).format("MM/YYYY") : "Atual   "}
            </b>
            <div>
              <p> <b>{nome} - {siglaPartido}/{siglaUf} </b></p>
              <div><p>{titulo}</p></div>
            </div>
          </div>
        )
      )}
      <h5>Frentes (Membros)</h5>
      {conexoesPoliticas?.frentes.map((item) => (
        <p>Total de Membros - {item.length}</p>
      ))}
      <h5>Órgãos (Membros)</h5>
      {conexoesPoliticas?.orgaos.map((item) => (
        <p>Total de Membros - {item.length}</p>
      ))}

    </div>
  );
}
