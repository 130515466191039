import React, { useState, useEffect } from "react";
// import "../../../card.css";
import { Grid, Paper, Button, Box, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import "../../CSS/institutionCard.css";
import { AppContext } from "../../../../App";
import EmptyProfile from "../../../../image/EmptyProfile.png";
import axios from "axios";
import { withRouter } from "react-router-dom";

function InstitutionNoticiasDetails({ items }) {
    const { isMobile, currIdToken, expiresOn, renewSilentToken, institutionNoticias } = React.useContext(AppContext);
    const mobileClass = isMobile ? "mobile" : "";
    const [imageErrorFlag, setImageErrorFlag] = React.useState(false);
    const handleImageError = () => setImageErrorFlag(true);
   
    const sizeStyle = isMobile ? { width: "70vw", height: "80vh" } : { width: 1000, height: 500 };
    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        pr: 2,
        borderRadius: "10px",
        display: "flex",
    };
    const informationPara = (text) => {
        // let paragraph = text.split("\\.(?=( |[A-Z]))"); 
        // let paragraph = text.split("\n\n");
        let paragraph = text.split(".");
        return paragraph;
    };
    return (
        <div className="card-profile" >
            <h5 style={{ fontWeight: "bold" }}>{items.institution_news_title}</h5>
            <div className="card-img">
                {!imageErrorFlag && <img src={items.institution_news_image} style={{ width: "124px", height: "96px" ,borderRadius:"10px", margin: "10px 0px" }} alt="img" onError={handleImageError} />}
                {imageErrorFlag && <img style={{ width: "102px", height: "102px", margin: "10px 0px" }} src={EmptyProfile} alt="img" />}

            </div>
            <Typography style={{ width: "100%", textAlign: "justify" }} >
                {informationPara(items.institution_news_text).map((data, index) => {
                    return <p>{data}.</p>;
                })}
            </Typography>
        </div>
    )
}

export default InstitutionNoticiasDetails