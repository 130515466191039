import { Box, Grid, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import StakeholdersProfileDados from "./Dados/StakeholderProfileDados";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { textAlign } from "@mui/system";
import { Tag } from "@mui/icons-material";
import * as moment from "moment";
import { Card, Divider } from "@mui/material";
import axios from "axios";
import { AppContext } from "../../../App";
import { AccessTime, Square, StarBorder, StarRounded } from "@mui/icons-material";
import { Spinner } from "react-bootstrap";
import PessoasMessage from "../FooterMessages/Js/PessoasMessage";

export default function StakeholderTweet({ posicionamentos, nome }) {
  const { isMobile, currIdToken, expiresOn, renewSilentToken } =
    React.useContext(AppContext);

  useEffect(() => {
    if (!currIdToken && !nome) return;
    // handleRecenTweets();
  }, [currIdToken, nome]);
  const [loading, setLoading] = useState(false);
  const [tweets, settweets] = useState([]);
  const mobileClass = isMobile ? "mobile" : "";
  const handleRecenTweets = async () => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/twitter-get-stakeholder-tweets/` + nome, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        settweets(response.data);
        setLoading(true);
      })
      .catch(renewSilentToken);
  };
  return (
    <Grid item>
      <h5 style={{ marginBottom: "15px", fontWeight: "bold" }}>
        Feeds recentes
      </h5>
      {
        loading ? (
          <div>
            {tweets.length > 0
              ? tweets.map((item) => {
                  return (
                    <Card style={{ padding: "10px" }}>
                      <Typography>
                        <b style={{ fontSize: "16px" }}> {item.author}</b>{" "}
                        &nbsp;{" "}
                      </Typography>
                      <Link
                        href={item.twitter_url}
                        target="_blank"
                        style={{ textDecoration: "none", color: "#3c3c3c" }}
                      >
                        <Typography>{item.text}</Typography>
                      </Link>
                      {/* <div className="modal-author-time"> */}
                      <div className="author">
                        <Square
                          style={{ padding: "4px" }}
                          className="home-btn-details-square"
                        />{" "}
                        <a
                          href={`https://twitter.com/${item.twitter_name}`}
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          {item.twitter_name}{" "}
                        </a>
                      </div>
                      <div
                        className="time"
                        style={{ textTransform: "capitalize" }}
                      >
                        <AccessTime
                          style={{
                            padding: "2px",
                            fontSize: "17px",
                            marginRight: "5px",
                          }}
                        />{" "}
                        {moment(item.created_at).format("DD/MM/YYYY")}
                      </div>
                      {/* </div> */}
                    </Card>
                  );
                })
              : "A parte interessada não postou nada nos últimos tempos"}
          </div>
        ) : (
          //delete this div and uncomment below div when twitter api will work

          <div>
            Funcionalidade temporariamente indisponível dadas às recentes
            mudanças e limitações de views e posts aplicadas pelo Twitter
          </div>
        )

        // (
        //   <div
        //     className="normalspinner"
        //     style={{
        //       width: "100%",
        //       zIndex: 9999,
        //       alignItems: "center",
        //       textAlign: "center",
        //       marginTop: "150px",
        //     }}
        //   >
        //     <Spinner animation="border"></Spinner>
        //   </div>
        // )
      }
    </Grid>
  );
}
