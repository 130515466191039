import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Paper,
  InputBase,
  Divider,
  IconButton,
} from "@mui/material";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { createTheme } from "@mui/material/styles";
import { HomeData } from "./HomeData";
import HomePosts from "./HomePosts";
import { EditOutlined, SearchRounded } from "@mui/icons-material";
import HomeButtonDetails from "./HomeButtonDetails";
import { HomeRecentSearchPages } from "./HomeRecentSearchPages";
import HomeRecentSearchPagesDetails from "./HomeRecentSearchPagesDetails";
import Footer from "../../ReuseableComponents/Footer";
import { usePageHeader } from "../../MasterPage/MasterPage";
import { AppContext } from "../../App";
import axios from "axios";
import { KeywordPopulationForm } from "./KeywordPopulationForm";
import HomeNewsList from "./HomeNewsList";
import { useGetFetch } from "../../hooks/useFetchBackend";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import "../CSS/Home.css";
//For Modal
export const HomeContext = React.createContext({});

export default function Home() {
  usePageHeader(null);
  const fetchBackend = useGetFetch();
  const [showModal, setShowModal] = useState(false);
  const [showModalForm, setShowModalForm] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [keyword, setKeyword] = useState("");
  const [keywordModalData, setKeywordModalData] = useState({});
  const {
    isMobile,
    currIdToken,
    expiresOn,
    renewSilentToken,
    currUserId,
    setNotifications,
    setCountNotification,
    keywordData,
    check,
    setCheck,
    getSelectedKeywords,
  } = React.useContext(AppContext);

  const sizeStyle = isMobile
    ? { width: "90vw", height: "80vh" }
    : { width: 1000, height: 500 };

  const sizeStyleForm = isMobile
    ? { width: "80vw", height: "70vh" }
    : { width: 500, height: 440 };

  useEffect(() => {
    if (!currIdToken) return;
    getSelectedKeywords();
    getCount();
  }, [currIdToken]);

  //To get last 5th date from today
  const lastDate = new Date();
  const seventhDate = lastDate.setDate(lastDate.getDate() - 7);
  const seventhDateNew = moment(seventhDate).format("YYYY-MM-DD");

  const getCount = async () => {
    const data = {
      user_id: currUserId,
      since: seventhDateNew,
    };
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/notifications/unread-notification-count`,
        data,
        {
          headers: {
            Authorization: "Bearer " + currIdToken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data) {
          setCountNotification(response.data.Unread_Notification);
        }
      })
      .catch((error) => {});
  };
  const getCheckboxValues = (datavalue) => {
    setCheck(datavalue);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openModalForm = (e) => {
    setShowModalForm(true);
  };

  const closeModalForm = () => {
    setShowModalForm(false);
    getCount();
  };

  const searchKeywordValue = (e) => {
    let data = e.target.value;
    setKeyword(data);
  };

  const handleRecentSearch = async (item) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    openModal();
    setModalContent("Loading...");
    const response = await fetch(
      `${process.env.REACT_APP_API_KEY}/news/get?keywords=${item.text}`,
      {
        method: "POST",
        headers: { Authorization: "Bearer " + currIdToken },
      }
    ).then((r) => r.json());
    if (response !== "" || response !== null || response.length > 0) {
      const newItem = {
        text: item.text,
        data: response,
      };
      setKeywordModalData(newItem);
      setModalContent(<HomeButtonDetails item={newItem} close={closeModal} />);
    } else {
      setModalContent("Atualmente os dados não estão disponíveis");
    }
  };

  const handleImpKeywordSearch = async (item) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }

    const subTopics = keywordData.filter((topic) => {
      return item == topic.topic;
    });

    const subTopicData = subTopics.map((topic) => topic.sub_topic);
    const AllsubTopics = subTopicData.join(",");

    openModal();
    setModalContent("Loading...");

    const params = {
      keywords: AllsubTopics,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_KEY}/news/get?keywords=${AllsubTopics}`,
      {
        method: "POST",
        headers: { Authorization: "Bearer " + currIdToken },
      }
    ).then((r) => r.json());
    if (response !== "" || response !== null || response.length > 0) {
      const newItem = {
        text: item,
        data: response,
      };
      setKeywordModalData(newItem);
      setModalContent(<HomeButtonDetails item={newItem} close={closeModal} />);
    } else {
      setModalContent("Atualmente os dados não estão disponíveis");
    }
  };
  const mobileClass = isMobile ? "mobile" : "";
  return (
    <HomeContext.Provider value={{ openModal, setModalContent, keywordModalData }}>
      {HomeData.map((item, index) => {
        return (
          <React.Fragment key={`home-homedata1-${index}`}>
            <h5
              className="home-heading"
              style={{ color: "var(--bayer-md-blue)", fontWeight: "bold" }}
            >
              {item.title}{" "}
            </h5>
            <Typography style={{ marginTop: "10px" }}>{item.description}</Typography>
          </React.Fragment>
        );
      })}
      <div className="home-posts" style={{ width: "100%", display: "flex", flexDirection: "row" }}>
        <div className="home-posts-list" style={{ width: "70%" }}>
          <HomePosts />
        </div>
        <div
          className="home-news-list"
          style={{ width: "30%", marginTop: "20px", marginLeft: "-21px" }}
        >
          <HomeNewsList close={closeModal} />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        {HomeData.map((item, index) => {
          return (
              <React.Fragment>
              <div style={{flexBasis: "100%"}}>
                <div style={{display: "flex", flexDirection: "row"}}>
                <Typography
                  style={{
                    marginTop: "20px",
                    fontWeight: "bold",
                    color: "var(--bayer-dark-blue)",
                  }}
                >
                  Palavras Chaves{" "}
                  </Typography>
                  <EditOutlined
                    sx={{ cursor: "pointer", marginTop: "17px", marginLeft: "5px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      openModalForm();
                    }}
                  />
                  </div>
                  <Modal open={showModalForm} onClose={closeModalForm}>
                    <Box sx={{ ...styleModal, ...sizeStyleForm }}>
                      <div
                        style={{
                          width: "100%",
                          overflowY: "scroll",
                          overflowX: "hidden",
                          paddingRight: "16px",
                        }}
                      >
                        <KeywordPopulationForm
                          isMobile={isMobile}
                          getCheckboxValues={getCheckboxValues}
                          closeModalForm={closeModalForm}
                          existingKeywordData={keywordData}
                          getKeywordsAPI={getSelectedKeywords}
                          getCount={getCount}
                        />
                      </div>
                    </Box>
                  </Modal>
                {check.map((item, index) => {
                  return (
                    <React.Fragment key={`home-checkeditems-${index}`}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          handleImpKeywordSearch(item);
                        }}
                        style={{
                          marginRight: "10px",
                          marginTop: "10px",
                          backgroundColor:
                            index % 2 === 0 ? "var(--bayer-blue)" : "var(--bayer-green)",
                        }}
                      >
                        {item}{" "}
                      </Button>
                    </React.Fragment>
                  );
                })}{" "}
                <div style={{ display: "flex" }}>
                  {" "}
                  <Paper
                    component="form"
                    variant="outlined"
                    size="small"
                    style={{ marginRight: "10px", marginTop: "10px" }}
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleRecentSearch({ text: keyword });
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Pesquisar"
                      value={keyword}
                      inputProps={{ "aria-label": "Search" }}
                      onChange={(e) => {
                        setKeyword(e.target.value);
                      }}
                    />
                    <IconButton
                      type="submit"
                      sx={{ p: "10px", ml: "15%" }}
                      aria-label="search"
                    >
                      <SearchRounded />
                    </IconButton>
                  </Paper>
                  <Tooltip
                    title="Use esta barra de pesquisa para busca de novas palavras-chaves"
                    placement="right-start"
                  >
                    <InfoIcon
                      style={{
                        marginTop: "22px",
                        width: "20px",
                        height: "20px",
                        color: "grey",
                      }}
                    />
                  </Tooltip>
                </div>
              </div>

              <div style={{ flexBasis: "100%" }}>
                <Typography
                  style={{
                    marginTop: "20px",
                    marginLeft: "5px",
                    fontWeight: "bold",
                    color: "var(--bayer-dark-blue)",
                  }}
                >
                  {item.title3}{" "}
                </Typography>
                <div style={{ display: "flex" }}>
                  {HomeRecentSearchPages()?.map((item, index) => {
                    return (
                      <HomeRecentSearchPagesDetails
                        item={item}
                        key={`home-recentsearchpage-${index}`}
                      />
                    );
                  })}{" "}
                </div>
              </div>
            </React.Fragment>
          );
        })}{" "}
      </div>
      <Modal open={showModal} onClose={closeModal}>
        <Box sx={{ ...styleModal, ...sizeStyle }}>
          <div
            style={{
              width: "100%",
              overflowY: "scroll",
              overflowX: "hidden",
              paddingRight: "16px",
            }}
          >
            {modalContent}
          </div>
        </Box>
      </Modal>
    </HomeContext.Provider>
  );
}

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  pr: 2,
  borderRadius: "10px",
  display: "flex",
};
