import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import InputAdornment from "@mui/material/InputAdornment";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  CircleMarker,
  Tooltip,
  ZoomControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "../CSS/Mapa.css";
import L from "leaflet";
import { Autocomplete, Box, Modal, Stack, TextField } from "@mui/material";
import {
  AddCircleOutline,
  PlusOneOutlined,
  SearchRounded,
  SettingsPhoneTwoTone,
} from "@mui/icons-material";
import axios from "axios";
import { AppContext } from "../../App";
import { usePageHeader } from "../../MasterPage/MasterPage";
import { Spinner } from "react-bootstrap";
import MapaModal from "./MapaModal";
import MapMessage from "../../Stakeholders/Pessoas/FooterMessages/Js/MapMessage";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export default function Mapa() {
  usePageHeader("Mapa De Conexão");

  delete L.Icon.Default.prototype._getIconUrl;

  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  });
  const [dashmap, setDashmap] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);
  const [stakeholderConnection, setStakeholderConnection] = useState([]); //stakeholder connections
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState();

  const { isMobile, currIdToken, expiresOn, renewSilentToken } =
    React.useContext(AppContext);

  const sizeStyle = isMobile
    ? { width: "90vw", height: "80vh" }
    : { width: 1000, height: 500 };

  useEffect(() => {
    if (!currIdToken) return;
    handleMapa();
  }, [currIdToken]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleMapa = () => {
    getStakeholderPosition();
    getStakeholderInfo();
  };

  const getStakeholderInfo = async () => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/moi/get-all-stakeholders`, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        if (response.data != "") {
          const stakeholderDetail = [];
          var stakeholderInfoData = response.data;
          for (var i = 0; stakeholderInfoData.length > i; i++) {
            stakeholderDetail.push({
              label: stakeholderInfoData[i].stakeholder_nome,
              id: stakeholderInfoData[i].stakeholder_information_id,
              state: stakeholderInfoData[i].stakeholder_siglauf,
            });
          }
          setDropdownData(stakeholderDetail);
        }
      })
      .catch(renewSilentToken);
  };

  const getStakeholderPosition = async () => {
    setLoading(true);
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/moi/get-state`, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        if (response.data != "") {
          var locationData = [];
          var getData = response.data;
          for (var i = 0; getData.length > i; i++) {
            locationData.push({
              state: getData[i].state_name,
              location: {
                lat: parseFloat(getData[i].latitute),
                lng: parseFloat(getData[i].longitute),
              },
            });
          }
          setDashmap(locationData);
        }
        setLoading(false);
      })
      .catch(renewSilentToken);
  };

  const getStakeholderConnections = async (stakeholderId) => {
    setLoading(true, 5000);

    if (expiresOn < new Date()) {
      await renewSilentToken();
    }

    await axios
      .get(`${process.env.REACT_APP_API_KEY}/moi/get-connection/` + stakeholderId, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        if (response.data != "") {
          var connectionData = [];
          var getData = response.data;

          for (var i = 0; getData.length > i; i++) {
            connectionData.push({
              id_x: getData[i].stakeholder_id_x,
              id_y: getData[i].stakeholder_id_y,
              nome_x: getData[i].nome_x,
              nome_y: getData[i].nome_y,
              number_of_connections: getData[i].number_of_connections,
              location_connection: getData[i].location_connection,
              legislativa_connection_count: getData[i].legislativa_connection,
              political_party_connection: getData[i].political_party_connection,
              frentes_connection_count: getData[i].frentes_connection,
              orgaos_connection_count: getData[i].orgaos_connection,
              frentes_connection_count_total: getData[i].count_of_frentes,
              orgaos_connection_count_total: getData[i].count_of_orgaos,
              legislativa_connection_count_total: getData[i].count_of_legislativa,
              location_x: {
                lat: getData[i].latitude_x,
                lng: getData[i].longitude_x,
              },
              location_y: {
                lat: getData[i].latitude_y,
                lng: getData[i].longitude_y,
              },
              siglaPartido_x: getData[i].siglaPartido_x,
              siglaPartido_y: getData[i].siglaPartido_y,
              state_x: getData[i].state_x,
              state_y: getData[i].state_y,
            });
          }

          setStakeholderConnection(connectionData);
        }
        setLoading(false);
      })
      .catch(renewSilentToken);
  };

  const defaultCenter = {
    lat: -15.793889,
    lng: -47.882778,
  };

  const locations = dashmap;
  const [selected, setSelected] = useState({});

  const getFrentesConnecDetails = async (id_x, id_y, nome_x, nome_y) => {
    openModal();
    setModalContent("Loading...");
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/moi/get-frentes-details`,
        {
          stakeholder_id_x: id_x.toString(),
          stakeholder_id_y: id_y.toString(),
        },
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then((response) => {
        if (response && response.data && Array.isArray(response.data)) {
          setModalContent(
            <MapaModal
              isMobile={isMobile}
              data={response.data}
              stakeholderNameX={nome_x}
              stakeholderNameY={nome_y}
              topicName={"Frentes Parlamentares que"}
              close={closeModal}
            />
          );
        }
      })
      .catch(renewSilentToken);
  };

  const getOrgaosConnecDetails = async (id_x, id_y, nome_x, nome_y) => {
    openModal();
    setModalContent("Loading...");
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/moi/get-orgao-details`,
        {
          stakeholder_id_x: id_x.toString(),
          stakeholder_id_y: id_y.toString(),
        },
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then((response) => {
        if (response && response.data && Array.isArray(response.data)) {
          setModalContent(
            <MapaModal
              isMobile={isMobile}
              data={response.data}
              stakeholderNameX={nome_x}
              stakeholderNameY={nome_y}
              topicName={"Órgãos que"}
              close={closeModal}
            />
          );
        }
      })
      .catch(renewSilentToken);
  };

  const getLegislativeConnecDetails = async (id_x, id_y, nome_x, nome_y) => {
    openModal();
    setModalContent("Loading...");
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/moi/get-legistative-details`,
        {
          stakeholder_id_x: id_x.toString(),
          stakeholder_id_y: id_y.toString(),
        },
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then((response) => {
        if (response && response.data && Array.isArray(response.data)) {
          setModalContent(
            <MapaModal
              isMobile={isMobile}
              data={response.data}
              stakeholderNameX={nome_x}
              stakeholderNameY={nome_y}
              topicName={"Atuações Legislativas que"}
              close={closeModal}
            />
          );
        }
      })
      .catch(renewSilentToken);
  };

  return (
    <>
        <p className="mapa-text">Para ver as conexões entre os stakeholders, clique nos ícones azuis dispostos no mapa.</p>

      <div className="map-heading">
        <form className="d-flex gap-2 mt-3 mb-3">
          <Stack style={{ width: "50%" }}>
            <Autocomplete
              disablePortal
              disableClearable
              id="combo-box-demo"
              options={dropdownData}
              renderInput={(params) => (
                <TextField {...params} label="Pesquisar" size="small" />
              )}
              onChange={(event, selectedValue) => {
                setShow(false);

                getStakeholderConnections(selectedValue?.id);
              }}
            />
          </Stack>
        </form>
        {loading == false ? (
          <MapContainer
            className="map"
            center={defaultCenter}
            zoom={4.5}
            style={{ marginBottom: "40px" }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {show ? (
              locations.map((item) => {
                return (
                  <>
                    <CircleMarker
                      radius={4}
                      key={item.name}
                      center={item.location} //for circle marker
                      onMouseOver={(e) => {
                        e.target.openPopup();
                      }}
                      onMouseOut={(e) => {
                        e.target.closePopup();
                      }}
                    >
                      <Popup
                        position={item.location}
                        clickable={true}
                        onCloseClick={() => setSelected({})}
                      >
                        <p style={{ fontSize: "12px" }}>Estado: {item.state}</p>
                      </Popup>
                    </CircleMarker>
                  </>
                );
              })
            ) : (
              <></>
            )}

            {!show ? (
              stakeholderConnection.map((item) => {
                return (
                  <>
                    <div>
                      <CircleMarker
                        radius={7}
                        key={item.nome_x}
                        center={item.location_x} //for circle marker
                        onMouseOver={(e) => {
                          e.target.openPopup();
                        }}
                        onMouseOut={(e) => {
                          e.target.closePopup();
                        }}
                        fillColor="white"
                      >
                        <Popup
                          position={item.location_x}
                          clickable={true}
                          onCloseClick={() => setSelected({})}
                        >
                          <p style={{ fontSize: "12px" }}>Nome: {item.nome_x}</p>
                          {item.location_connection !== 1 &&
                          item.political_party_connection !== 1 &&
                          item.frentes_connection_count !== 1 &&
                          item.orgaos_connection_count !== 1 &&
                          item.legislativa_connection_count !== 1 ? (
                            <p style={{ fontSize: "12px" }}>
                              A parte interessada selecionada não tem nenhuma conexão
                            </p>
                          ) : (
                            <>
                              <p style={{ fontSize: "12px" }}>Estado: {item.state_x}</p>
                              <p style={{ fontSize: "12px" }}>
                                Sigla Partido: {item.siglaPartido_x}
                              </p>
                            </>
                          )}
                        </Popup>

                        <Tooltip
                          direction="bottom"
                          offset={[0, 20]}
                          opacity={1}
                          permanent
                        >
                          {item.nome_x}
                        </Tooltip>
                      </CircleMarker>
                      {item.location_connection === 1 ||
                      item.political_party_connection === 1 ||
                      item.frentes_connection_count === 1 ||
                      item.orgaos_connection_count === 1 ||
                      item.legislativa_connection_count === 1 ? (
                        <CircleMarker
                          radius={4}
                          key={item.nome_y}
                          center={item.location_y} //for circle marker
                          onMouseOver={(e) => {
                            e.target.openPopup();
                          }}
                          onMouseOut={(e) => {
                            e.target.closePopup();
                          }}
                        >
                          <Popup
                            position={item.location_y}
                            clickable={true}
                            onCloseClick={() => setSelected({})}
                          >
                            <p style={{ fontSize: "12px" }}>
                              {item.nome_x} tem uma conexão com {item.nome_y} com os
                              seguintes detalhes:
                            </p>
                            {item.state_x === item.state_y ? (
                              <p style={{ fontSize: "12px" }}>
                                Ambos possuem mandato pelo estado de {item.state_y}
                              </p>
                            ) : (
                              <></>
                            )}
                            {item.siglaPartido_x === item.siglaPartido_y ? (
                              <p style={{ fontSize: "12px" }}>
                                Ambos participam do mesmo Partido Político:{" "}
                                {item.siglaPartido_y}
                              </p>
                            ) : (
                              <></>
                            )}

                            {item.frentes_connection_count === 1 ? (
                              <p style={{ fontSize: "12px" }}>
                                Ambos trabalharam na mesma Organização de Frente
                                Parlamentar tendo um total de {""}
                                {item.frentes_connection_count_total} conexões
                                <a
                                  href="#"
                                  onClick={() =>
                                    getFrentesConnecDetails(
                                      item.id_x,
                                      item.id_y,
                                      item.nome_x,
                                      item.nome_y
                                    )
                                  }
                                  style={{
                                    fontSize: "10px",
                                    textDecoration: "none",
                                  }}
                                >
                                  {""} Ver Mais
                                </a>
                              </p>
                            ) : (
                              <></>
                            )}
                            {item.orgaos_connection_count === 1 ? (
                              <p style={{ fontSize: "12px" }}>
                                Ambos já trabalharam no mesmo Órgão Parlamentar tendo um
                                total de {""}
                                {item.orgaos_connection_count_total} conexões
                                <a
                                  href="#"
                                  onClick={() =>
                                    getOrgaosConnecDetails(
                                      item.id_x,
                                      item.id_y,
                                      item.nome_x,
                                      item.nome_y
                                    )
                                  }
                                  style={{
                                    fontSize: "10px",
                                    textDecoration: "none",
                                  }}
                                >
                                  {""} Ver Mais
                                </a>
                              </p>
                            ) : (
                              <></>
                            )}
                            {item.legislativa_connection_count === 1 ? (
                              <p style={{ fontSize: "12px" }}>
                                Ambos já trabalharam na mesma Frente Legislativa tendo um
                                total de {""}
                                {item.legislativa_connection_count_total} conexões
                                <a
                                  href="#"
                                  onClick={() =>
                                    getLegislativeConnecDetails(
                                      item.id_x,
                                      item.id_y,
                                      item.nome_x,
                                      item.nome_y
                                    )
                                  }
                                  style={{
                                    fontSize: "10px",
                                    textDecoration: "none",
                                  }}
                                >
                                  {""} Ver Mais
                                </a>
                              </p>
                            ) : (
                              <></>
                            )}
                          </Popup>
                          <Tooltip
                            direction="bottom"
                            offset={[0, 20]}
                            opacity={1}
                            permanent
                          >
                            {item.nome_y}
                          </Tooltip>
                        </CircleMarker>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                );
              })
            ) : (
              <></>
            )}
          </MapContainer>
        ) : (
          <div
            className="normalspinner"
            style={{
              width: "100%",
              zIndex: 9999,
              alignItems: "center",
              textAlign: "center",
              marginTop: "150px",
            }}
          >
            <Spinner animation="border"></Spinner>
          </div>
        )}
        <Modal open={showModal} onClose={closeModal}>
          <Box sx={{ ...styleModal, ...sizeStyle }}>
            <div
              style={{
                width: "100%",
                overflowY: "scroll",
                overflowX: "hidden",
                paddingRight: "16px",
              }}
            >
              {modalContent}
            </div>
          </Box>
        </Modal>
      </div>
      <MapMessage />
    </>
  );
}

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  pr: 2,
  borderRadius: "10px",
  display: "flex",
};
