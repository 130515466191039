import React, { Profiler, useState } from "react";
import { Grid, Typography, InputAdornment, CircularProgress, Chip } from "@mui/material";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import DelayedInput from "../../../ReuseableComponents/DelayedInput/DelayedInput";
import InstitutionCard from "./InstitutionCard";
import { FaSearch } from "react-icons/fa";
import { usePageHeader } from "../../../MasterPage/MasterPage";
import { AppContext } from "../../../App";
import {
  useInfiniteCamara,
  fetchCamara,
  useFetchCamara,
} from "../../../hooks/useFetchCamara";
import { formatReferenciasDeputados, getUFValida } from "../../utils";
import { useFetchBackend } from "../../../hooks/useFetchBackend";
import { v } from "../../../utils";
import ScrollButton from "../../../MasterPage/scrollToTop/ScrollButton";
import { Link } from "react-router-dom";
const InputProps = (loading) => ({
  startAdornment: (
    <InputAdornment position="start">
      <FaSearch />
    </InputAdornment>
  ),
});

const gridMobileProps = (isMobile) => ({
  columns: isMobile ? 6 : 12,
  sx: {
    overflowY: "auto",
    maxHeight: isMobile ? "600px" : "450px",
    mt: isMobile ? 2 : 1,
    pr: 4,
    pb: 2,
    pl: 2,
  },
});

const itemsPorPagina = 10;

export default function Institutions() {
  const [loading, setLoading] = useState("");

  usePageHeader("Stakeholders - Instituições");

  const { isMobile, institutionData } = React.useContext(AppContext);
  const [input, setInput] = React.useState("");
  const [referencias, setReferencias] = React.useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const [keyword, setKeyword] = useState("");
  const [recentInstitution, setRecentInstitution] = React.useState([]);

  React.useEffect(() => {
    tryFunction();
  }, []);

  const tryFunction = () => {
    let recentInstitutionLocal = JSON.parse(
      localStorage.getItem("recent-institution-new")
    ) || { profiles: {}, order: [] };
    let recentInstitutionObjs = recentInstitutionLocal.order.map(
      (id) => recentInstitutionLocal.profiles[id]
    );
    recentInstitutionObjs = recentInstitutionObjs.filter((item) => item !== undefined);
    setRecentInstitution(recentInstitutionObjs);
  };

  const mobileClass = isMobile ? "mobile" : "";
  return (
    <>
      <Typography className="stakeholder-heading">
        Busque as principais instituições de interesse da Bayer
      </Typography>

      <div className="d-flex gap-2 mt-3">
        <DelayedInput
          InputProps={InputProps(loading)}
          fullWidth
          value={keyword}
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
          label="Pesquisar"
          size="small"
          InputComponent={TextField}
          delay={300}
        />
      </div>
      <div style={{ marginTop: "1%" }}>
        <b> Visitas recentes :&nbsp; </b>
        {recentInstitution.length > 0 ?
          recentInstitution.map((profile) => {
            let name;
            if (profile.institution_title) {
              name = profile.institution_title;
            } else {
              return;
            }
            return (
              <Link to={`/stakeholders-instituicoes/${profile.institution_id}`}>
                <Chip
                  sx={{ mr: 1, cursor: "pointer", mb: 1 }}
                  label={name}
                  color="primary"
                  size="small"
                />
              </Link>
            );
          })
          : "Nenhuma visita recente"}
      </div>
      <div>
        <Grid
          container
          spacing={2}
          {...gridMobileProps(isMobile)}
          style={{ maxHeight: "57vh" }}
        >
          <InstitutionCard keyword={keyword} />
        </Grid>
      </div>
      <ScrollButton />
    </>
  );
}
