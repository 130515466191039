import React from "react";
import { Box, Grid } from "@mui/material";
import moment from "moment";

export default function DadosCard({ faseEvento, titulo, tipoDiscurso, sumario, transcricao }) {  
          return (
              <Grid my={2} container columnSpacing={4}>
                <Grid item style={{maxWidth: "500px"}}>
                  <b>{tipoDiscurso}</b>
                  <p>{sumario}</p>
                  <p>{transcricao}</p>
                </Grid>
              </Grid>
          );
}


