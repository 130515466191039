export const FavouriteData = [
    {
        id: 1,
        title: "News",
        fav_count: 5,
        mstr_info_type_name: "fav_news"
    },
    {
        id: 1,
        title: "Stakeholders",
        fav_count: 5,
        mstr_info_type_name: "fav_stakeholder"
    },
    {
        id: 1,
        title: "Agenda",
        fav_count: 5,
        mstr_info_type_name: "fav_agenda"
    },
    {
        iid: 1,
        title: "Social Media",
        fav_count: 5,
        mstr_info_type_name: "fav_socialmedia"
    },
]