import React, { useState, useEffect } from "react";
import { KeyboardArrowUpTwoTone } from "@mui/icons-material";
import { ClassNames } from "@emotion/react";
import { Button } from "@mui/material";
import "./css/ScrollToTop.css";

export const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="footer" style={{ marginLeft: "50px" }}>
      <a title="Scroll to top" className="scroll-to-top" onClick={scrollToTop}>
        <KeyboardArrowUpTwoTone
          aria-hidden="true"
          style={{
            width: "20px",
            height: "20px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            cursor: "pointer",
            border: "1px solid #fff",
            backgroundColor: "var(--bayer-dark-green)",
            color: "#fff",
            borderRadius: "10px",
            zIndex: 1,
          }}
          value="Scroll to top"
        />
      </a>
    </div>
  );
};
