import React, { useState, useEffect } from "react";
import { Grid, Paper, Button, Chip } from "@mui/material";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./card.css";
import "./ministerCard.css";
import { AppContext } from "../App";
import EmptyProfile from "../image/EmptyProfile.png";
import axios from "axios";

function MinisterCard({ keyword, ministerSelectParams }) {
  const { isMobile, currIdToken, expiresOn, renewSilentToken, ministerData } =
    React.useContext(AppContext);
  const mobileClass = isMobile ? "mobile" : "";
  const [imageErrorFlag, setImageErrorFlag] = React.useState(false);
  const [recentMinisterProfiles, setRecentMinisterProfiles] = React.useState([]);
  const handleImageError = () => setImageErrorFlag(true);
  const [recentProfiles, setRecentProfiles] = React.useState({ profiles: {}, order: [] });
  const [count, setCount] = React.useState(10);

  const LoadMore = () => {
    setCount(count + 10);
  };
  const handleRecent = (item) => {
    recentProfiles["profiles"][item.minister_id] = item;
    recentProfiles["order"] = recentProfiles["order"].filter(
      (arrayItem) => arrayItem !== item.minister_id
    );
    recentProfiles["order"].push(item.minister_id);
    recentProfiles["order"] = recentProfiles["order"].slice(-5);
    localStorage.setItem("recent-minister", JSON.stringify(recentProfiles));
    setRecentProfiles(recentProfiles);
  };

  useEffect(() => {
    const recentProfilesLocal =
      JSON.parse(localStorage.getItem("recent-minister")) || recentProfiles;
    setRecentProfiles(recentProfilesLocal);
  }, []);

  const contactDetails = (text) => {
    let contact = text.split(",\n");
    return contact[0];
  };

  const contactDetailsAll = (text) => {
    let contact = text.split(",\n");
    return contact[1];
  };
  let filterData = [...ministerData];
  if (keyword !== "" && ministerData) {
    filterData = ministerData.filter((data) => {
      var lower_title =
        ministerSelectParams == "Nome"
          ? data.minister_name.toLowerCase()
          : data.minister_desigination.toLowerCase();
      var lower_searchKey = keyword.toLowerCase();
      var normal_lower_title=lower_title.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      var normal_lower_searchKey=lower_searchKey.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      return normal_lower_title.search(normal_lower_searchKey) != -1;
    });
  }

  return (
    <>
      {filterData.map((item, index) => {
        return (
          <Grid item xs={6}>
            <Paper elevation={12} className={`minister-card-grid ${mobileClass}`.trim()}>
              <div className="minister-card-img">
                {!imageErrorFlag && (
                  <img src={item.minister_img} alt="img" onError={handleImageError} />
                )}
                {imageErrorFlag && <img src={EmptyProfile} alt="img" />}
              </div>
              <div className="minister-card-profile">
                <h5 title={item.minister_name} style={{ marginBottom: "5px" }}>
                  {item.minister_name}
                </h5>
                <small style={{ height: "20px" }}>{item.minister_desigination}</small>
              </div>
              <div className={`minister-card-interaction ${mobileClass}`.trim()}>
                <div className="card-link-button">
                  <Link to={`/stakeholders-pessoas/minister-${item.minister_id}`}>
                    <Button
                      onClick={() => handleRecent(item)}
                      variant="contained"
                      style={{ backgroundColor: "var(--bayer-blue)" }}
                    >
                      Ver Mais
                    </Button>
                  </Link>
                </div>
              </div>
            </Paper>
          </Grid>
        );
      })}
    </>
  );
}

export default MinisterCard;
