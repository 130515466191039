import { AccessTime, Close } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import React from "react";
import { AppContext } from "../../App";
import moment from "moment";

export default function StakeholderEventDetails({ item, close }) {
  const { event } = React.useContext(AppContext);
  const displayTime = (text) => {
    let finalTime = text.split("T");
    return finalTime[1];
  };

  const displayDesc = (text) => {
    let guestList = text.split("CONVIDADOS: ");
    let paragraph = text
      .replace(/convidados:/i, "convidados:\n\n")
      .replace("CONVIDADOS:", "CONVIDADOS:\n\n")
      .replace(/Convidados:/i, "Convidados\n\n");
    return paragraph;
  };
  const displayGuest = (text) => {
    let guestList = text.split("CONVIDADOS: ");
    let paragraph = text
      .replace(/convidados:/i, "convidados:\n")
      .replace("CONVIDADOS:", "CONVIDADOS:\n")
      .replace(/Convidados:/i, "Convidados\n");
    if (guestList[1] == undefined) {
      guestList = text.split("Convidados:");
    }
    return paragraph;
  };
  // To add styling to descricao of event
  const UnStyledDescription = item.descricao;
  const StyledDescription = UnStyledDescription.replaceAll(
    "<A",
    `<A target="_blank" style="font-weight:bold"`
  );
  const StyledDescriptionLink = StyledDescription.replaceAll(
    "<a",
    `<a target="_blank" style="font-weight:bold"`
  );
  const Description = StyledDescriptionLink.replaceAll(";", ".<br/>");
  const { isMobile } = React.useContext(AppContext);
  const mobileClass = isMobile ? "mobile" : "";

  const eventParticipentsList = (text) => {
    let paragraph = text;
    paragraph = paragraph
      .replace(/\n+/gm, "\n")
      .replace(/\t+/gm, " ")
      .replace(/\r\n\r\n+/gm, "\n")
      .split("\n");
    return paragraph;
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end", height: "20px" }}>
        <Close
          style={{
            fontSize: "17px",
            cursor: "pointer",
            position: "fixed",
            marginRight: "-27px",
            marginTop: "-20px",
          }}
          onClick={close}
        />
      </div>
      <h3 style={{ color: "var(--bayer-md-blue)" }}>
        Detalhes do Evento das Partes Interessadas
      </h3>
      {!isMobile ? (
        <Grid my={2} container columnSpacing={2}>
          <Grid item style={{ mixWidth: "400px" }}>
            <b style={{ color: "var(--bayer-md-green)" }}>
              Hora do evento:
              <br />
              Título do evento:
              <br />
              Descrição do Evento:
              <br />
            </b>
          </Grid>
          <Grid item style={{ maxWidth: "700px" }}>
            <Typography>
              {moment(item.dataHoraInicio).format("DD/MM/YYYY")}
              {" - "}
              {item.dataHoraInicio.length > 0 ? displayTime(item.dataHoraInicio) : ""}
              <br />
              {item.descricaoTipo}
              <br />
              <Typography>
                {eventParticipentsList(Description).map((data, index) => {
                  return (
                    <div>
                      <div dangerouslySetInnerHTML={{ __html: data }} />
                      <br />
                    </div>
                  );
                })}
              </Typography>
              <br />
              <br />
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid my={2} container columnSpacing={2}>
          <Grid item>
            <b style={{ color: "var(--bayer-md-green)" }}>Hora do evento :</b>
            <Typography>
              {moment(item.dataHoraInicio).format("DD/MM/YYYY")}
              {item.dataHoraInicio.length > 0 ? displayTime(item.dataHoraInicio) : ""}
            </Typography>
            <br />
            <b style={{ color: "var(--bayer-md-green)" }}> Título do evento :</b>
            <Typography>{item.descricaoTipo}</Typography>
            <br />
            <b style={{ color: "var(--bayer-md-green)" }}> Descrição do Evento : </b>
            <Typography dangerouslySetInnerHTML={{ __html: Description }} />
            <br />
          </Grid>
        </Grid>
      )}
    </div>
  );
}
