import _ from "lodash";

export const formatReferenciasDeputados = (r) => {
  return {
    codTipoProfissao: r.codTipoProfissao.reduce((acc, { cod, ...item }) => {
      return { ...acc, [cod]: item };
    }, {}),
    siglaUF: r.siglaUF.reduce((acc, { sigla, ...item }) => {
      return { ...acc, [sigla]: item };
    }, {}),
    siglaSituacao: r.siglaSituacao.reduce((acc, { sigla, ...item }) => {
      return { ...acc, [sigla]: item };
    }, {}),
  };
};

export function getUFValida(referencias, value) {
  //make uppercase and removes accents
  const val = _.deburr(value.toUpperCase());
  let response = "@";
  Object.entries(referencias.siglaUF).every(([siglaKey, siglaValue]) => {
    if (val === siglaKey) {
      response = siglaKey;
      return false;
    }
    const estadoSemAcento = _.deburr(siglaValue.nome);
    if (estadoSemAcento.includes(val)) {
      response = response !== "@" ? response : siglaKey;
    }
    return true;
  });
  return response;
}
