import React, { useState, useEffect, Suspense } from "react";
import {
  Typography,
  InputAdornment,
  CircularProgress,
  Paper,
  Divider,
  Box,
  Link,
} from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { AppContext } from "../../App";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import BayerLogo from "../../image/Logo_BayerCross_Black.jpeg";
import { FacebookData } from "./FacebookData";
import "../CSS/style.css";
import { AccessTime, Square, StarBorder, StarRounded } from "@mui/icons-material";
import axios from "axios";
import { useInfiniteCamara, useInfinitePosts } from "../../hooks/useLazyLoad";
import moment from "moment";
import FavouriteStar from "../../ReuseableComponents/FavouriteStar";
import ErrorBoundary from "../../ReuseableComponents/ErrorBoundary";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "react-bootstrap";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const FETCH_DELAY_MS = 200;

const InputProps = (loading) => ({
  startAdornment: (
    <InputAdornment position="start">
      {loading ? (
        <CircularProgress
          style={{ padding: 0, height: "16px", width: "16px", margin: 0 }}
        />
      ) : (
        <FaSearch />
      )}
    </InputAdornment>
  ),
});

const gridMobileProps = (isMobile) => ({
  columns: isMobile ? 6 : 12,
  sx: {
    overflowY: "auto",
    maxHeight: isMobile ? "600px" : "450px",
    mt: isMobile ? 2 : 1,
    pr: 4,
    pb: 2,
    pl: 2,
  },
});

const itemsPorPagina = 10;

const initialParams = {
  itens: itemsPorPagina,
  pagina: 1,
  ordem: "ASC",
  ordenarPor: "nome",
};
const cardWidth = 400;

const Item = styled(Paper)(({ theme }) => ({
  minWidth: window.innerWidth <= 768 ? 0 : cardWidth,
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export default function SocialMediaTwitter({ tweets }) {
  const [expanded, setExpanded] = React.useState(false);
  const [idToBeRemoved, setIdToBeRemoved] = React.useState("");

  const {
    currIdToken,
    expiresOn,
    renewSilentToken,
    currUserId,
    setFavData,
    favData,
    getFavListAPI,
  } = React.useContext(AppContext);

  const addFavourites = async (
    e,
    twitterId,
    twitterText,
    twitterAuthor,
    twitterCreatedAt,
    twitterLang,
    twitterGeo,
    twitterUrl
  ) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    e.stopPropagation();
    const params = {
      text: twitterText,
      author: twitterAuthor,
      tweet_id: twitterId.toString(),
      created_at: twitterCreatedAt,
      lang: twitterLang,
      geo: twitterGeo,
      twitter_url: twitterUrl,
      user_id: currUserId,
      type: "fav_socialmedia_twitter",
    };

    await axios
      .post(`${process.env.REACT_APP_API_KEY}/add-twitter-fav`, params, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        setFavData((f) =>
          f.concat({
            twitter_id: twitterId,
            title: twitterText,
            date: twitterCreatedAt,
            type: "fav_socialmedia_twitter",
          })
        );
        getFavListAPI();
      })
      .catch(renewSilentToken);
  };

  const removeFavourites = async (e, twitterId) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    e.stopPropagation();
    const filteredArray = favData.filter(({ id, type, twitter_id }) => {
      return (
        type === "fav_socialmedia_twitter" &&
        twitterId.toString() === twitter_id.toString()
      );
    });
    const itemTobeRemoved =
      filteredArray &&
      Array.isArray(filteredArray) &&
      filteredArray.length &&
      filteredArray[0].id
        ? filteredArray[0].id.toString()
        : "";
    if (itemTobeRemoved) {
      const params = {
        user_id: currUserId,
        type: "fav_socialmedia_twitter",
        favourite_ids: [itemTobeRemoved],
      };

      await axios
        .post(`${process.env.REACT_APP_API_KEY}/favourites/remove-favourite`, params, {
          headers: { Authorization: "Bearer " + currIdToken },
        })
        .then((response) => {
          getFavListAPI();
          setFavData((f) => {
            return f.filter(({ id }) => id !== itemTobeRemoved);
          });
        })
        .catch(renewSilentToken);
    }
  };

  const [count, setCount] = useState({
    prev: 0,
    next: 10,
  });

  const [hasMore, setHasMore] = useState(true);
  const [current, setCurrent] = useState(tweets.slice(count.prev, count.next));
  const getMoreData = () => {
    if (current.length === tweets.length) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      setCurrent(current.concat(tweets.slice(count.prev + 10, count.next + 10)));
    }, 2000);
    setCount((prevState) => ({
      prev: prevState.prev + 10,
      next: prevState.next + 10,
    }));
  };

  const displayTime = (text) => {
    let time = text.split(",");
    return time[1];
  };

  return (
    <div>
      <Item>
        <div className="CardHeader" style={{ backgroundColor: "var(--bayer-blue)" }}>
          Twitter
        </div>
        <ErrorBoundary>
          <InfiniteScroll
            dataLength={current.length}
            next={getMoreData}
            hasMore={hasMore}
            loader={""}
          >
            <Card variant="outlined" sx={{ overflowY: "auto", height: 345 }}>
              {tweets.length > 0 ? (
                tweets.map((tweets) => {
                  return (
                    <Suspense fallback={<div>Loading...</div>}>
                      <Box p={2}>
                        <Square
                          style={{ padding: "4px" }}
                          className="home-btn-details-square"
                        />
                        <Link
                          href={`https://twitter.com/${tweets.twitter_name}`}
                          target="_blank"
                        >
                          {tweets.author}
                        </Link>
                        {<br />}
                        <AccessTime
                          style={{ padding: "4px", margin: "0" }}
                          className="home-btn-details-clock"
                        />
                        {moment(tweets.created_at).format("DD/MM/YYYY")} -{" "}
                        {displayTime(tweets.created_at)}
                        <FavouriteStar
                          addFav={addFavourites}
                          removeFav={removeFavourites}
                          selectedId={tweets.tweet_id}
                          text={tweets.text}
                          author={tweets.author}
                          createdAt={tweets.created_at}
                          lang={tweets.lang}
                          geo={tweets.geo}
                          twitterUrl={tweets.twitter_url}
                        />
                        <Link
                          href={tweets.twitter_url}
                          target="_blank"
                          style={{ textDecoration: "none", color: "#3c3c3c" }}
                        >
                          <CardContent>
                            <Typography variant="body2">{tweets.text}</Typography>
                          </CardContent>
                        </Link>
                        <Divider />
                      </Box>
                    </Suspense>
                  );
                })
              ) : (
                <div style={{  textAlign: "center",marginTop: "100px",marginLeft: "10px", marginRight:"10px"}}>
                  {/* <b>Resultados não disponiveis para busca realizada</b> */}
                  <b>Funcionalidade temporariamente indisponível dadas às recentes mudanças e limitações de views e posts aplicadas pelo Twitter</b>
                </div>
              )}
            </Card>
          </InfiniteScroll>
        </ErrorBoundary>
      </Item>
    </div>
  );
}
