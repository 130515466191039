import React from "react";
import {
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  ListItemIcon,
  ListItemButton,
  Collapse,
  Modal,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AIALogo from "../ReuseableComponents/AIALogo/AIALogo";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  MoreHoriz,
  MoveToInbox as InboxIcon,
} from "@mui/icons-material";
import BayerLogo from "../image/bayer-cross-white.png";
import { useContext } from "react";
import { AppContext } from "../App";
import { Link } from "react-router-dom";
import * as RiIcons from "react-icons/ri";
import "./drawer.css";
import { useMsal } from "@azure/msal-react";
import Restricted from "../ReuseableComponents/Restricted";
import RestrictedImg from "../image/Restricted_Img.png";
import Notification from "../ReuseableComponents/Notification";

const drawerWidth = 240;
const thinDrawerWidth = 60;
const backgroundColor = "var(--bayer-md-green)";
const mobileDrawerHeight = "100vh";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  pr: 2,
  borderRadius: "10px",
  display: "flex",
};

export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, isMobile }) => ({
  flexGrow: 1,
  paddingRight: "30px",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(!isMobile && { marginLeft: `-${drawerWidth - 110}px` }),
  ...(isMobile && { paddingLeft: `30px` }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "50px",
  }),
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const DrawerBody = ({ children, open, onRequestClose }) => {
  const { isMobile } = useContext(AppContext);

  return (
    <Drawer
      sx={{
        width: isMobile ? "100vw" : drawerWidth,
        flexShrink: 0,
        ...(isMobile && open && { height: mobileDrawerHeight }),
        "& .MuiDrawer-paper": {
          width: isMobile ? "100vw" : drawerWidth,
          ...(isMobile && open && { height: mobileDrawerHeight }),
          boxSizing: "border-box",
          background: backgroundColor,
          color: "#fff",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
        },
      }}
      variant="persistent"
      anchor={isMobile ? "top" : "left"}
      open={open}
    >
      <DrawerHeader>
        <div>
          <img style={{ width: `50px`, padding: "0.25em" }} src={BayerLogo} alt="bayer" />
        </div>
        <AIALogo />
        <IconButton onClick={onRequestClose} style={{ color: "#fff" }}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      {children}
    </Drawer>
  );
};

export const ThinDrawer = ({ onRequestOpen, open }) => {
  const { isMobile } = useContext(AppContext);
  return (
    <div
      style={{
        display: "flex",
        width: isMobile ? "100%" : `${thinDrawerWidth}px`,
        justifyContent: "flex-start",
        alignItems: "center",
        background: backgroundColor,
        color: "white",
        flexDirection: isMobile ? "row" : "column",
        position: "fixed",
        ...(!isMobile && { height: "100vh" }),
        zIndex: isMobile ? "9999" : "",
      }}
    >
      <IconButton
        style={{ marginTop: "10px" }}
        color="inherit"
        onClick={onRequestOpen}
        sx={{ ...(open && { display: "none" }) }}
      >
        <MenuIcon />
      </IconButton>
      <div>
        <img
          style={{ width: `${thinDrawerWidth - 10}px`, padding: ".25em" }}
          src={BayerLogo}
          alt="bayer"
        />
      </div>
      <div>
        <AIALogo small />
      </div>
      <div style={{ marginLeft: "auto", paddingRight: "5%" }}>
        {isMobile ? <Notification /> : ""}
      </div>
    </div>
  );
};

export const MasterPageContainer = ({ children }) => {
  const { isMobile } = useContext(AppContext);
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "95vh",
        ...(isMobile && { flexDirection: "column" }),
      }}
    >
      <CssBaseline />
      {children}
    </Box>
  );
};

export const LoggedUser = () => {
  const { isMobile } = useContext(AppContext);
  const sizeStyle = isMobile
    ? { width: "90vw", height: "80vh" }
    : { width: 1000, height: 500 };
  const [showModal, setShowModal] = React.useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const { profilePicture, account, graphInfo } = useContext(AppContext);
  return (
    <>
      <List style={{ marginTop: "auto" }}>
        <ListItem>
          <ListItemText>
            <Typography
              style={{
                fontSize: "10px",
                color: "#fff",
                cursor: "pointer",
                paddingLeft: "57px",
              }}
              onClick={openModal}
            >
              Declaração de Privacidade
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            {profilePicture && <Avatar src={profilePicture} />}
          </ListItemAvatar>
          <ListItemText>
            <Typography style={{ fontSize: "10px", width: "max-content", color: "#fff" }}>
              {account?.name}
            </Typography>
            <Typography style={{ fontSize: "10px", color: "#fff" }}>
              {graphInfo?.mail}
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
      <Modal open={showModal} onClose={closeModal}>
        <Box sx={{ ...styleModal, ...sizeStyle }}>
          <div
            style={{
              width: "100%",
              overflowY: "scroll",
              overflowX: "hidden",
              paddingRight: "16px",
            }}
          >
            <Restricted close={closeModal} />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export const LogoutButton = ({ onClick }) => {
  const { instance } = useMsal();
  return (
    <List>
      {["Sair"].map((text, index) => (
        <ListItem
          button
          key={text}
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm("Tem certeza que deseja sair?")) return;
            instance.logout();
          }}
          className="drawer-item"
        >
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      ))}
    </List>
  );
};

const DrawerLink = ({ href, children }) => (
  <Link
    component={Link}
    to={href}
    style={{
      textDecoration: "none",
    }}
  >
    {children}
  </Link>
);

export const DrawerItemLinks = ({ sidebar, onClickMobile }) => (
  <DrawerLink href={sidebar.path}>
    <ListItemButton onClick={onClickMobile} className="drawer-item">
      <ListItemIcon>{sidebar.icon}</ListItemIcon>
      <ListItemText>{sidebar.title}</ListItemText>
    </ListItemButton>
  </DrawerLink>
);

export const DrawerItemLinksWithSubnav = ({
  sidebar,
  openSubnav,
  toggleSubnav,
  onClickMobile,
}) => (
  <>
    <ListItemButton onClick={toggleSubnav} className="drawer-item">
      <ListItemIcon>{sidebar.icon}</ListItemIcon>
      <ListItemText>{sidebar.title}</ListItemText>
      <div>
        {!openSubnav && <RiIcons.RiArrowDownSFill />}
        {openSubnav && <RiIcons.RiArrowUpSFill />}
      </div>
    </ListItemButton>
    <Collapse in={openSubnav} timeout="auto" unmountOnExit className="drawer-item">
      <List component="div" disablePadding>
        {sidebar?.subNav?.map((item) => (
          <DrawerLink href={item.path}>
            <ListItemButton sx={{ pl: 9 }} onClick={onClickMobile}>
              <ListItemText>{item.title}</ListItemText>
            </ListItemButton>
          </DrawerLink>
        ))}
      </List>
    </Collapse>
  </>
);
