import { forwardRef } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import * as RiIcons from "react-icons/ri";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from "date-fns/locale";

registerLocale("ptBR", ptBR);

const TimePicker = forwardRef(({ value, onClick }, ref) => {
  return (
    <button type="button" onClick={onClick} ref={ref}>
      <i className="far fa-clock"></i>
      <span style={{ fontSize: "1rem", flex: "1" }}>Alterar</span>
      <i className="far fa-clock"></i>
    </button>
  );
});

export default function DatePicker({
  date,
  changeDate,
  showMonthYearPicker,
  timeIcon,
  ...props
}) {
  const onPickDate = (chosenDate) => {
    showMonthYearPicker &&
      changeDate(chosenDate.getFullYear(), chosenDate.getMonth() + 1);
    !showMonthYearPicker && changeDate(chosenDate);
  };
  return (
    <ReactDatePicker
      timeCaption="Hora"
      locale="ptBR"
      {...props}
      showMonthYearPicker={showMonthYearPicker}
      selected={date}
      onChange={onPickDate}
      customInput={timeIcon ? <TimePicker locale="ptBR" /> : <RiIcons.RiArrowDownSFill />}
    />
  );
}
