import React, { useState, useEffect } from "react";
import * as Scroll from "react-scroll";
import { Grid, Paper, Button, Box, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import "../../../Institution/CSS/institutionCard.css";
import { AppContext } from "../../../../App";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Avatar, Card, Divider } from "@mui/material";
import { AccessTime, Square, StarBorder, StarRounded } from "@mui/icons-material";
import { Spinner } from "react-bootstrap";
import InstitutionProfileNewsDetails from "../../../Institution/JS/InstitutionTabs/InstitutionProfileNewsDetails";
import StakeholderProfileNewsDetails from "../../Noticias/StakeholderProfileNewsDetails";
import MinisterMessage from "./MinisterMessage";

function MinisterNoticias({ ministerNews }) {
  let scroll = Scroll.animateScroll;
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  const {
    isMobile,
    currIdToken,
    expiresOn,
    renewSilentToken,
    institutionNoticias,
    institutionData,
  } = React.useContext(AppContext);
  const sizeStyle = isMobile
    ? { width: "70vw", height: "80vh" }
    : { width: 1000, height: 500 };
  const [loading, setLoading] = useState(false);
  const [institutionNews, setInstitutionNews] = useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  const [newsFlag, setNewsFlag] = useState(true);
  const [handleError, setHandleError] = useState();
  const [googleNews, setGoogleNews] = useState([]);

  const openModal = (item) => {
    setModalContent(<StakeholderProfileNewsDetails item={item} close={closeModal} />);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {!loading ? (
        <div style={{marginBottom:"40px"}}>
          {ministerNews.length > 0
            ? ministerNews.map((item) => {
                return (
                  <div
                    style={{
                      paddingRight: "12px",
                    }}
                    onClick={() => openModal(item)}
                  >
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <div style={{ marginLeft: "10px", width: "100%" }}>
                          <Typography
                            className="modal-title"
                            style={{ fontWeight: "bold" }}
                          >
                            {item.title}{" "}
                          </Typography>
                          <Typography
                            className="modal-description"
                            dangerouslySetInnerHTML={{
                              __html: item.desc,
                            }}
                          ></Typography>
                          <div className="modal-author-time">
                            <div className="author">
                              <Square
                                style={{ padding: "4px" }}
                                className="home-btn-details-square"
                              />{" "}
                              {item.media}{" "}
                            </div>
                            <div className="time" style={{ textTransform: "capitalize" }}>
                              <AccessTime
                                style={{ padding: "4px" }}
                                className="home-btn-details-clock"
                              />{" "}
                              {item.date}
                            </div>
                          </div>
                        </div>
                        <Divider className="border" />
                      </div>
                      <Divider />
                    </>
                  </div>
                );
              })
            : "Não foi possível buscar notícias no momento. Tente novamente depois de algum tempo."}
          <Modal open={showModal} onClose={closeModal}>
            <Box sx={{ ...styleModal, ...sizeStyle }}>
              <div
                style={{
                  width: "100%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  paddingRight: "16px",
                }}
              >
                {modalContent}
              </div>
            </Box>
          </Modal>
        </div>
      ) : (
        <div
          className="normalspinner"
          style={{
            width: "100%",
            zIndex: 9999,
            alignItems: "center",
            textAlign: "center",
            marginTop: "150px",
          }}
        >
          <Spinner animation="border"></Spinner>
        </div>
      )}
      <MinisterMessage/>
    </>
  );
}
const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  pr: 2,
  borderRadius: "10px",
  display: "flex",
};

export default withRouter(MinisterNoticias);
