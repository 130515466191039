import "./AIALogo.css";
export default function AIALogo({ small, ...props }) {
  const smallClass = small? 'small' : '';
  return (
    <div {...props} className={`AIA-container ${smallClass}`.trim()}>
      <div></div>
      <div></div>
      <div></div>
      <h3>AIA</h3>
    </div>
  );
}
