export const FacebookData = [
  {
    news_id: 1,
    news_title:
      "Fundão eleitoral encerra ano do triunfo da política sobre a agenda de Guedes",
    datasource_id: 1,
    news_date: "2022-02-01T16:43:44.545",
    news_image:
      "https://mcusercontent.com/4911ce1e520f5bf26dd891c79/images/2f466d3f-476e-e73f-4efa-dc9517805eb2.jpg",
    news_text:
      " Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1",
  },
  {
    news_id: 2,
    news_title:
      "Fundão eleitoral encerra ano do triunfo da política sobre a agenda de Guedes",
    datasource_id: 1,
    news_date: "2022-02-01T16:43:44.545",
    news_image:
      "https://mcusercontent.com/4911ce1e520f5bf26dd891c79/images/2f466d3f-476e-e73f-4efa-dc9517805eb2.jpg",
    news_text:
      " Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1 Facebook Data 1",
  },
];
