import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { usePageHeader } from "../../MasterPage/MasterPage";
import { Close, EditOutlined, StarBorder, StarRounded } from "@mui/icons-material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";
import favpatrilogo from "../../image/Fav_Patri.png";
import favjotalogo from "../../image/Fav_Jota.png";
import favtwitterlogo from "../../image/Fav_Twitter_2.png";
import favyoutubelogo from "../../image/Fav_Youtube_2.jpg";
import axios from "axios";
import {
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  ListItemIcon,
  ListItemButton,
  Collapse,
  Card,
  Modal,
  Button,
} from "@mui/material";
import { FavouriteData } from "./FavouriteData";
import { useParams } from "react-router-dom";
import { AppContext } from "../../App";
import { withRouter } from "react-router-dom";
import * as moment from "moment";
import HomePostDetails from "../../Home/JS/HomePostDetails";
import { Spinner } from "react-bootstrap";
import HomePostDetailsBackFavs from "../../Home/JS/HomePostDetailsBackFavs";
import "../CSS/FavouriteItemList.css";
import yearsToMonths from "date-fns/yearsToMonths/index.js";

function FavouriteItemList(props) {
  usePageHeader("Favoritos");
  const params = props.match.params;
  const [loader, setLoader] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [sortOrder, setSortOrder] = React.useState("asc");
  const {
    isMobile,
    currIdToken,
    expiresOn,
    renewSilentToken,
    currUserId,
    favData,
    setFavData,
  } = React.useContext(AppContext);
  const [modalContent, setModalContent] = React.useState();
  const sizeStyle = isMobile
    ? { width: "90vw", height: "80vh" }
    : { width: 1000, height: 500 };
  useEffect(() => {
    if (!currIdToken) return;
    getFavListAPI();
  }, [currIdToken]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleClick = (favData) => {
    getFavItemDetails(favData.id);
  };

  const getFavListAPI = async () => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/favourites/get-all`,
        {
          user_id: currUserId, //loggedInUser ID
        },
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then((response) => {
        if (response && response.data && Array.isArray(response.data)) {
          const listItems = response.data.filter((dataItem) => {
            const item = dataItem.data ? JSON.parse(dataItem.data) : null;

            return item && item.type === params.mstr_info_type_name;
          });
          setData(listItems);
          setLoader(true);
        }
      })
      .catch(renewSilentToken);
  };

  const getFavItemDetails = async (favId) => {
    openModal();
    setModalContent("Loading...");
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/news/get-selected-information`,
        {
          type: params.mstr_info_type_name,
          ids: [favId.toString()],
        },
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then((response) => {
        if (response && response.data && Array.isArray(response.data)) {
          response.data.map((item, index) => {
            setModalContent(
              <HomePostDetailsBackFavs
                isMobile={isMobile}
                item={item}
                close={closeModal}
                getFavList={getFavListAPI}
                type={params.mstr_info_type_name}
              />
            );
          });
        }
      })
      .catch(renewSilentToken);
  };

  useEffect(() => {
    if (!currIdToken) return;
  }, [currIdToken]);

  const removeFavourites = async (favId) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }

    const reqParams = {
      user_id: currUserId, //loggedInUser ID
      type: params.mstr_info_type_name,
      favourite_ids: [favId.toString()],
    };

    await axios
      .post(`${process.env.REACT_APP_API_KEY}/favourites/remove-favourite`, reqParams, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        setFavData((f) => {
          return f.filter(({ id }) => id !== favId);
        });
        getFavListAPI();
      })
      .catch(renewSilentToken);
  };

  var ascOrderList = [];
  var descOrderList = [];

  String.prototype.replaceAt = function (index, replacement) {
    return (
      this.substring(0, index) + replacement + this.substring(index + replacement.length)
    );
  };

  const sortList = () => {
    var newOrderedList = [];
    for (let i = 0; i < data.length; i++) {
      var x = {};
      newOrderedList.push(JSON.parse(data[i].data));
    }
    ascOrderList = newOrderedList.slice(0);
    ascOrderList.sort(function (a, b) {
      var x = a.title.toLowerCase();
      var z = b.title.toLowerCase();
      if (x.charAt(0) === "é" || x.charAt(0) === "ê") {
        x = x.replaceAt(0, "e");
      }
      if (
        x.charAt(0) === "â" ||
        x.charAt(0) === "á" ||
        x.charAt(0) === "à" ||
        x.charAt(0) === "ã"
      ) {
        x = x.replaceAt(0, "a");
      }
      if (x.charAt(0) === "í") {
        x = x.replaceAt(0, "i");
      }
      if (x.charAt(0) === "ç") {
        x = x.replaceAt(0, "c");
      }
      if (x.charAt(0) === "ó" || x.charAt(0) === "ô" || x.charAt(0) === "õ") {
        x = x.replaceAt(0, "o");
      }
      if (x.charAt(0) === "ú" || x.charAt(0) === "u" || x.charAt(0) === "ũ") {
        x = x.replaceAt(0, "u");
      }
      if (z.charAt(0) === "é" || z.charAt(0) === "ê") {
        z = z.replaceAt(0, "e");
      }
      if (
        z.charAt(0) === "â" ||
        z.charAt(0) === "á" ||
        z.charAt(0) === "à" ||
        z.charAt(0) === "ã"
      ) {
        z = z.replaceAt(0, "a");
      }
      if (z.charAt(0) === "í") {
        z = z.replaceAt(0, "i");
      }
      if (z.charAt(0) === "ç") {
        z = z.replaceAt(0, "c");
      }
      if (z.charAt(0) === "ó" || z.charAt(0) === "ô" || z.charAt(0) === "õ") {
        z = z.replaceAt(0, "o");
      }
      if (z.charAt(0) === "ú" || z.charAt(0) === "u" || z.charAt(0) === "ũ") {
        z = z.replaceAt(0, "u");
      }
      return x < z ? -1 : x > z ? 1 : 0;
    });

    descOrderList = newOrderedList.slice(0);
    descOrderList
      .sort(function (a, b) {
        var x = a.title.toLowerCase();
        var z = b.title.toLowerCase();
        if (x.charAt(0) === "é" || x.charAt(0) === "ê") {
          x = x.replaceAt(0, "e");
        }
        if (z.charAt(0) === "é" || z.charAt(0) === "ê") {
          z = z.replaceAt(0, "e");
        }
        return x < z ? -1 : x > z ? 1 : 0;
      })
      .reverse();

    return sortOrder === "asc" ? ascOrderList : descOrderList;
  };

  const handleSortOrderClick = (e) => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    sortList(e);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p className="favourites-back-btn">
          <Link
            to="/favourites"
            style={{
              textDecoration: "none",
              display: "block",
              marginBottom: "10px",
            }}
          >
            <KeyboardBackspaceIcon /> Voltar
          </Link>
        </p>

        <p>
          <Button
            variant="contained"
            color="success"
            type="submit"
            style={{ marginBottom: "10px", textTransform: "Capitalize" }}
            key="submit"
            onClick={(e) => handleSortOrderClick(e)}
          >
            Classificar A - Z
          </Button>
        </p>
      </div>
      <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
        {sortList().map((favData, index) => {
          return (
            <React.Fragment key={`home-newslist-${index}`}>
              <Card className="favourite-list">
                <List style={{ marginTop: "auto" }}>
                  <ListItem>
                    <ListItemAvatar style={{ marginRight: "10px" }}>
                      <div
                        style={{
                          height: "65px",
                          width: "65px",
                        }}
                      >
                        <Card
                          style={{
                            backgroundImage:
                              favData.datasource_name === "JOTA"
                                ? `url('${favjotalogo}')`
                                : favData.datasource_name === "PATRI"
                                ? `url('${favpatrilogo}')`
                                : favData.type === "fav_socialmedia_twitter"
                                ? `url('${favtwitterlogo}')`
                                : favData.type === "fav_socialmedia_youtube"
                                ? `url('${favyoutubelogo}')`
                                : "",
                            width: "100%",
                            height: "100%",
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            overflowY: "hidden",
                            cursor: "pointer",
                          }}
                        ></Card>
                      </div>
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography
                        className="favourite-title"
                        style={{
                          fontSize: "14px",
                          cursor: "pointer",
                          fontWeight: "bold",
                          textTransform: "capitalize",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          width: "260px",
                          overflow: "hidden",
                        }}
                        onClick={() => handleClick(favData)}
                      >
                        {favData.title}
                      </Typography>
                      <Typography style={{ fontSize: "10px" }}>
                        {moment(favData.date).format("DD/MM/YYYY")}
                      </Typography>
                    </ListItemText>
                    <ListItemIcon>
                      <StarRounded
                        className="star"
                        style={{ cursor: "pointer" }}
                        key={favData.id}
                        onClick={() => removeFavourites(favData.id)}
                      />
                    </ListItemIcon>
                  </ListItem>
                </List>
              </Card>
            </React.Fragment>
          );
        })}
      </div>

      <Modal open={showModal} onClose={closeModal}>
        <Box sx={{ ...styleModal, ...sizeStyle }}>
          <div
            className="modal-style"
            style={{
              width: "100%",
              overflowY: "scroll",
              overflowX: "hidden",
              paddingRight: "16px",
            }}
          >
            {modalContent}
          </div>
        </Box>
      </Modal>
      {loader ? (
        getFavListAPI
      ) : (
        <div
          className="normalspinner"
          style={{
            width: "100%",
            alignItems: "center",
            textAlign: "center",
            marginTop: "80px",
          }}
        >
          <Spinner animation="border"></Spinner>
        </div>
      )}
    </div>
  );
}

export default withRouter(FavouriteItemList);

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  pr: 2,
  borderRadius: "10px",
  display: "flex",
};
