import React from "react";
import { Button, capitalize, Grid, InputBase, TextField, Tooltip } from "@mui/material";
import NoticiasPost from "./NoticiasPost";
import { usePageHeader } from "../../MasterPage/MasterPage";
import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";
import { AppContext } from "../../App";
import { makeStyles } from "@material-ui/styles";
import { ptBR } from "date-fns/locale";
import DatePicker from "react-datepicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { addDays } from "date-fns";
import "../CSS/Notice.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import InfoIcon from "@mui/icons-material/Info";
import { unstable_capitalize } from "@mui/utils";
import { Link } from "react-router-dom";
import GoogleNews from "./GoogleNews";
import { Spinner } from "react-bootstrap";
const itemsPorPagina = 10;
//To get last 7th date from today
const lastDate = new Date();
const seventhDate = lastDate.setDate(lastDate.getDate() - 7);
const seventhDateNew = moment(seventhDate).format("MM-DD-YYYY");

//To get today's date
const current = new Date();
const curDate = `${current.getFullYear()}-${current.getMonth() + 1}-${
  current.getDate() + 1
}`;

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  button: {
    backgroundColor: "var(--bayer-light-blue)",
    color: "#fff",
    marginRight: ".5rem",
    "&:hover": {
      backgroundColor: "var(--bayer-green)",
      color: "#fff",
    },
  },
});

export default function Noticias() {
  const [value, setValue] = React.useState([null, null]);
  const [startDate, endDate] = value;
  const [searchKey, setSearchKey] = React.useState();
  const [noticiasData, setNoticiasData] = React.useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [googleNews, setGoogleNews] = useState([]);
  const [newsFlag, setNewsFlag] = useState(false);
  const [disable, setDisable] = React.useState(false);
  const [stakeholderNews, setStakeholderNews] = useState([]);
  const [searchkey, setSearchkey] = useState("");
  const [btnColor, setBtnColor] = useState("var(--bayer-light-blue)");
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);

  const [noDataGoogleNewsMsg, setNoDataGoogleNewsMsg] = useState(false);
  const [noDataNewsMsg, setNoDataNewsMsg] = useState(false);
  const [initialStates, setInitialStates] = useState("");
  const { isMobile, currIdToken, expiresOn, renewSilentToken } =
    React.useContext(AppContext);
  const states = [
    {
      state: "AC",
      link: "http://saude.acre.gov.br/noticias/",
    },
    {
      state: "AL",
      link: "https://www.saude.al.gov.br/",
    },
    {
      state: "AP",
      link: "https://saude.portal.ap.gov.br/",
    },
    {
      state: "AM",
      link: "http://www.saude.am.gov.br/noticias.php",
    },
    {
      state: "BA",
      link: "http://www.saude.ba.gov.br/",
    },
    {
      state: "CE",
      link: "https://www.saude.ce.gov.br/",
    },
    {
      state: "DF",
      link: "https://www.saude.df.gov.br/",
    },
    {
      state: "ES",
      link: "https://saude.es.gov.br/",
    },
    {
      state: "GO",
      link: "https://www.saude.go.gov.br/",
    },
    {
      state: "MA",
      link: "https://www.saude.ma.gov.br/",
    },
    {
      state: "MT",
      link: "http://www.saude.mt.gov.br/",
    },
    {
      state: "MS",
      link: "https://www.saude.ms.gov.br/",
    },
    {
      state: "MG",
      link: "https://www.saude.mg.gov.br/",
    },
    {
      state: "PA",
      link: "http://www.saude.pa.gov.br/category/noticias/",
    },
    {
      state: "PB",
      link: "https://paraiba.pb.gov.br/diretas/saude",
    },
    {
      state: "PR",
      link: "https://www.saude.pr.gov.br/",
    },
    {
      state: "PE",
      link: "http://portal.saude.pe.gov.br/blog",
    },
    {
      state: "PI",
      link: "http://www.saude.pi.gov.br/",
    },
    {
      state: "RJ",
      link: "https://www.saude.rj.gov.br/",
    },
    {
      state: "RN",
      link: "http://www.saude.rn.gov.br/",
    },
    {
      state: "RS",
      link: "https://saude.rs.gov.br/inicial",
    },
    {
      state: "RO",
      link: "https://rondonia.ro.gov.br/sesau/noticias/",
    },
    {
      state: "RR",
      link: "https://www.saude.rr.gov.br/index.php/noticias-saude",
    },
    {
      state: "SC",
      link: "https://www.saude.sc.gov.br/index.php/noticias-geral",
    },
    {
      state: "SP",
      link: "http://portaldenoticias.saude.sp.gov.br/",
    },
    {
      state: "SE",
      link: "https://www.saude.se.gov.br/",
    },
    {
      state: "TO",
      link: "https://www.to.gov.br/saude",
    },
  ];
  usePageHeader("Notícias");
  function handleUrl(link) {
    window.open(link.target.value);
    state.clear();
  }

  useEffect(() => {
    if (!currIdToken) return;
    const firstDate = value?.[0] ?? seventhDateNew;
    const lastDate = value?.[1] ?? curDate;
    if (expiresOn < new Date()) {
      renewSilentToken();
    }
  }, [value, currIdToken]);
  const firstDate = value?.[0] ?? seventhDateNew;
  const lastDate = value?.[1] ?? curDate;
  const mapDays = ["Mon", "Tue", "Wed", "Thu", "Aak", "Sil", "Sk"];

  const [state, setState] = useState({
    startDate: addDays(new Date(), 1),
    endDate: null,
    key: "selection1",
  });
  const handleDisable = () => {
    btnColor === "var(--bayer-light-blue)"
      ? setBtnColor("var(--bayer-green)")
      : setBtnColor("var(--bayer-light-blue)");
    setDisable(!disable);
  };

  const handleSearch = (keyword) => {
    setLoading(true);
    let searchdata = keyword;
    setInitialStates("");
    if (!disable) {
      searchResult(searchdata);
    } else {
      getNews(searchdata);
    }
  };

  const getNews = async (search_key) => {
    setNewsFlag(true);
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/news/get-google-news/` + search_key, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        if (
          response &&
          response.data &&
          Array.isArray(response.data) &&
          response.data.length > 0
        ) {
          setGoogleNews(response.data);
          setNoDataGoogleNewsMsg(false);
          setLoading(false);
        } else {
          setGoogleNews([]);
          setNoDataGoogleNewsMsg(true);
          setLoading(false);
        }
      })
      .catch(renewSilentToken);
  };
  const searchResult = async (searchdata) => {
    setNewsFlag(false);
    let url1 = `${process.env.REACT_APP_API_KEY}/news/get-news`;
    axios
      .get(url1, {
        headers: { Authorization: "Bearer " + currIdToken },
        params: {
          start_date: moment(firstDate).format("YYYY-MM-DD"),
          end_date: moment(lastDate).format("YYYY-MM-DD"),
          keywords: searchdata,
        },
      })
      .then((response) => {
        if (
          response &&
          response.data &&
          Array.isArray(response.data) &&
          response.data.length > 0
        ) {
          setNoticiasData(response.data);
          setFilteredData(response.data);
          setNoDataNewsMsg(false);

          setLoading(false);
        } else {
          setFilteredData([]);
          setNoDataNewsMsg(true);
          setLoading(false);
        }
      })
      .catch(renewSilentToken);
  };

  const classes = useStyles();
  const calenderDate = new Date();
  const calenderDateNew = calenderDate.setDate(calenderDate.getDate() - 6);
  const mobileClass = isMobile ? "mobile" : "";
  const buttonMobileClass = isMobile ? "buttonMobile" : "";
  const desktopFormClass = "d-flex gap-2 mt-3";
  const mobileFormClass = "d-flex mobileForm gap-2 mt-3";

  return (
    <>
      <div style={{ width: "100%", paddingRight: !isMobile ? "50px" : "" }}>
        <h5
          className="noticias-heading"
          style={{ color: "var(--bayer-md-blue)", fontWeight: "bold" }}
        >
          Saiba as principais notícias do Brasil e do mundo
        </h5>
        <p></p>
        {!isMobile ? (
          <div className={`btnContainer my-3 ${buttonMobileClass}`}>
            <Button
              variant="contained"
              href="https://in.gov.br/leiturajornal?data"
              target="_blank"
              className={`${classes.button} ${
                buttonMobileClass.length > 0 ? "my-1" : ""
              }`}
            >
              Diário Oficial
            </Button>
            <Button
              variant="contained"
              className={`${classes.button} mx-2 ${
                buttonMobileClass.length > 0 ? "my-1" : ""
              }`}
              target="_blank"
              onClick={handleDisable}
              style={{ backgroundColor: btnColor }}
            >
              Mídias
            </Button>
            <Button
              variant="contained"
              href="http://conitec.gov.br/"
              target="_blank"
              className={`${classes.button} mx-2 ${
                buttonMobileClass.length > 0 ? "my-1" : ""
              }`}
            >
              CONITEC
            </Button>
            <Button
              variant="contained"
              className={`${classes.button} mx-2 ${
                buttonMobileClass.length > 0 ? "my-1" : ""
              }`}
              href="http://portaldenoticias.saude.sp.gov.br/"
              target="_blank"
            >
              Secretaria da Saúde
            </Button>
          </div>
        ) : (
          <Grid>
            <Grid item>
              <Button
                variant="contained"
                href="https://in.gov.br/leiturajornal?data"
                target="_blank"
                className={`${classes.button} ${
                  buttonMobileClass.length > 0 ? "my-1" : ""
                }`}
              >
                Diário Oficial
              </Button>

              <Button
                variant="contained"
                href="http://conitec.gov.br/"
                target="_blank"
                className={`${classes.button} ${
                  buttonMobileClass.length > 0 ? "my-1" : ""
                }`}
              >
                CONITEC
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                className={`${classes.button} ${
                  buttonMobileClass.length > 0 ? "my-1" : ""
                }`}
                target="_blank"
                onClick={handleDisable}
                style={{ backgroundColor: btnColor }}
              >
                Mídias
              </Button>
              <Button
                variant="contained"
                className={`${classes.button} ${
                  buttonMobileClass.length > 0 ? "my-1" : ""
                }`}
                href="http://portaldenoticias.saude.sp.gov.br/"
                target="_blank"
              >
                Secretaria da Saúde
              </Button>
            </Grid>
          </Grid>
        )}
        <form
          className={!isMobile ? desktopFormClass : mobileFormClass}
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch(keyword);
          }}
        >
          <TextField
            fullWidth
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            label="Pesquisar"
            size="small"
          />
          {!isMobile ? (
            <>
              <DatePicker
                disabled={!disable ? false : disable}
                className={isMobile ? "inputMobile" : "desktopClass"}
                locale="ptBR"
                placeholderText="   Período"
                dateFormat="dd/MM/yyyy"
                weekDays={mapDays}
                wrapperClassName={!disable ? "date-picker" : "date-picker-disabled"}
                selectsRange={true}
                startDate={!disable ? startDate : ""}
                endDate={!disable ? endDate : ""}
                onChange={(newValue) => {
                  !disable ? setValue(newValue) : setValue([null, null]);
                }}
                minDate={calenderDateNew}
                maxDate={new Date()}
              />

              <FormControl
                disabled={!disable ? false : disable}
                className={!isMobile ? "mx-2" : ""}
                size="small"
                style={{ width: "30%" }}
              >
                <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={initialStates}
                  onChange={handleUrl}
                  label="Estado"
                >
                  {states.map((item) => (
                    <MenuItem value={item.link}>{item.state}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          ) : (
            <Grid>
              <Grid item>
                <DatePicker
                  disabled={!disable ? false : disable}
                  className={isMobile ? "inputMobile" : "desktopClass"}
                  locale="ptBR"
                  placeholderText="   Período"
                  dateFormat="dd/MM/yyyy"
                  weekDays={mapDays}
                  wrapperClassName={!disable ? "date-picker" : "date-picker-disabled"}
                  selectsRange={true}
                  startDate={!disable ? startDate : ""}
                  endDate={!disable ? endDate : ""}
                  onChange={(newValue) => {
                    !disable ? setValue(newValue) : setValue([null, null]);
                  }}
                  minDate={calenderDateNew}
                  maxDate={new Date()}
                />

                <FormControl
                  disabled={!disable ? false : disable}
                  className="mx-2"
                  size="small"
                  style={{ width: "40%" }}
                >
                  <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={initialStates}
                    onChange={handleUrl}
                    label="Estado"
                  >
                    {states.map((item) => (
                      <MenuItem value={item.link}>{item.state}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
          <Button
            variant="contained"
            className={`${classes.button} ${buttonMobileClass.length > 0 ? "my-1" : ""}`}
            type="submit"
            style={{ width: !isMobile ? "25%" : "" }}
          >
            Enviar
          </Button>
        </form>

        {loading == false ? (
          <div
            className="container my-3"
            style={{ maxHeight: "332px", overflowY: "auto" }}
          >
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                return <>{!disable ? <NoticiasPost item={item} /> : <></>}</>;
              })
            ) : (
              <div style={{ width: "100%" }}>
                {!disable && !noDataNewsMsg ? (
                  <b>Insira uma palavra-chave e envie para buscar as notícias.</b>
                ) : (
                  <></>
                )}
                {!disable && noDataNewsMsg ? (
                  <b>
                    Não foi possível buscar notícias no momento. Tente novamente depois de
                    algum tempo.
                  </b>
                ) : (
                  <></>
                )}
              </div>
            )}

            {googleNews.length > 0 ? (
              googleNews.map((item, index) => {
                return <>{disable ? <GoogleNews item={item} /> : <></>}</>;
              })
            ) : (
              <div style={{ width: "100%" }}>
                {disable && !noDataGoogleNewsMsg ? (
                  <b>
                    Insira uma palavra-chave e envie para buscar as notícias do Google.
                  </b>
                ) : (
                  <></>
                )}
                {disable && noDataGoogleNewsMsg ? (
                  <b>
                    Não é possível buscar notícias do Google neste momento. Tente
                    novamente depois de algum tempo.
                  </b>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        ) : (
          <div
            className="normalspinner"
            style={{
              width: "100%",
              zIndex: 9999,
              alignItems: "center",
              textAlign: "center",
              marginTop: "150px",
            }}
          >
            <Spinner animation="border"></Spinner>
          </div>
        )}
      </div>
    </>
  );
}
