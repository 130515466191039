import { Button, Collapse, Box } from "@mui/material";
import React from "react";
import MesaCard from "./MesaCard";

export default function StakeholdersProfileMesa({ mesa }) {
  const [collapse, setCollapse] = React.useState(false);
  const [mesaOut, mesaIn] = [mesa.slice(0, 5), mesa.slice(5, mesa.length)];
  return (
    <Box mb={3}>
      <h5 style={{ marginBottom: "15px", fontWeight: "bold" }}>Legislatura (Mesa)</h5>
      {mesaOut.map((item) => (
        <MesaCard {...item} />
      ))}
      {!collapse && <Button type="button" variant="outlined" onClick={() => setCollapse(true)}>
        Expandir
      </Button>}
      <Collapse in={collapse} timeout="auto">
        {mesaIn.map((item) => (
          <MesaCard {...item} />
        ))}
        <Button type="button" variant="outlined" onClick={() => setCollapse(false)}>
          Minimizar
        </Button>
      </Collapse>
    </Box>
  );
}

