import * as React from "react";
import { Divider, List } from "@mui/material";
import {
  DrawerBody,
  LoggedUser,
  Main,
  MasterPageContainer,
  ThinDrawer,
  LogoutButton,
  DrawerItemLinks,
  DrawerItemLinksWithSubnav,
} from "./styles";
import { AppContext } from "../App";
import Navbar from "./Navbar/Navbar";
import { routes } from "../Routes";
import Footer from "../ReuseableComponents/Footer";

export const usePageHeader = (header) => {
  const { setPageHeader } = React.useContext(AppContext);
  React.useEffect(() => {
    setPageHeader(header);
  }, [header, setPageHeader]);
};

export default function MasterPage({ children }) {
  const [open, setOpen] = React.useState(false);
  const [subnav, setSubnav] = React.useState(false);
  const { isMobile } = React.useContext(AppContext);

  const toggleSubnav = () => {
    setSubnav(!subnav);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleCloseOnItemClickMobile = () => {
    isMobile && setOpen(false);
  };

  return (
    <MasterPageContainer>
      <ThinDrawer open={open} onRequestOpen={handleDrawerOpen} />
      <DrawerBody open={open} onRequestClose={handleDrawerClose}>
        <List>
          {routes.map((sidebar, index) =>
            sidebar.subNav ? (
              <React.Fragment key={`masterpage-${index}`}>
                <DrawerItemLinksWithSubnav
                  onClickMobile={handleCloseOnItemClickMobile}
                  sidebar={sidebar}
                  openSubnav={subnav}
                  toggleSubnav={toggleSubnav}
                />
              </React.Fragment>
            ) : (
              <DrawerItemLinks
                onClickMobile={handleCloseOnItemClickMobile}
                sidebar={sidebar}
              />
            )
          )}
        </List>
        <Divider />
        <LogoutButton onClick={() => alert("Under Construction")} />
        <LoggedUser />
      </DrawerBody>
      <Main open={open} isMobile={isMobile} className="main-body">
        <Navbar />
        {children}
      </Main>
    </MasterPageContainer>
  );
}
