import React, { useState, useEffect } from "react";
import { Grid, Paper, Button } from "@mui/material";
import { Link } from "react-router-dom";
import "./card.css";
import { AppContext } from "../App";
import EmptyProfile from "../image/EmptyProfile.png";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { CloseButton } from "react-bootstrap";

const StakeholderCard = React.forwardRef(
  ({ item, keyword, deputado, ministro, outros, ...props }, ref) => {
    const { isMobile, currIdToken, expiresOn, renewSilentToken, ministerData } =
      React.useContext(AppContext);
    const mobileClass = isMobile ? "mobile" : "";
    const sizeStyle = isMobile
      ? { width: "70vw", height: "80vh" }
      : { width: 1000, height: 500 };
    const [imageErrorFlag, setImageErrorFlag] = React.useState(false);
    const [recentProfiles, setRecentProfiles] = React.useState({
      profiles: {},
      order: [],
    });
    const handleImageError = () => setImageErrorFlag(true);
    const handleRecent = (item) => {
      let itemId;
      if (item.id) {
        itemId = item.id;
      } else if (item.stakeholder_id) {
        itemId = item.stakeholder_id;
      } else {
        return;
      }
      item.id = itemId;
      recentProfiles["profiles"][itemId] = item;
      recentProfiles["order"] = recentProfiles["order"].filter(
        (arrayItem) => arrayItem !== itemId
      );
      recentProfiles["order"].push(itemId);
      recentProfiles["order"] = recentProfiles["order"].slice(-5);
      localStorage.setItem("recent-profiles", JSON.stringify(recentProfiles));
      setRecentProfiles(recentProfiles);
    };

    useEffect(() => {
      const recentProfilesLocal =
        JSON.parse(localStorage.getItem("recent-profiles")) || recentProfiles;
      setRecentProfiles(recentProfilesLocal);
    }, []);
    ////////////Pdfloader
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

    const [pdfLink, setPdfLink] = useState([]);
    const handleLinkPdf = async (name) => {
      if (expiresOn < new Date()) {
        return renewSilentToken();
      }
      await axios
        .get(`${process.env.REACT_APP_API_KEY}/person/outros-get-pdf/${name}`, {
          headers: { Authorization: "Bearer " + currIdToken },
          responseType: "arraybuffer",
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(blob);
          setPdfLink(fileURL);
          PdfOpenModal();
        })
        .catch(renewSilentToken);
    };
    const HandleRedirectPdf = () => {
      return window.open(pdfLink, "_blank");
    };

    ////modal
    const [showModal, setShowModal] = React.useState(false);
    const PdfOpenModal = () => {
      setShowModal(true);
    };

    const PdfCloseModal = () => {
      setShowModal(false);
    };

    if (deputado)
      return (
        <Grid item xs={6}>
          <Paper ref={ref} elevation={12} className={`card-grid ${mobileClass}`.trim()}>
            <div className="card-img">
              {!imageErrorFlag && (
                <img src={item.urlFoto} alt="img" onError={handleImageError} />
              )}
              {imageErrorFlag && <img src={EmptyProfile} alt="img" />}
            </div>
            <div className="card-profile">
              <h5 title={item.nome}>{item.nome}</h5>
              <small>{item.situacao}</small>
              <small>Deputado</small>
              <small>
                {item.siglaUf} / {item.siglaPartido}
              </small>
            </div>
            <div className={`card-interaction ${mobileClass}`.trim()}>
              <div className="card-link-button">
                <Link to={`/stakeholders-pessoas/${item.id}`}>
                  <Button
                    onClick={() => handleRecent(item)}
                    variant="contained"
                    style={{ backgroundColor: "var(--bayer-blue)" }}
                  >
                    Ver Mais
                  </Button>
                </Link>
              </div>
            </div>
          </Paper>
        </Grid>
      );
    return <>/</>;
  }
);

export default StakeholderCard;

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  pr: 2,
  borderRadius: "10px",
  display: "flex",
};
