import { Close } from "@mui/icons-material";
import React, { useState, useEffect } from "react";

export default function Restricted({close}) {
  const openImprintLink = () => {
    window.open("https://www.bayer.com.br/pt/imprint", "_blank");
  };

  const openPoliticiaPrivacyLink = () => {
    window.open("https://privacy.microsoft.com/pt-br/privacystatement", "_blank");
  };

  const openBayerPrivacyLink = () => {
    window.open("https://www.bayer.com/en/privacy-statement-pv", "_blank");
  };

  const openContactDataPrivacyLink = () => {
    window.open("https://www.bayer.com/en/privacy-statement-pv", "_blank");
  };

  return (
    <div>
      <p style={{display: "flex", justifyContent:"flex-end"}}>
        <Close
          style={{
            fontSize: "17px",
            cursor: "pointer",
            position: "fixed",
            marginRight: "-27px",
            marginTop: "-20px",
          }}
          onClick={close}
        />
        </p>
      <h3
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          color: "var(--bayer-md-blue)",
        }}
      >
        Declaração de Privacidade
      </h3>
      <p>
        Esse aplicativo (doravante o aplicativo "App") é fornecido por Bayer S.A.
        (doravante "nos", "nós"). Para obter mais informações sobre o provedor do App,
        consulte o{" "}
        <a href="#" onClick={openImprintLink} style={{color: "#0191df"}}>
          Imprint
        </a>
        .
      </p>
      <h5 style={{ color: "var(--bayer-md-green)", fontWeight: "bold" }}>
        Processamento de dados pessoais
      </h5>
      <p>
        A seguir, desejamos fornecer informações sobre como lidamos com seus dados
        pessoais quando você utiliza nosso aplicativo. Salvo indicação em contrário nos
        capítulos seguintes, a base jurídica para o tratamento dos seus dados pessoais
        resulta do fato de tal tratamento ser necessário para disponibilizar as
        funcionalidades da App conforme solicitado por você (Art. 7, V, Lei Geral de
        Proteção de Dados).
      </p>
      <h5 style={{ color: "var(--bayer-md-green)", fontWeight: "bold" }}>
        Utilização do nosso aplicativo
      </h5>
      <h6 style={{ fontWeight: "bold" }}>Acessando nosso aplicativo</h6>
      <p>
        A fim de disponibilizar para você os serviços do nosso aplicativo, ele irá
        transferir as seguintes informações para nós, toda vez que você usar o nosso
        aplicativo: <br />
        • Endereço IP
        <br />
        • Data e hora de acesso
        <br />
        • Diferença de fuso horário em relação ao Horário de Greenwich (GMT)
        <br />
        • Informações do sistema operacional
        <br />
        • Status de acesso/código de status HTTP
        <br />
        • Volume de dados transferido
        <br />
        • Configurações de idioma, versão do aplicativo
        <br />
        • Credenciais de login (nome de usuário e senha)
        <br />
        Para proteger nossos interesses legítimos, armazenaremos essas informações por um
        período limitado de tempo para poder iniciar um rastreamento de dados pessoais em
        caso de real ou tentativa de acesso não autorizado aos nossos servidores (Art. 7,
        IX Lei Geral de Proteção de Dados).
      </p>
      <h6 style={{ fontWeight: "bold" }}>Registro e login</h6>
      <p>
        Para poder usar determinados serviços de nosso app, primeiro você precisa
        registrar uma conta conosco, onde você pode determinar suas credenciais de login
        pessoais que você precisa para poder entrar em sua conta pessoal. Para este
        registro e procedimento de login subsequente, coletamos as seguintes informações
        sobre você: <br />
        • Nome de usuário e senha
        <br />
        Se processar esses dados pessoais para fornecer-lhe acesso aos serviços do nosso
        aplicativo. Eles são excluídos caso você desative sua conta de usuário.
      </p>
      <h6 style={{ fontWeight: "bold" }}>
        Serviços de login de terceiros/Single Sign On
      </h6>
      <p>
        Sujeito ao fornecimento do seu consentimento (Art. 7, I, Lei Geral de Proteção de
        Dados), fornecemos a você a possibilidade de se registrar em nossos serviços
        utilizando o provedor de serviços de login de terceiros AZURE AD (doravante "
        Provedor de Login "). Para utilizar este serviço de login, será solicitado a fazer
        login no serviço do Provedor de Login usando suas respectivas credenciais. Ao
        fazer isso, seu perfil estará conectado ao nosso serviço, o que significa que o
        Provedor de Login-Service recebe as informações de que você utiliza conosco e
        recebemos automaticamente as seguintes informações sobre você:
        <br />
        • O fato
        <br />
        de que a identidade foi autenticada pelo Provedor de Login/li Teremos essas
        informações apenas para a finalidade de registro e para identificá-lo durante seus
        logins. No caso de o Provedor de Login transferir mais informações sobre você do
        que precisamos para o propósito de verificação de login, excluíremos imediatamente
        essas informações. Para obter mais informações sobre como funciona o serviço do
        Provedor de Login e como este provedor processa seus dados pessoais, consulte-o
        para mais detalhes{" "}
        <a href="#" onClick={openPoliticiaPrivacyLink}>
          Política de privacidade da Microsoft – Privacidade da Microsoft
        </a>
        .
      </p>
      <h6 style={{ fontWeight: "bold" }}>Registro para grupos fechados de usuários</h6>
      <p>
        Certas informações sobre medicamentos controlados contidas neste app devem ser
        disponibilizadas apenas para pessoas pertencentes ao círculo de especialistas
        médicos. Para verificar se você está autorizado a acessar o app, somos obrigados a
        solicitar comprovante de sua condição de perito médico. Assim que o seu direito de
        acesso for verificado, criaremos para você uma conta de usuário na qual, além de
        seu status profissional, iremos registrar os seguintes dados pessoais:
        <br />
        • Nome de
        <br />
        usuário e senha Processamos estes dados pessoais a fim de lhe fornecer acesso ao
        nosso app. Eles são excluídos no caso de você excluir sua conta de usuário.
      </p>
      <h6 style={{ fontWeight: "bold" }}>Análise do App com a Google</h6>
      <p>
        Em nosso aplicativo usamos um serviço de análise da Google Inc., 1600 Anfiteatro
        Parkway, Mountain View, CA 94043, Estados Unidos ("Google"). A Google analisará o
        uso do nosso Aplicativo em nosso nome para que possamos entender melhor como você
        usa este App, o que nos permite melhorar este App de acordo com os interesses de
        nossos clientes. As informações coletadas pela Google em conexão com o uso do
        nosso Aplicativo (por exemplo, conteúdo do nosso Aplicativo visitado por você,
        suas configurações de idioma, seu sistema operacional, sua resolução de tela,
        etc.) serão transmitidas para um servidor da Google nos EUA, onde será armazenado
        e analisado. Os respectivos resultados serão então disponibilizados para nós de
        forma anonimizada. Seus dados de uso não serão conectados ao seu endereço IP
        completo durante esse processo. Ativamos em nosso App a função de anonimização IP
        oferecida pela Google, que excluirá os últimos 8 dígitos (tipo IPv4) ou os últimos
        80 bits (tipo IPv6) do seu endereço IP após cada transferência de dados para a
        Google. Além disso, ao concluir acordos específicos com o Google, garantimos que
        um nível adequado de proteção de dados seja mantido no que diz respeito ao
        processamento de dados pessoais pela Google nos EUA. Apenas analisamos seu uso
        deste aplicativo, se obtivermos seu consentimento prévio após seu primeiro acesso
        ao nosso aplicativo ou se você fizer uso da chave acima para ativar ou desativar a
        análise do aplicativo (Art. 6(1)(a) Regulamento Geral de Proteção de Dados). Você
        pode retirar seu consentimento a qualquer momento, utilizando a mesma chave acima.
      </p>
      <h6 style={{ fontWeight: "bold" }}>
        A publicidade comportamental online com a Google
      </h6>
      <p>
        Esse App usa serviços de propaganda comportamental on-line da Google Inc., 1600
        Anfiteatro Parkway, Mountain View, CA 94043, Estados Unidos ("Google"). A Google
        analisará o uso deste App para fins publicitários. As informações coletadas pela
        Google em conexão com o uso do nosso Aplicativo (por exemplo, conteúdo do nosso
        Aplicativo usado por você, suas configurações de idioma, seu sistema operacional,
        sua resolução de tela, etc.) serão transmitidas para um servidor da Google nos
        EUA, onde será armazenado e analisado. Nós e nosso parceiro Google usaremos essas
        informações para melhor adaptar nossos anúncios a você e seus interesses, para
        limitar o número de vezes que um mesmo anúncio é apresentado, para avaliar a
        eficiência de campanhas promocionais e para compreender melhor o comportamento dos
        visitantes depois que eles olharam para um determinado anúncio. Quando você visita
        aplicativos ou sites da chamada "Google Display Network", pop-ups personalizados
        adaptados aos seus interesses podem ser apresentados a você com base nas
        informações coletadas em nosso App. Além disto, ao concluir acordos específicos
        com a Google, garantimos que um nível adequado de proteção de dados seja mantido
        no que diz respeito ao processamento de dados pessoais pela Google nos EUA. A
        Google é responsável por processar seus dados pessoais que a Google coleta
        diretamente do nosso Aplicativo para serviços de publicidade comportamental
        on-line. Não temos controle sobre dados pessoais coletados e processados por
        terceiros, não estamos em posição de fornecer informações vinculativas sobre o
        escopo e finalidade de tal processamento de seus dados pessoais. Assim, visite as
        informações da Google sobre privacidade de dados para receber mais informações
        sobre como a Google processa seus dados pessoais e por quanto tempo. No momento da
        elaboração desta Política de privacidade, as informações da Google estavam
        disponíveis nas Diretrizes de Proteção de Dados da Google para Publicidade. Apenas
        analisamos seu uso deste aplicativo, se obtivermos seu consentimento prévio após
        seu primeiro acesso ao nosso aplicativo ou se você fizer uso da chave acima para
        ativar ou desativar a análise do aplicativo (Art. 6(1)(a) Regulamento Geral de
        Proteção de Dados). Você pode retirar seu consentimento a qualquer momento,
        utilizando a mesma chave acima.
      </p>
      <h6 style={{ fontWeight: "bold" }}>
        Informações sobre efeitos colaterais e reclamações de qualidade
      </h6>
      <p>
        Este app não se destina ou foi projetado para comunicações relacionadas a efeitos
        colaterais, falta de efeito terapêutico, erros de medicação , produtos do mercado
        cinza/medicamentos falsificados, uso incorreto ou fora do rótulo, reclamações de
        qualidade e/ou outras questões relacionadas à segurança ou qualidade dos produtos
        da Bayer. Se desejar relatar efeitos colaterais ou fazer uma reclamação de
        qualidade, entre em contato com seu profissional de saúde (por exemplo, médico ou
        farmacêutico), sua autoridade de saúde local ou use nosso{" "}
        <a href="#" onClick={openBayerPrivacyLink}>
          website
        </a>
        para relato de efeitos colaterais indesejáveis. Se, apesar disso, você nos relatar
        efeitos colaterais indesejáveis ou outros problemas relacionados à segurança ou
        qualidade dos produtos da Bayer, seremos legalmente obrigados a lidar com sua
        comunicação e poderemos entrar em contato com você para fins de esclarecimento.
        Posteriormente, temos que notificar as autoridades de saúde competentes sobre os
        problemas relatados por você. Nesse contexto, suas informações podem ser
        encaminhadas de forma pseudonimizada, ou seja, nenhuma informação pela qual você
        possa ser diretamente identificado será transmitida. Também podemos ter que
        encaminhar essas notificações com dados pseudonimizados para as empresas do nosso
        grupo e parceiros de cooperação, na medida em que estes sejam igualmente obrigados
        a notificar suas respectivas autoridades de saúde competentes. Mais informações
        sobre privacidade de dados e relatórios de efeitos colaterais estão disponíveis na{" "}
        <a href="#" onClick={openBayerPrivacyLink}>
          Declaração de Privacidade para Farmacovigilância
        </a>
        .
      </p>
      <h4 style={{ color: "#ffd669" }}>Informações sobre seus direitos</h4>
      <p>
        Os seguintes direitos estão em geral disponíveis para você de acordo com as leis
        de privacidade de dados aplicáveis:
        <br /> • direito de informação sobre seus dados pessoais armazenados por nós
        <br /> • direito de solicitar a correção, eliminação ou processamento restrito de
        seus dados pessoais
        <br /> • direito de objeção a um processamento por razões de nosso próprio
        interesse legítimo, interesse público ou perfil, a menos que sejamos capazes de
        provar que razões imperiosas, justificadas e superiores a seus interesses,
        direitos e liberdade existem, ou que tal processamento é feito para fins de
        afirmação, exercício ou defesa de reivindicações legais
        <br /> • direito à portabilidade de dados
        <br /> • direito de apresentar uma reclamação junto a uma autoridade de proteção
        de dados
        <br /> • você pode, a qualquer momento e com efeito futuro, retirar seu
        consentimento para o processamento de seus dados pessoais.
        <br /> Para maiores informações, consulte os capítulos acima que descrevem o
        processamento de dados pessoais com base em seu consentimento. Caso deseje exercer
        seus direitos, favor dirigir sua solicitação ao contato indicado abaixo ou
        encaminhe seu pedido via nosso
        <a href="#" onClick={openContactDataPrivacyLink}>
          formulário de contato (https://www.bayer.com/en/contacting-data-privacy)
        </a>
        .
      </p>
      <h4 style={{ color: "#ffd669" }}>Contato</h4>
      <p>
        Para qualquer dúvida que você possa ter com relação à privacidade de dados, envie
        sua solicitação para nosso{" "}
        <a href="#" onClick={openContactDataPrivacyLink}>
          formulário de contato
        </a>{" "}
        ou entre em contato com o<br />
        Data Privacy Officer da nossa empresa no seguinte endereço:
        <br />
        <b>Data Privacy Officer</b>
        <br /> Rua Domingos Jorge, 1100 – 10º andar
        <br /> CEP 04779-900 – São Paulo, SP - Brasil
      </p>
      <h4 style={{ color: "#ffd669" }}>Alteração da Política de Privacidade</h4>
      <p>
        Podemos atualizar nossa Política de Privacidade de tempos em tempos. As
        atualizações de nossa Política de Privacidade serão publicadas em nosso
        Aplicativo. Quaisquer alterações se tornam efetivas após a publicação em nosso
        Aplicativo. Por isso, recomendamos que você visite o App regularmente para se
        manter informado sobre possíveis atualizações.
        <br />
        Última atualização: 03/18/2022
      </p>
    </div>
  );
}
