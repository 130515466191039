import React from "react";
import { Box, Grid } from "@mui/material";
import moment from "moment";

export default function OrgaosCard({ uriOrgao, siglaOrgao, nomePublicacao, nomeOrgao, codTitulo, titulo, dataInicio  }) {
          return (
            dataInicio && (
              <Grid my={2} container columnSpacing={4}>
                <Grid item>
                  <b style={{ color: "var(--bayer-md-green)" }}>{moment(dataInicio).format("DD/MM/YYYY")}</b>
                  <br/>
                  <b>{nomePublicacao}</b>
                  <p>{nomeOrgao}</p>
                </Grid>
              </Grid>
            )
          );
}
