import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { Box } from "@mui/material";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { AppContext } from "../../../../App";
import { Spinner } from "react-bootstrap";
import MinisterMessage from "./MinisterMessage";

function MinisterInformation(props) {
  const { id } = props.match.params;
  const profile = id.split("-");
  const profileID = profile[1];

  const { isMobile, currIdToken, expiresOn, renewSilentToken, institutionData } =
    React.useContext(AppContext);
  const mobileClass = isMobile ? "mobile" : "";

  let discriptionUrl;
  const [urls, setUrls] = useState("");

  const informationPara = (text) => {
    let paragraph = text.replace(/(?:https?|ftp):\/\/[\n\S]+/g, "").split(". ");
    return paragraph;
  };
  ////for matching all the httpsurls
  const informationParatwo = (text) => {
    let regex = new RegExp(/(?:https?|ftp):\/\/[\n\S]+/g)
    let resultUrl = text.match(regex)
    return resultUrl
  };


  useEffect(() => {
    if (!currIdToken) return;
    handleMinisterInformation();
  }, [currIdToken]);
  ////////////////////////handling Information
  const [ministerInfo, setministerInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleMinisterInformation = async () => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/minister/get-minister/${profileID}`, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        if (response.data != "") {
          setministerInfo(response.data);
          setLoading(true);
        }
      })

      .catch(renewSilentToken);
  };

  return (
    <>
    <div style={{marginBottom:"40px"}}>
      {loading ? (
        <Box mt={2}>
          {ministerInfo.map((item) => {
            return (
              <Grid my={2} container columnSpacing={5}>
                <Grid item>
                  {informationParatwo(item.description) ?
                    informationParatwo(item.description).map((data) => {
                      return (data ?
                        <a href={data} type="button" download><Button variant="contained">Currículo</Button></a>
                        : "")
                    })
                    : ""}
                  {informationPara(item.description).map((data, index) => {
                    return <p>{data != "" && data != "None" ? data + "." : ""}</p>;
                  })}
                  <b>
                    E-Mail: {" "}
                    <a href={`mailto:${item.stakeholder_email}`}>
                      {item.stakeholder_email}
                    </a>
                  </b><br />
                  <b>
                    Telefone: {" "}

                    {item.stakeholder_phone_number}

                  </b>
                </Grid>
              </Grid>
            );
          })}
          
        </Box>
      ) : (
        <div
          className="normalspinner"
          style={{
            width: "100%",
            zIndex: 9999,
            alignItems: "center",
            textAlign: "center",
            marginTop: "150px",
          }}
        >
          <Spinner animation="border"></Spinner>
        </div>
      )}
    </div>
    <MinisterMessage/>
    </>
  );
}
export default withRouter(MinisterInformation);
