import React, { useState, useEffect } from "react";
import {
  Typography,
  InputAdornment,
  CircularProgress,
  Paper,
} from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { AppContext } from "../../App";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import BayerLogo from "../../image/Logo_BayerCross_Black.jpeg";
import { FacebookData } from "./FacebookData";
import "../CSS/style.css";
import {
  AccessTime,
  Square,
  StarBorder,
  StarRounded,
} from "@mui/icons-material";
import axios from "axios";
import FavouriteStar from "../../ReuseableComponents/FavouriteStar";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const FETCH_DELAY_MS = 200;

const InputProps = (loading) => ({
  startAdornment: (
    <InputAdornment position="start">
      {loading ? (
        <CircularProgress
          style={{ padding: 0, height: "16px", width: "16px", margin: 0 }}
        />
      ) : (
        <FaSearch />
      )}
    </InputAdornment>
  ),
});

const gridMobileProps = (isMobile) => ({
  columns: isMobile ? 6 : 12,
  sx: {
    overflowY: "auto",
    maxHeight: isMobile ? "600px" : "450px",
    mt: isMobile ? 2 : 1,
    pr: 4,
    pb: 2,
    pl: 2,
  },
});

const itemsPorPagina = 10;

const initialParams = {
  itens: itemsPorPagina,
  pagina: 1,
  ordem: "ASC",
  ordenarPor: "nome",
};
const cardWidth = 275;

const Item = styled(Paper)(({ theme }) => ({
  minWidth: window.innerWidth <= 768 ? 0 : cardWidth,
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export default function SocialMediaFacebook() {
  const [expanded, setExpanded] = React.useState(false);

  const {
    currIdToken,
    expiresOn,
    renewSilentToken,
    currUserId,
    favData,
    setFavData,
  } = React.useContext(AppContext);

  useEffect(() => {
    if (!currIdToken) return;
  }, [currIdToken]);

  const addFavourites = async (e) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    e.stopPropagation();
    const params = {
      user_id: currUserId, //loggedInUser ID
      type: "fav_news",
    };

    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/favourites/add-favourite`,
        params,
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then((response) => {
      })
      .catch(renewSilentToken);
  };

  const removeFavourites = async (e) => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    e.stopPropagation();
    const params = {
      user_id: currUserId, //loggedInUser ID
      type: "fav_news",
    };

    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/favourites/remove-favourite`,
        params,
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then((response) => {
        setFavData((f) => {
        });
      })
      .catch(renewSilentToken);
  };

  return (
    <div>
      <Item>
        <div
          className="CardHeader"
          style={{ backgroundColor: "var(--bayer-md-blue)" }}
        >
          Facebook
        </div>
        <Card variant="outlined" sx={{ overflowY: "auto", height: 345 }}>
          {/* <div> */}
          {/* <CardHeader
                  title="Facebook"
                  titleTypographyProps={{ variant: "h6" }}
                  style={{ backgroundColor: "#014422", color: "white" }}
                /> */}
          {/* </div> */}
          {/* <div style={{ marginTop: "60px" }}>
                {" "} */}
          {FacebookData.map((item, index) => {
            return (
              <>
                <CardMedia
                  component="img"
                  height="194"
                  image={BayerLogo}
                  alt="Paella dish"
                />
                <Square
                  style={{ padding: "4px" }}
                  className="home-btn-details-square"
                />{" "}
                <AccessTime
                  style={{ padding: "4px" }}
                  className="home-btn-details-clock"
                />{" "}
                <FavouriteStar
                  // data={favData}
                  addFav={addFavourites}
                  removeFav={removeFavourites}
                  // isMarked={clicked}
                  selectedId={"news_" + item.news_id}
                  />
                <CardContent>
                  <Typography variant="h6" color="text.secondary">
                    {item.news_title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.news_text}
                  </Typography>
                </CardContent>
              </>
            );
          })}
          {/* </div> */}
        </Card>
      </Item>
    </div>
  );
}
