import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AppContext } from "../../../App";
import { AccessTime, Close, Square, StarBorder, StarRounded } from "@mui/icons-material";
import axios from "axios";
import LinkIcon from "@mui/icons-material/Link";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  borderRadius: "10px",
};

export default function StakeholderProfileNewsDetails({ item, close }) {
  const {
    currIdToken,
    currUserId,
    expiresOn,
    renewSilentToken,
    favData,
    setFavData,
    isMobile,
  } = React.useContext(AppContext);
  useEffect(() => {
    if (!currIdToken && !item) return;
    getNewsDetails(item);
  }, [currIdToken, item]);

  const sizeStyle = isMobile
    ? { width: "70vw", height: "80vh" }
    : { width: 1000, height: 500 };



  const [googleNews, setGoogleNews] = useState([]);

  const getNewsDetails = async (item) => {
    if (expiresOn < new Date()) {
      return renewSilentToken();
    }
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/news/get-google-news/article/` + item.link, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        if (response.data !== "") {
          setGoogleNews(response.data);
        }
      })
      .catch(renewSilentToken);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end", height: "20px" }}>
        <Close
          style={{
            fontSize: "17px",
            cursor: "pointer",
            position: "fixed",
            marginBottom: "20px",
          }}
          onClick={close}
        />
      </div>
      <div className="modal-top-bar">
        <div className="home-post-details-top">
          <div className="home-post-details-author">
            <Square className="home-post-details-square" style={{ padding: "4px" }} />
            {item.media}
          </div>
          {<br />}
          <div className="home-post-details-time">
            <AccessTime className="home-post-details-clock" style={{ padding: "4px" }} />
            {item.date}
          </div>
        </div>
      </div>
      <Typography>
        Alguns links disponibilizados nesta notícia, podem conter conteúdos pagos
      </Typography>
      <div className="modal-image"></div>
      <Typography className="modal-title" variant="h5" style={{ fontWeight: "bold" }}>
        {item.title}
      </Typography>
      {<br />}
      <Typography
        className="modal-description"
        variant="subtitile2"
        style={{ alignContent: "center" }}
      >
        <div dangerouslySetInnerHTML={{ __html: item.desc }} />
        <a href={item.link} target="_blank" style={{ color: "#0191df" }}>
          Veja mais
        </a>
      </Typography>
      {<br />}
      {<br />}
      <Typography className="modal-title" variant="h5" style={{ fontWeight: "bold" }}>
        Artigo
      </Typography>
      {googleNews.map((news) => {
        return (
          <>
            {news.Article !== "" &&
            news.Article !== undefined &&
            news.Article !== null ? (
              <>
                <Typography
                  className="modal-description"
                  variant="subtitile2"
                  style={{ alignContent: "center" }}
                >
                  <div dangerouslySetInnerHTML={{ __html: news.Article }} />
                </Typography>
                {<br />}
                <Typography
                  className="modal-title"
                  variant="h5"
                  style={{ fontWeight: "bold" }}
                >
                  Resumo
                </Typography>
                <Typography
                  className="modal-description"
                  variant="subtitile2"
                  style={{ alignContent: "center" }}
                >
                  <div dangerouslySetInnerHTML={{ __html: news.Summary }} />
                </Typography>
              </>
            ) : (
              "Clique no link acima para ler a notícia completa."
            )}
          </>
        );
      })}
    </div>
  );
}
