import React, { useState, useEffect } from "react";
import { Grid, Paper, Button } from "@mui/material";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../CSS/institutionCard.css";
import { AppContext } from "../../../App";
import EmptyProfile from "../../../image/EmptyProfile.png";
import axios from "axios";

function InstitutionCard({ keyword }) {
  const { isMobile, currIdToken, expiresOn, renewSilentToken, institutionData } =
    React.useContext(AppContext);
  const mobileClass = isMobile ? "mobile" : "";
  const [imageErrorFlag, setImageErrorFlag] = React.useState(false);
  const [recentInstitution, setRecentInstitution] = React.useState({
    profiles: {},
    order: [],
  });
  const handleImageError = () => setImageErrorFlag(true);
  const handleRecent = (item) => {
    recentInstitution["profiles"][item.institution_id] = item;
    recentInstitution["order"] = recentInstitution["order"].filter(
      (arrayItem) => arrayItem !== item.institution_id
    );
    recentInstitution["order"].push(item.institution_id);
    recentInstitution["order"] = recentInstitution["order"].slice(-5);
    localStorage.setItem("recent-institution-new", JSON.stringify(recentInstitution));
    setRecentInstitution(recentInstitution);
  };
  useEffect(() => {
    const recentInstitutionLocal =
      JSON.parse(localStorage.getItem("recent-institution-new")) || recentInstitution;
    setRecentInstitution(recentInstitutionLocal);
  }, []);
  const contactDetails = (text) => {
    let contact = text.split(",\n");
    return contact[0];
  };

  const contactDetailsAll = (text) => {
    let contact = text.split(",\n");
    return contact[1];
  };
  let filterData = [...institutionData];
  if (keyword !== "" && institutionData) {
    filterData = institutionData.filter((data) => {
      const lower_title = data.institution_title.toLowerCase();
      const lower_searchKey = keyword.toLowerCase();
      return lower_title.search(lower_searchKey) != -1;
    });
  }

  return (
    <>
      {filterData.map((item, index) => {
        return (
          <Grid item xs={6}>
            <Paper
              elevation={12}
              className={`card-grid-institution ${mobileClass}`.trim()}
            >
              <div className="card-img">
                {
                  <img
                    src={
                      item.institution_image === ""
                        ? EmptyProfile
                        : item.institution_image
                    }
                    alt="img"
                  />
                }
              </div>
              <div className="card-profile">
                <h5>
                {item.institution_title.charAt(0).toUpperCase() + item.institution_title.slice(1)}
                </h5>
              </div>
              <div className="card-interaction">
                <div className="card-link-button">
                  <Link to={`/stakeholders-instituicoes/${item.institution_id}`}>
                    <Button
                      onClick={() => handleRecent(item)}
                      variant="contained"
                      style={{ backgroundColor: "var(--bayer-blue)" }}
                    >
                      Ver Mais
                    </Button>
                  </Link>
                </div>
              </div>
            </Paper>
          </Grid>
        );
      })}
    </>
  );
}
export default InstitutionCard;
