import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { usePageHeader } from "../../MasterPage/MasterPage";
import { EditOutlined, StarBorder } from "@mui/icons-material";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import {
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  ListItemIcon,
  ListItemButton,
  Collapse,
  Card,
} from "@mui/material";
import { FavouriteData } from "./FavouriteData";
import { Link } from "react-router-dom";
import FavouriteItemList from "./FavouriteItemList";
import { AppContext } from "../../App";
import "../CSS/Favourite.css";

export default function Favourite() {
  usePageHeader("Favoritos");
  const [loader, setLoader] = useState(false);
  const [data, setData] = React.useState([]);
  const { currIdToken, currUserId, expiresOn, renewSilentToken } =
    React.useContext(AppContext);

  useEffect(() => {
    if (!currIdToken) return;
    getFavListCountAPI();
  }, [currIdToken]);

  const getFavListCountAPI = async () => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/favourites/get-count`,
        {
          user_id: currUserId,
        },
        { headers: { Authorization: "Bearer " + currIdToken } }
      )
      .then((response) => {
        if (response.data !== "") {
          setData(response.data);
          setLoader(true);
        }
      })
      .catch(renewSilentToken);
  };

  return (
    <>
      {loader ? (
        <div
          className="favourite-main-list"
          style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
        >
          {data.length > 0 ? (
            data.map((item, index) => {
              return (
                <Card
                className="favourite-list"
                  style={{
                    width: "45%",
                    marginBottom: "10px",
                    border: "1px solid #A9A9A9",
                    boxShadow: "none",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                >
                  <Link
                    component={<FavouriteItemList />}
                    to={`/favourites/${item.mstr_info_type_name}`}
                    style={{
                      textDecoration: "none",
                      color: "#3c3c3c",
                    }}
                  >
                    <List style={{ marginTop: "auto" }}>
                      <ListItem>
                        <ListItemAvatar style={{ marginRight: "10px" }}>
                          <div
                            style={{
                              height: "65px",
                              width: "65px",
                            }}
                          >
                            <Card
                              style={{
                                backgroundColor: "#add8e6",
                                width: "100%",
                                height: "100%",
                                position: "relative",
                              }}
                            ></Card>
                          </div>
                          <StarBorder
                            style={{
                              position: "absolute",
                              top: "30px",
                              left: "35px",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText>
                          <Typography
                            style={{
                              fontSize: "14px",
                              width: "max-content",
                              fontWeight: "bold",
                            }}
                          >
                            {item.mstr_info_type_name === "fav_news" ? "Notícias" : ""}
                            {item.mstr_info_type_name === "fav_agenda" ? "Agenda" : ""}
                            {item.mstr_info_type_name === "fav_socialmedia_twitter"
                              ? "Mídias Sociais Twitter"
                              : ""}
                            {item.mstr_info_type_name === "fav_socialmedia_youtube"
                              ? "Mídias Sociais Youtube"
                              : ""}
                          </Typography>
                          <Typography style={{ fontSize: "10px" }}>
                            {item.fav_count} itens favoritados
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Link>
                </Card>
              );
            })
          ) : (
            <div
              className="favourite-main-list-error-msg"
              style={{ width: "100%", textAlign: "center" }}
            >
              <b>Nenhum item foi adicionado aos favoritos.</b>
            </div>
          )}
        </div>
      ) : (
        <div
          className="normalspinner"
          style={{
            width: "100%",
            alignItems: "center",
            textAlign: "center",
            marginTop: "80px",
          }}
        >
          <Spinner animation="border"></Spinner>
        </div>
      )}
    </>
  );
}
