import { Tab } from '@mui/material';
import React from 'react'

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function StakeholderProfileTab({index, ...props}) {
  return (
    <Tab {...props} {...tabProps(index)} />
  )
}
