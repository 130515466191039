import React, { useState, useEffect } from "react";
import { AccessTime, Square, StarBorder, StarRounded } from "@mui/icons-material";
import { IconButton, Button } from "@mui/material";
import { AppContext } from "../App";
import axios from "axios";

export default function FavouriteStar({
  addFav,
  removeFav,
  selectedId,
  text, //title
  author, //desc
  createdAt, //publishedAt
  lang, //channelTitle
  geo, //kind
  twitterUrl, //youtubeUrl
  favType,
}) {
  const { favData, renewSilentToken, currIdToken, currUserId } =
    React.useContext(AppContext);
  const [clickedBtn, setClickedBtn] = React.useState(false);

  useEffect(() => {
    if (favData.length) {
      const isfavourite = favData.filter(({ id, type, video_id, twitter_id, title }) => {
        if (type === "fav_socialmedia_youtube" && video_id) {
          return video_id.toString() === selectedId.toString();
        } else if (type === "fav_socialmedia_twitter" && twitter_id) {
          return twitter_id.toString() === selectedId.toString();
        } else {
          return id == selectedId && (!favType || type === favType);
        }
      })?.length
        ? true
        : false;
      setClickedBtn(isfavourite);
    }
  }, [favData]);
  return (
    <div style={{ float: "right" }}>
      {clickedBtn ? (
        <IconButton
          onClick={(e) =>
            removeFav(e, selectedId, text, author, createdAt, lang, geo, twitterUrl)
          }
        >
          <StarRounded className="filled-star" />
        </IconButton>
      ) : (
        <IconButton
          onClick={(e) =>
            addFav(e, selectedId, text, author, createdAt, lang, geo, twitterUrl)
          }
        >
          <StarBorder className="star" />
        </IconButton>
      )}
    </div>
  );
}
