import React from 'react'
import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import styles from "../../Institution/CSS/institutionpdf.module.css"
import { Button } from '@material-ui/core';

function MinisterPdf({ ministerID, ministerInfo, ministerNoticias }) {
 
  const informationPara = (text) => {
    let paragraph = text.replace(/(?:https?|ftp):\/\/[\n\S]+/g, "").split(". ");
    return paragraph;
  };
  ////for matching all the httpsurls
 const informationParatwo = (text) => {
  let regex = new RegExp(/(?:https?|ftp):\/\/[\n\S]+/g)
  let resultUrl = text.match(regex)
 return resultUrl
 };
  return (
    <div className={styles.container}>
      <div>
        {ministerInfo.map((item) => {
          return (
            <div>


              <h4 style={{ fontSize: "22px", textAlign: "center", width: "100%", color: "#1976d2" }}>{item.stakeholder_name}</h4>
              <p style={{ textAlign: "center", width: "100%", color: "#1976d2" }}><small>{item.stakeholder_designation}</small></p>
              <div style={{ textAlign: "center" }}>
                <img className={styles.stackholderImg} src={item.stakeholder_img} alt="img" style={{ height: "32px", textAlign: "center" }} />
              </div>


              <div >
                <h4 className={styles.institution_sub_heading}>Informações</h4>
                <p className={styles.institution_body_div}>
              
                {informationPara(item.description).map((data, index) => {
                  return <p className={styles.institution_body}>{data != "" && data != "None" ? data + "." : ""}</p>;
                })}
               
                </p>
                <div className={styles.institution_body}>
                {informationParatwo(item.description)?
                  informationParatwo(item.description).map((data)=>{
                  return ( data ?
                  <b> Currículo : <a href={data} target="_blank">{data}</a> </b>
                   :"" )
                })
              :""}<br/>
                  <b>
                    E-Mail :{" "}
                    <a href={`mailto:${item.stakeholder_email}`}>
                      {item.stakeholder_email}
                    </a>
                  </b>
                  <br/>
                  <b>
                    Telefone: {" "}

                    {item.stakeholder_phone_number}

                  </b>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <br />
    </div>
  )
}

export default MinisterPdf