import { lastVisitedKey } from "../../hooks/useSaveHistory";
import { routes } from "../../Routes";

export const HomeRecentSearchPages = () => {
  const History = JSON.parse(localStorage.getItem(lastVisitedKey));
  return History?.map((routeIndex) => ({
    icon: routes[routeIndex].icon,
    text: routes[routeIndex].title,
    path: routes[routeIndex].path || routes[routeIndex].subNav[0].path,
  }));
};
