import React from "react";
import "../../card.css";
import "../CSS/institutionCard.css";
import { AppContext } from "../../../App";
import EmptyProfile from "../../../image/EmptyProfile.png";
import axios from "axios";
import { withRouter } from "react-router-dom";
import styles from "../CSS/institutionpdf.module.css";
import fb from "../../../image/facebook.png";
import insta from "../../../image/instagram.png";
import twitter from "../../../image/twitter.png";
import youtube from "../../../image/youtube.png";
import linkedin from "../../../image/Linkedin.png";


function PDF({ institutionId, institutionInfo, institutionSocial, institutionNoticias }) {
    const { isMobile } = React.useContext(AppContext);
    const mobileClass = isMobile ? "mobile" : "";
    const [imageErrorFlag, setImageErrorFlag] = React.useState(false);
    const handleImageError = () => setImageErrorFlag(true);

    const informationPara = (text) => {
        let paragraph = text.replace(/ATUAÇÃO/i, 'ATUAÇÃO\n').replace('_Botões/Virada/Banner_Virada_720x300.jpg', '').replace(/YouTube video player/i, '');
        paragraph = paragraph.replace(/\n+/gm, '\n').replace(/\t+/gm, ' ').replace(/(<([^>]+)>)/ig, '').replace(/(?:https?:\/\/)?(?:www\.)?youtu(?:\.be\/|be.com\/\S*(?:watch|embed)(?:(?:(?=\/[-a-zA-Z0-9_]{11,}(?!\S))\/)|(?:\S*v=|v\/)))([-a-zA-Z0-9_]{11,})/gm, '').replace(/((?:https?|ftp?|http):\/\/[a-z./_0-9A-Z\-]+)|(\n\/[a-z_A-Z\-\0-9\\u00f5]+)/gm, '').replace(/(;\s+[a-z]\s+)|(;\s+)/gm, '\n').split("\n");
        return paragraph;
    };
    return (
        <div className={styles.container}>

            {institutionInfo
                .filter((data) => data.institution_id == institutionId)
                .map((items) => {
                    return (
                        <div>
                            <h4 style={{ fontSize: "22px", textAlign: "center", width: "100%", color: "#1976d2" }}> {items.institution_title}</h4>
                            <div style={{ textAlign: "center" }}>
                                <img className={styles.stackholderImg} src={items.institution_image} alt="img" style={{ height: "32px", textAlign: "center" }} />
                            </div>
                            <div>
                                <h4 className={styles.institution_sub_heading}>Informações</h4>
                                <p className={styles.institution_body_div}>
                                    {informationPara(items.institution_info).map((data, index) => {
                                        return (<p className={styles.institution_body}>{data}</p>);
                                    })}
                                </p>
                                <div className={styles.institution_body}>
                                    <p>

                                        Número de telefone :&nbsp;
                                        {items.institution_telephone !== "" ? items.institution_telephone : "NA"} </p>
                                    <p>
                                        Número do WhatsApp :&nbsp;
                                        {items.institution_whatsapp !== "" ? items.institution_whatsapp : "NA"} </p>
                                    <p>Endereço de e-mail :&nbsp;
                                        {items.institution_mail !== "" ? items.institution_mail : "NA"} </p>
                                    <p>Endereço :&nbsp;
                                        {items.institution_address !== "" ? items.institution_address : "NA"} </p></div>
                            </div>
                            <div>
                                <div>
                                    <h4 className={styles.institution_sub_heading}>Conexões</h4>

                                </div>

                                <div>
                                    {institutionSocial
                                        .filter((data) => data.institution_id == institutionId)
                                        .map((items) => {
                                            return (

                                                <div
                                                >

                                                    {items.socialmedia_type == "youtube" ? (
                                                        <div style={{ margin: "20px" }}>
                                                            {
                                                                items.socialmedia_url != "NA" && items.socialmedia_url != "No Data" ? (
                                                                    <p> <img src={youtube} className={styles.falogo} />
                                                                        YouTube : {items.socialmedia_url}</p>
                                                                ) : <p><img src={youtube} className={styles.falogo} />
                                                                    YouTube : Não disponível</p>
                                                            }
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {items.socialmedia_type == "facebook" ? (
                                                        <div style={{ margin: "20px" }}>
                                                            {
                                                                items.socialmedia_url != "NA" && items.socialmedia_url != "No Data" ? (
                                                                    <p>
                                                                        <img src={fb} className={styles.falogo} />
                                                                        Facebook : {items.socialmedia_url}
                                                                    </p>
                                                                ) : <p><img src={fb} className={styles.falogo} />
                                                                    Facebook : Não disponível</p>
                                                            }
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {items.socialmedia_type == "instagram" ? (
                                                        <div style={{ margin: "20px" }}>
                                                            {
                                                                items.socialmedia_url != "NA" && items.socialmedia_url != "No Data" ? (
                                                                    <p>
                                                                        <img src={insta} className={styles.instagram} />
                                                                        Instagram : {items.socialmedia_url}
                                                                    </p>
                                                                ) : <p><img src={insta} className={styles.falogo} />
                                                                    Instagram : Não disponível</p>
                                                            }
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {items.socialmedia_type == "twitter" ? (
                                                        <div style={{ margin: "20px" }}>
                                                            {
                                                                items.socialmedia_url != "NA" && items.socialmedia_url != "No Data" ? (
                                                                    <p>
                                                                        <img src={twitter} className={styles.falogo} />
                                                                        Twitter : {items.socialmedia_url}
                                                                    </p>
                                                                ) : <p><img src={twitter} className={styles.falogo} />
                                                                    Twitter : Não disponível</p>
                                                            }
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {items.socialmedia_type == "linkedin" ? (
                                                        <div style={{ margin: "20px", color: "--var(--bayer-dark-blue)" }}>

                                                            {
                                                                items.socialmedia_url != "NA" && items.socialmedia_url != "No Data" ? (
                                                                    <p>
                                                                        <img src={linkedin} className={styles.falogo} />
                                                                        LinkedIn : {items.socialmedia_url}
                                                                    </p>
                                                                ) : <p><img src={linkedin} className={styles.falogo} />
                                                                    LinkedIn : Não disponível</p>
                                                            }
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <div className="card-interaction"></div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
}
export default PDF;
